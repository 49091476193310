//@flow

import './LoadingMessage.css';
import React from "react";

type Props = {
    message?: string
}

export default function LoadingMessage(props: Props) {
    return (<div className="splash-screen">
        {props.message}
        <div className="loading-dot">.</div>
    </div>);
}
