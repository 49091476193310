//@flow

import styles from "./UserManagement.module.css";
import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage, Field, Form, useField } from "formik";
import { Button, Intent, Checkbox, Label } from "@blueprintjs/core";
import Flexbox from "flexbox-react";
import { InputGroup, Divider, Card } from "@blueprintjs/core";

import FocusError from "../FocusError/FocusError";
import BlocRegistry from "../../blocs/registry";
import { apiAxios } from "../../config/AxiosConfig";
import { AVAILABLE_GROUPS, AVAILABLE_ROLES } from "../../config/Constant";
import { AppToaster } from "../Toaster/Toaster";
import ProjectGroupSuggest from "../ProjectGroupSuggest/ProjectGroupSuggest";
import ProjectGroup from "../../models/ProjectGroup/ProjectGroup";
import _ from "lodash";
import { PatternFormat } from "react-number-format";

type Props = {
  onUpdateUser: ()=> void,
  onCreateUser: () => void,
  projectGroups: ?Array<ProjectGroup>,
  project: ?Project,
  loading:?boolean,
  buttonTitle: ?string
};

const phoneRegExp = /^(?!0{10})[0-9]{2}[0-9]{8}$/;
const extensionRegex = /^[0-9]{0,4}$/;

const formStates = {
  INPUT: "INPUT",
  SUBMITTING: "SUBMITTING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE"
};

export default function CreateUser(props: Props) {
  const [userDetails, setUserDetails ] = useState({});
  const [loading, setLoading ] = useState(false);
  const { project } = props;

  const { authBloc } = useContext(
    BlocRegistry
  );
  const buildHeaders = () => {
    const accessToken = authBloc.getCurrentToken();
    const auth = "Bearer " + accessToken;
    return { headers: { Authorization: auth } };
  };

  const getUserInformation = async () => {  
    try {
      const  { userId } = props;
      if(userId){
        const tenantId = authBloc.getCurrentTenant();
        setLoading(true);
        const response = await apiAxios.get(
          `/tenants/${tenantId}/user/${userId}`,
          buildHeaders()
        );
        setLoading(false);
        setRoles(response.data.groups);
        setGroups(response.data.groups);
        setUserDetails(response.data);

        return response;
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const { email, firstName, lastName, attributes } = userDetails;
  const {
    officePhoneNumber,
    officePhoneExtension,
    phoneNumber,
    phoneNumberExtension,
    projectGroupIds,
  } = attributes ? attributes : {};
  const [state, setState] = useState(formStates.INPUT);
  const[ roles, setRoles ] = useState([]);
  const[ groups, setGroups ] = useState([]);
  const[ projectGroups, setProjectGroups ] = useState([]);
  const { buttonTitle, onCreateUser, onUpdateUser } = props;
  const setUserInformation = () => {
    if (groups.includes(AVAILABLE_GROUPS.COMPLAINCE_AUDIATORS)) {
      if (projectGroupIds) {
        let selectedProjectGroupsData = _.chain(props.projectGroups)
          .keyBy("id")
          .at(projectGroupIds)
          .value();
        if (selectedProjectGroupsData[0] !== undefined) {
          setProjectGroups(selectedProjectGroupsData);
        }
      }
    }
  };
  useEffect(()=>{
    getUserInformation()
    setUserInformation()
  }, [])
 
  useEffect(()=>{
    setUserInformation()
  }, [props.projectGroups, userDetails])
  
  const handleKeyPress = (e) => {
    // Prevent non-numeric input
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const createInput = (
    inputName: string,
    inputLabel: string,
    type?: string,
    placeHolderName?: string,
    formik
  ) => {
    return (
      <Flexbox flex={1} flexDirection={"column"} width={"100%"}>
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1} style={{ position: "relative", width: "100%" }}>
          {inputName === "officePhoneNumber" || inputName === "phoneNumber"? (
            <Flexbox flexDirection={"row"}>
              <PatternFormat
                className={`bp3-large`}
                as={InputGroup}
                name={inputName}
                placeholder={placeHolderName}
                format="###-###-####"
                // mask="_"
                allowEmptyFormatting
                type={"tel" || "text"}
                fill
                disabled={formik && formik.isSubmitting}
                value={formik && formik.values[inputName]}
                onBlur={formik && formik.handleBlur}
                onValueChange={(values) => {
                  formik && formik.setFieldValue(inputName, values.value);
                }}
                style={{ width: 1070 }}
              />
              <Label style={{ marginLeft: 10, marginRight: 10 }}>x</Label>
            </Flexbox>
          ) : (
            <>
              { inputName === "phoneNumberExtension" || inputName === "officePhoneExtension" ?
                <Field
                  className={`bp3-large`}
                  as={InputGroup}
                  name={inputName}
                  placeholder={placeHolderName}
                  type={"tel"}
                  fill
                  maxLength={4}
                  disabled={formik && formik.isSubmitting}
                  onKeyPress={handleKeyPress}
                  style={{ width: 70 }}
                /> :
                <Field
                  className={`bp3-large`}
                  as={InputGroup}
                  name={inputName}
                  placeholder={placeHolderName}
                  type={"text"}
                  fill
                  disabled={formik && formik.isSubmitting}
                  style={{ width:"100%" }}
                />
              }
            </>
          )}
        </Flexbox>
        <Flexbox
          className={styles.errorText}
          flex={1}
          style={{ overflowWrap: "anywhere" }}
        >
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };


  const setAccessRoles = (event, roleName, groupName) => {
    if (event.target.checked) {
      let roleStringsArray = [roleName];
      let groupsStringArray = [groupName];
      setRoles(roleStringsArray);
      setGroups(groupsStringArray);
    } else {
      let roleStringsArray = roles.filter((e) => e !== roleName);
      let groupsStringArray = groups.filter((e) => e !== groupName);
      setRoles(roleStringsArray);
      setGroups(groupsStringArray);
    }
  };


  const createUserInfoRequestObject = (values) => {
    let projectGroupIds = []
    if(groups.includes(AVAILABLE_GROUPS.COMPLAINCE_AUDIATORS)){
      if(values.projectGroups && values.projectGroups.length > 0 ){
        projectGroupIds = values.projectGroups.map(projectGroup => projectGroup.id);
      }
    }
    let userInfo = {
      data: {
        createdTimestamp: 1626422416,
        username: values.email,
        enabled: true,
        totp: false,
        emailVerified: true,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        attributes: {
          phoneNumber: values.phoneNumber,
          phoneNumberExtension: values.phoneNumberExtension,
          officePhoneNumber: values.officePhoneNumber,
          officePhoneExtension: values.officePhoneExtension,
          projectGroupIds: projectGroupIds,
        },
        credentials: values.password ? [
          {
            "type": "password",
            "value": values.password,
          },
        ] : [],
        requiredActions: [],
        notBefore: 0,
        access: {
          manageGroupMembership: true,
          view: true,
          mapRoles: true,
          impersonate: true,
          manage: true,
        },
      },
      realmRoles: {
        roles: roles,
        group: groups
      },
    };
    return userInfo;
  };

  return (
    <div className={`bp3-running-text bp3-text-large ${styles.formContainer}`}>
      <Formik
        initialValues={{
          firstName,
          lastName,
          email,
          phoneNumber: phoneNumber ? phoneNumber[0].replace(/-/g, '') : '',
          phoneNumberExtension: phoneNumberExtension ? phoneNumberExtension : "",
          officePhoneNumber: officePhoneNumber? officePhoneNumber[0] : '',
          projectGroups,
          officePhoneExtension: officePhoneExtension ? officePhoneExtension : "",
        }}
        enableReinitialize
        validationSchema={Yup.object({
          firstName: Yup.string().required("Required"),
          lastName: Yup.string().required("Required"),
          email: Yup.string().email("Invalid email address").required("Required"),
          officePhoneNumber: Yup.string()
          .matches(phoneRegExp, "Invalid phone number")
          .required("Required"),
          phoneNumber: Yup.string()
            .matches(phoneRegExp, "Invalid phone number")
            .required("Required"),
          password: props.userId ? Yup.string().notRequired("Required") : Yup.string().required("Required"),
          confirmPassword:props.userId ?  Yup.string().notRequired("Required").oneOf(
            [Yup.ref("password"), null],
            "Passwords must match"
          ) : Yup.string().oneOf(
            [Yup.ref("password"), null],
            "Passwords must match"
          ).required("Required"),
          phoneNumberExtension: Yup.string().matches(extensionRegex,{message:  "Extension must be at most 4 digits" }),
          officePhoneExtension: Yup.string().matches(extensionRegex,{message:  "Extension must be at most 4 digits" }),
        })}
        onSubmit={async (values) => {
         let userInfo = createUserInfoRequestObject(values);
         if(groups && groups.length > 0){
          if(props.userId){
            onUpdateUser(userInfo, props.userId);
           } else {
            onCreateUser(userInfo);
           }
         } else {
          AppToaster.show({
            message: "Atleast one assignment access is required.",
            intent: Intent.DANGER,
          });
         }
        }
      }
      >
        {(formik) => (
          <Form>
            <Flexbox
              alignItems={"center"}
              flexDirection={"column"}
              className={styles.inputSection}
            >
              <Card elevation={1} className={styles.card}>
                <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                  <h3>Team Member Information</h3>
                </Flexbox>
                <Flexbox flex={1} width={"100%"}>
                  <Divider className={styles.fullWidthInput} />
                </Flexbox>
                {createInput("firstName", "First Name")}
                {createInput("lastName", "Last Name")}
                <Flexbox flex={1} flexDirection={"row"}>
                {createInput(
                  "phoneNumber",
                  "Mobile Number",
                  "phone",
                  "222-222-2222",
                  formik
                )}
                  {createInput("phoneNumberExtension", "Ext.")}
                  </Flexbox>
                <Flexbox flex={1} flexDirection={"row"}>
                  {createInput(
                    "officePhoneNumber",
                    "Office Phone",
                    "phone",
                    "222-222-2222",
                    formik
                  )}
                  {createInput("officePhoneExtension", "Ext.")}
                </Flexbox>
                {createInput("email", "Email", "email")}
                {createInput("password", "Password", "password")}
                {createInput("confirmPassword", "Confirm Password", "password")}
                {/* <div class="sc-bdVaJa kZPOHL" style={{ paddingBottom: 10 }}>
                <label for="profile">Profile Photo</label>
              </div>
              <label class="bp3-file-input bp3-fill bp3-large">
                <input type="file" />
                <span class="bp3-file-upload-input">Choose file...</span>
              </label> */}
            </Card>

            {/* <Card elevation={1} className={styles.card}>
              <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                <h3>Permissions</h3>
              </Flexbox>
              <Flexbox flex={1} width={"100%"}>
                <Divider className={styles.fullWidthInput} />
              </Flexbox>
              <div>
                <Checkbox label="Manage Individual Project Tasks" />
                <Checkbox label="Manage Applications" />
                <Checkbox label="View Locked Attachments" />
            </div>
            
            </Card> */}

            <Card elevation={1} className={styles.card}>
              <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                <h3>Assignment Access</h3>
              </Flexbox>
              <Flexbox flex={1} width={"100%"}>
                <Divider className={styles.fullWidthInput} />
              </Flexbox>
              <div>
              <Checkbox
                  label="Accounting Manager"
                  checked={groups.includes(AVAILABLE_GROUPS.ACCOUNTING_MANAGERS)}
                  onChange={(event) => {
                    setAccessRoles(event, AVAILABLE_ROLES.ACCOUNTING_MANAGER,  AVAILABLE_GROUPS.ACCOUNTING_MANAGERS);
                  }}
                />
                 <Checkbox
                  label="Compliance Auditor"
                  checked={groups.includes(AVAILABLE_GROUPS.COMPLAINCE_AUDIATORS)}
                  onChange={(event) => {
                    setAccessRoles(event, AVAILABLE_ROLES.COMPLAINCE_AUDIATOR,  AVAILABLE_GROUPS.COMPLAINCE_AUDIATORS);
                  }}
                />
                 <Checkbox
                  label="Project Admin"
                  checked={groups.includes(AVAILABLE_GROUPS.PROJECT_ADMINS)}
                  onChange={(event) => {
                    setAccessRoles(event, AVAILABLE_ROLES.PROJECT_ADMIN,  AVAILABLE_GROUPS.PROJECT_ADMINS);
                  }}
                />
                <Checkbox
                  label="Project Manager"
                  checked={groups.includes(AVAILABLE_GROUPS.PROJECT_MANAGERS)}
                  onChange={(event) => {
                    setAccessRoles(event, AVAILABLE_ROLES.PROJECT_MANAGER, AVAILABLE_GROUPS.PROJECT_MANAGERS);
                  }}
                />
                <Checkbox
                  label="Tenant Admin"
                  checked={groups.includes(AVAILABLE_GROUPS.TENANT_ADMINS)}
                  onChange={(event) => {
                    setAccessRoles(event, AVAILABLE_ROLES.TENANT_ADMIN, AVAILABLE_GROUPS.TENANT_ADMINS);
                  }}
                />
                <Checkbox
                  label="Marketing"
                  checked={groups.includes(AVAILABLE_GROUPS.MARKETING_MANAGERS)}
                  onChange={(event) => {
                    setAccessRoles(event, AVAILABLE_ROLES.MARKETING_MANAGER, AVAILABLE_GROUPS.MARKETING_MANAGERS);
                  }}
                />
                {
                  groups.includes(AVAILABLE_GROUPS.COMPLAINCE_AUDIATORS) &&
                 <div>
                   <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                      <h5>Project Groups</h5>
                    </Flexbox>
               <Flexbox
                 flex={1}
                 alignItems={"flex-start"}
                 width={"100%"}
                // className={styles.inputSection}
               >
                 <ProjectGroupSuggest
                   inputName={"projectGroups"}
                   projectGroups={props.projectGroups}
                   selectedItems={projectGroups}
                   
                 />
               </Flexbox>
                </div>
              }
              </div>
              
            </Card>

            {/* <Card elevation={1} className={styles.card}>
              <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                <h3>Permissions (Recommended Only For Super Users)</h3>
              </Flexbox>
              <Flexbox flex={1} width={"100%"}>
                <Divider className={styles.fullWidthInput} />
              </Flexbox>
              <div>
                <Checkbox label="Manage Account Settings" />
                <Checkbox label="Manage Billing" />
                <Checkbox label="Manage Team Members" />
            </div>
            
            </Card> */}

            <Flexbox flex={1} marginTop={"15px"} marginBottom={"15px"}>
              <Button
                type="submit"
                className={"bp3-large"}
                rightIcon={state === formStates.INPUT ? "arrow-right" : null}
                disabled={state !== formStates.INPUT}
                loading={props.loading}
                icon={state === formStates.SUCCESS ? "saved" : null}
              >
                {state === formStates.INPUT
                  ? buttonTitle
                    ? buttonTitle
                    : "Create"
                  : null}
              </Button>
            </Flexbox>
          </Flexbox>
          <FocusError />
        </Form>
        )}
      </Formik>
    </div>
  );
}