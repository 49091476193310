// @flow

import type { AuthBloc } from "../../blocs/authBloc";
import { apiAxios } from "../../config/AxiosConfig";
import HomeownerApplication from "../../models/HomeownerApplication/HomeownerApplication";
import ExportEr from "../../models/ExportEr/ExportEr";

class ExportErApi {
  authBloc: AuthBloc;

  constructor(authBloc: AuthBloc) {
    this.authBloc = authBloc;
  }

  async getExportErForProject(
    projectId: string
  ): Promise<HomeownerApplication> {
    const tenantId = this.authBloc.getCurrentTenant();

    try {
      const response = await apiAxios.get(
        `/tenants/${tenantId}/projects/${projectId}/export-ERR`,
        this.buildHeaders()
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (error.status === 404) {
        return null;
      } else {
        console.error(error);
      }
    }
  }

  async updateExportErProject(
    projectId: string,
    er: ExportEr
  ): Promise<void> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.put(
        `/tenants/${tenantId}/projects/${projectId}/export-ERR`,
        er,
        this.buildHeaders()
      );

      if (response.status === 201 || response.status === 204) {
        return response.data;
      } else {
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  buildHeaders = () => {
    const accessToken = this.authBloc.getCurrentToken();
    const auth = "Bearer " + accessToken;
    return { headers: { Authorization: auth } };
  };
}

export default ExportErApi;
