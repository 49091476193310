// @flow

import React, { useState } from "react";
import { Table, SelectionModes, Column } from "@blueprintjs/table";
import { TextSortableColumn } from "../../components/Table/TextSortableColumn/TextSortableColumn";
import { Utils } from "@blueprintjs/table/lib/esnext/common/utils";
import {
  Button,
  Menu,
  MenuItem,
  Intent,
  Classes,
  Icon,
} from "@blueprintjs/core";
import history from "../../history";
import Invoice from "../../models/Invoice/Invoice";
import moment from "moment";
import { AuthRoles } from "../../blocs/authBloc";

type Props = {
  invoices: Array<Invoice>,
  onInvoiceDelete: (id: string) => any,
  onEditInvoiceMenuClick: (invoice:Invoice) => any,
  onInvoiceDownload:(request:Invoice) => any,
  onViewInvoice:(request:Invoice) => any
};

export default function InvoiceTable(props: Props) {
  const { invoices, onInvoiceDelete,onEditInvoiceMenuClick,onInvoiceDownload,onViewInvoice } = props;

  const [columns, setColumns] = useState([
    new TextSortableColumn("Controls", 0, "controls", () => {
      return 0;
    }),
    new TextSortableColumn("Status", 1, "status",undefined, {
      truncate: false,
      wrapText: true,
    }),
    new TextSortableColumn("Vendor", 2, "vendor"),
    new TextSortableColumn("Amount", 3, "amount"),
    new TextSortableColumn("Invoice", 4, "invoiceDate"),
    new TextSortableColumn("Due", 5, "dueDate"),
    new TextSortableColumn("Submitted", 5, "submittedDate"),
    new TextSortableColumn("Paid", 6, "paidDate"),
    new TextSortableColumn("Reimb. Submitted", 7, "reimbursementSubmittedDate",),
    new TextSortableColumn("Reimb. Received", 8, "reimbursementReceivedDate"),
    new TextSortableColumn("Notes", 9, "notes", undefined, {
      truncate: false,
      wrapText: true,
    }),
    new TextSortableColumn("File", 9, "fileName", undefined, {
      truncate: false,
      wrapText: true,
    }),
    new TextSortableColumn("Budget vs Actual", 10, "budgetVsActual"),
    new TextSortableColumn("Explanation", 11, "explanation", undefined, {
      truncate: false,
      wrapText: true,
    }),
  ]);

  const [sortedIndexMap, setSortedIndexMap] = useState([]);

  
  const statusRenderer = (rowIndex: number) => {
    return invoices[rowIndex].status;
  };

  const vendorRenderer = (rowIndex: number) => {
    if (invoices[rowIndex].vendor) {
      return invoices[rowIndex].vendor.name;
    } else {
      return "";
    }
  };

  const amountRenderer = (rowIndex: number) => {
    return `$ ${Number((invoices[rowIndex].amount).toFixed(1)).toLocaleString()}`; 
  };

  const invoiceDateRenderer = (rowIndex: number) => {
    if (invoices[rowIndex].invoiceDate) {
      return moment(invoices[rowIndex].invoiceDate).format("ll");
    } else {
      return "";
    }
  };

  const dueDateRenderer = (rowIndex: number) => {
    if (invoices[rowIndex].dueDate) {
      return moment(invoices[rowIndex].dueDate).format("ll");
    } else {
      return "";
    }
  };

  const submittedDateRenderer = (rowIndex: number) => {
    if (invoices[rowIndex].submittedDate) {
      return moment(invoices[rowIndex].submittedDate).format("ll");
    } else {
      return "";
    }
  };

  const paidDateRenderer = (rowIndex: number) => {
    if (invoices[rowIndex].paidDate) {
      return moment(invoices[rowIndex].paidDate).format("ll");
    } else {
      return "";
    }
  };

  const reimbursementSubmittedDateRenderer = (rowIndex: number) => {
    if (invoices[rowIndex].reimbursementSubmittedDate) {
      return moment(invoices[rowIndex].reimbursementSubmittedDate).format("ll");
    } else {
      return "";
    }
  };

  const reimbursementReceivedDateRenderer = (rowIndex: number) => {
    if (invoices[rowIndex].reimbursementReceivedDate) {
      return moment(invoices[rowIndex].reimbursementReceivedDate).format("ll");
    } else {
      return "";
    }
  };

  const notesRenderer = (rowIndex: number) => {
    return invoices[rowIndex].notes;
  };

  const fileNameRenderer = (rowIndex: number) => {
    if (invoices[rowIndex].attachment) {
      return invoices[rowIndex].attachment.title;
    } else {
      return "";
    }
  };
  const budgetVsActualRenderer = (rowIndex: number) => {
    if (invoices[rowIndex].budgetVsActual) {
      return "Yes";
    } else {
      return "No";
    }
  };
  const explanationRenderer = (rowIndex: number) => {
    if (invoices[rowIndex].explanation) {
      return invoices[rowIndex].explanation;
    } else {
      return "";
    }
  };
  const controlsRenderer = (rowIndex: number) => {
    return (
      <>
        <Menu className={Classes.ELEVATION_2}>
          {
             AuthRoles.COMPLIANCE_AUDITOR !== props.currentUserRole &&
             <MenuItem
             text="Edit"
             icon={"edit"}
             intent={Intent.PRIMARY}
             onClick={() => onEditInvoiceMenuClick(invoices[rowIndex])}
           />
          }
         

          <MenuItem
            text="View File"
            icon={"eye-open"}
            intent={Intent.WARNING}
            onClick={() => onViewInvoice(invoices[rowIndex])}
          />

          <MenuItem
            text="Download"
            icon={"download"}
            intent={Intent.SUCCESS}
            onClick={() => onInvoiceDownload(invoices[rowIndex])}
          />
{
  AuthRoles.COMPLIANCE_AUDITOR !== props.currentUserRole &&
  <MenuItem
  text="Delete"
  icon={"trash"}
  disabled = {invoices[rowIndex].paidDate || invoices[rowIndex].reimbursementSubmittedDate || invoices[rowIndex].reimbursementReceivedDate}
  intent={Intent.DANGER}
  onClick={() => onInvoiceDelete(invoices[rowIndex].id)}
/>
}
         
        </Menu>
      </>
    );
  };

  const cellRenderers = {
    controls: controlsRenderer,
    status: statusRenderer,
    vendor: vendorRenderer,
    amount: amountRenderer,
    invoiceDate: invoiceDateRenderer,
    dueDate: dueDateRenderer,
    submittedDate: submittedDateRenderer,
    paidDate: paidDateRenderer,
    reimbursementSubmittedDate: reimbursementSubmittedDateRenderer,
    reimbursementReceivedDate: reimbursementReceivedDateRenderer,
    notes: notesRenderer,
    fileName:fileNameRenderer,
    budgetVsActual: budgetVsActualRenderer,
    explanation: explanationRenderer,
  };

  const getCellData = (rowIndex: number, columnIndex: number) => {
    const sortedRowIndex = sortedIndexMap[rowIndex];
    if (sortedRowIndex != null) {
      rowIndex = sortedRowIndex;
    }
    return cellRenderers[columns[columnIndex].modelField](rowIndex);
  };

  const sortColumn = (
    columnIndex: number,
    comparator: (a: any, b: any) => number
  ) => {
    const sortedIndexMap = Utils.times(invoices.length, (i: number) => i);
    sortedIndexMap.sort((a: number, b: number) => {
      let fieldA;
      let fieldB;

      fieldA = invoices[a][columns[columnIndex].modelField];
      fieldB = invoices[b][columns[columnIndex].modelField];

      return comparator(fieldA, fieldB, a, b);
    });
    setSortedIndexMap(sortedIndexMap);
  };

  const renderedColumns = columns.map((column) => {
    const field = column.modelField;
    return column.getColumn(getCellData, sortColumn);
  });

  return (
    <Table
      defaultRowHeight={140}
      defaultColumnWidth={120}
      selectionModes={SelectionModes.COLUMNS_AND_CELLS}
      numRows={invoices.length}
    >
      {renderedColumns}
    </Table>
  );
}
