//@flow

import React from "react";
import CustomSuggest from "../CustomSuggest/CustomSuggest";
import type Task from "../../models/Task/Task";

type Props = {
    tasks: ?Array<Task>,
    inputName?: string,
}

export default function TaskSuggest(props: Props) {
    return <CustomSuggest inputName={props.inputName} items={props.tasks} filterItems={filterTasks} inputValueRenderer={inputValueRenderer} inputSelectValueMapper={inputSelectValueMapper} itemToKeyMapper={itemToKeyMapper}/>
}

function itemToKeyMapper(task: Task){
    return task.id;
}

function inputSelectValueMapper(task: Task){
    return task.id;
}

function inputValueRenderer(task: Task){
    return task.title;
}

function filterTasks(query, task, _index, exactMatch) {
    const normalizedTaskGroup = task.title.toLowerCase();
    const normalizedQuery = query.toLowerCase();

    if (exactMatch) {
        return normalizedTaskGroup === normalizedQuery;
    } else {
        return `${normalizedTaskGroup}`.indexOf(normalizedQuery) >= 0;
    }
};
