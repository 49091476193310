//@flow

import type {AttachmentTypes} from "../models/Attachment/Attachment";

export default class Invoice {
    id:string;
    projectId: string;
    tenantId: string;
    vendorId:string;
    vendor:InvoiceVendor
    status:string;
    amount:string;
    invoiceDate:string;
    dueDate:string;
    submittedDate:string;
    paidDate:string;
    reimbursementSubmittedDate:string;
    reimbursementReceivedDate:string;
    notes:string;
    attachment:InvoiceAttachment;
   
   

    constructor(
        id:string,
        projectId: string,
        tenantId: string,
        vendorId:string,
        vendor:InvoiceVendor,
        status:string,
        amount:string,
        invoiceDate:string,
        dueDate:string,
        submittedDate:string,
        paidDate:string,
        reimbursementSubmittedDate:string,
        reimbursementReceivedDate:string,
        notes:string,
        attachment:InvoiceAttachment,
    ){
        this.id = id;
        this.projectId = projectId;
        this.tenantId = tenantId;
        this.vendorId = vendorId;
        this.vendor = vendor;
        this.status = status;
        this.amount = amount;
        this.invoiceDate = invoiceDate;
        this.dueDate = dueDate;
        this.submittedDate = submittedDate;
        this.paidDate = paidDate;
        this.reimbursementSubmittedDate = reimbursementSubmittedDate;
        this.reimbursementReceivedDate = reimbursementReceivedDate;
        this.notes = notes;
        this.attachment = attachment;
    }
}

export class CreateInvoiceRequest{
    projectId: string;
    vendorId:?string;
    amount:?string;
    invoiceDate:?string;
    dueDate:?string;
    submittedDate:?string;
    paidDate:?string;
    reimbursementSubmittedDate:?string;
    reimbursementReceivedDate:?string;
    notes:?string;
    attachment:?CreateInvoiceAttachment;
    approvalDateFirst:?string;
    approvalDateSecond:?string;
    firstApprovedBy:?string;
    secondApprovedBy:?string;
    explanation:?string;
    budgetVsActual:?string;

    constructor(
    projectId: string,
    vendorId:?string,
    amount:?string,
    invoiceDate:?string,
    dueDate:?string,
    submittedDate:?string,
    paidDate:?string,
    reimbursementSubmittedDate:?string,
    reimbursementReceivedDate:?string,
    notes:?string,
    attachment:?CreateInvoiceAttachment,
    approvalDateFirst:?string,
    approvalDateSecond:?string,
    firstApprovedBy:?string,
    secondApprovedBy:?string,
    explanation:?string,
    budgetVsActual:?string,
    ) {
        this.projectId = projectId;
        this.vendorId = vendorId;
        this.amount = amount;
        if(invoiceDate)
        this.invoiceDate = invoiceDate;
        if(dueDate)
        this.dueDate = dueDate;
        if(submittedDate)
        this.submittedDate = submittedDate;
        if(paidDate)
        this.paidDate = paidDate;
        if(reimbursementSubmittedDate)
        this.reimbursementSubmittedDate = reimbursementSubmittedDate;
        if(reimbursementReceivedDate)
        this.reimbursementReceivedDate = reimbursementReceivedDate;
        this.notes = notes;    
        if(attachment)
        this.attachment = attachment;
        if(approvalDateFirst)
        this.approvalDateFirst = approvalDateFirst;
        if(approvalDateSecond)
        this.approvalDateSecond = approvalDateSecond;
        if(firstApprovedBy)
        this.firstApprovedBy = firstApprovedBy;
        if(secondApprovedBy)
        this.secondApprovedBy = secondApprovedBy;
        if(explanation)
        this.explanation = explanation;
        if(budgetVsActual)
        this.budgetVsActual = budgetVsActual;
    }
}

export class GetInvoiceResponse{
    id: string;
    projectId: string;
    tenantId: string;
    vendorId:String;
    status:String;
    amount:String;
    invoiceDate:String;
    dueDate:String;
    submittedDate:String;
    paidDate:String;
    reimbursementSubmittedDate:String;
    reimbursementReceivedDate:String;
    notes:String;
    attachmentType: AttachmentTypes;
    fileType: string;
    getUrl: string;

    constructor(invoice: Invoice, getUrl: string) {
        this.id = invoice.id;
        this.projectId = invoice.projectId;
        this.tenantId = invoice.tenantId;
        this.fileType = invoice.fileType;
        this.attachmentType = invoice.attachmentType;
        this.getUrl = getUrl;
    }
}

export class PostInvoiceResponse{
    id: string;
    projectId: string;
    tenantId: string;
    vendorId:string;
    status:string;
    amount:string;
    invoiceDate:string;
    dueDate:string;
    submittedDate:string;
    paidDate:string;
    reimbursementSubmittedDate:string;
    reimbursementReceivedDate:string;
    notes:string;
    attachmentType: AttachmentTypes;
    fileType: string;
    putUrl: string;

    constructor(invoice: Invoice, putUrl: string) {
        this.id = invoice.id;
        this.projectId = invoice.projectId;
        this.tenantId = invoice.tenantId;
        this.vendorId = invoice.vendorId;
        this.amount = invoice.amount;
        this.dueDate = invoice.dueDate;
        this.submittedDate = invoice.submittedDate;
        this.paidDate = invoice.paidDate;
        this.reimbursementSubmittedDate = invoice.reimbursementSubmittedDate;
        this.reimbursementReceivedDate = invoice.reimbursementReceivedDate;
        this.notes = invoice.notes
        this.fileType = invoice.fileType;
        this.attachmentType = invoice.attachmentType;
        this.putUrl = putUrl;
    }
}

export class UpdateInvoiceRequest{
    invoiceId:string;
    projectId: string;
    vendorId:?string;
    amount:?string;
    invoiceDate:?string;
    dueDate:?string;
    submittedDate:?string;
    paidDate:?string;
    reimbursementSubmittedDate:?string;
    reimbursementReceivedDate:?string;
    notes:?string;
    attachment:?CreateInvoiceAttachment;
    updateAttachment: ?boolean;
    deleteAttachment:?boolean;
    attachmentId:?string;
    approvalDateFirst:?string;
    approvalDateSecond:?string;
    firstApprovedBy:?string;
    secondApprovedBy:?string;
    explanation:?string;
    budgetVsActual:?string;

    constructor(
    invoiceId:String,    
    projectId: string,
    vendorId:?string,
    amount:?string,
    invoiceDate:?string,
    dueDate:?string,
    submittedDate:?string,
    paidDate:?string,
    reimbursementSubmittedDate:?string,
    reimbursementReceivedDate:?string,
    notes:?string,
    attachment:?CreateInvoiceAttachment,
    updateAttachment: ?boolean,
    deleteAttachment:?boolean,
    attachmentId:?string,
    approvalDateFirst:?string,
    approvalDateSecond:?string,
    firstApprovedBy:?string,
    secondApprovedBy:?string,
    explanation:?string,
    budgetVsActual:?string,
    ) {
        this.invoiceId = invoiceId;
        this.projectId = projectId;
        this.vendorId = vendorId;
        this.amount = amount;
        if(invoiceDate)
        this.invoiceDate = invoiceDate;
        if(dueDate)
        this.dueDate = dueDate;
        if(submittedDate)
        this.submittedDate = submittedDate;
        if(paidDate)
        this.paidDate = paidDate;
        if(reimbursementSubmittedDate)
        this.reimbursementSubmittedDate = reimbursementSubmittedDate;
        if(reimbursementReceivedDate)
        this.reimbursementReceivedDate = reimbursementReceivedDate;
        this.notes = notes;    
        if(attachment)
        this.attachment = attachment;
        this.updateAttachment = updateAttachment;
        this.deleteAttachment = deleteAttachment;
        this.attachmentId = attachmentId;
        if(approvalDateFirst)
        this.approvalDateFirst = approvalDateFirst;
        if(approvalDateSecond)
        this.approvalDateSecond = approvalDateSecond;
        if(firstApprovedBy)
        this.firstApprovedBy = firstApprovedBy;
        if(secondApprovedBy)
        this.secondApprovedBy = secondApprovedBy;
        if(explanation)
        this.explanation = explanation;
        if(budgetVsActual)
        this.budgetVsActual = budgetVsActual;
    }
}

export class InvoiceVendor{
    id:string;
    name:string;

    constructor(id:string,name:string){
        this.id = id;
        this.name = name;
    }
}

export  class InvoiceAttachment{
    id: string;
    projectId: string;
    title: ?string;
    description: ?string;
    tenantId: string;
    fileType: string;
    attachmentType: AttachmentTypes;
    getUrl: string;

    constructor(id: string,
                projectId: string,
                title: string,
                description: string,
                tenantId: string,
                fileType: string,
                attachmentType: AttachmentTypes,
                getUrl: string) {
        this.id = id;
        this.projectId = projectId;
        this.title = title;
        this.description = description;
        this.tenantId = tenantId;
        this.fileType = fileType;
        this.attachmentType = attachmentType;
        this.getUrl = getUrl;
    }
}

export  class CreateInvoiceAttachment{
    projectId: string;
    title: ?string;
    description: ?string;
    fileType: string;
    contentType: ?string;
    payload:  string | Buffer;

    constructor(projectId: string, title: string, description: string, fileType: string, contentType: ?string, payload: string | Buffer) {
        this.projectId = projectId;
        this.title = title;
        this.description = description;
        this.fileType = fileType;
        if(contentType) this.contentType = contentType;
        this.payload = payload;
    }
}