//@flow

import styles from "./CreateVendorRequestBuilder.module.css";
import React, {useState} from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage, Field, Form, useField } from "formik";
import { Button, Label, FileInput, Intent, Tag } from "@blueprintjs/core";
import Flexbox from "flexbox-react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { InputGroup, Divider, Card } from "@blueprintjs/core";
import {
    CreateVendorAttachment,
    CreateVendorRequest,
    UpdateVendorRequest,
    VendorAttachment
} from "../../models/Vendor/Vendor";
import { Address } from "../../models/Project/Project";
import { PatternFormat } from "react-number-format";
import DateTimeInput from "../DateTimeInput/DateTimeInput";
import mime from "mime-types";
import axios from "axios";
import { AppToaster } from '../../components/Toaster/Toaster';
import { AttachmentTypeEnum } from "../../models/Attachment/Attachment";


type Props = {
    onNewVendorCreate: (vendorToCreate: CreateVendorRequest) => Promise<any>,
    onUpdateVendor: (vendorToUpdate: CreateVendorRequest) => Promise<any>,
    onNewVendorCreated: () => void,
    onVendorAttachment: (vendorAttachment: VendorAttachment) => Promise<any>,
    submitButtonName: string,
    vendorData: Array<any>
};
type CountryProps = {
    countryInputName: string,
    stateInputName: string,
    disabled?: boolean
};

// const zipRegex = /^\d{5}(?:[-\s]\d{4})?$/;
// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const zipRegex = /^(?!0{3})[0-9]{3,5}$/;
const phoneRegExp = /^(?!0{10})[0-9]{2}[0-9]{8}$/;
const extensionRegex = /^[0-9]{0,4}$/;

function CountryStateDropdownInput(props: CountryProps) {
    // this will return field props for an <input />
    const [countryField, countryMeta] = useField(props.countryInputName);
    const [stateField, stateMeta] = useField(props.stateInputName);

    const countryError =
        countryMeta.error && countryMeta.touched ? (
            <div className={styles.errorText}>{countryMeta.error}</div>
        ) : (
            <div />
        );
    const stateError =
        stateMeta.error && stateMeta.touched ? (
            <div className={styles.errorText}>{stateMeta.error}</div>
        ) : (
            <div />
        );

    return (
        <Flexbox flex={1} flexDirection={"column"}>
            <Flexbox flex={1} flexDirection={"column"}>
                <CountryDropdown
                    disabled={true} //no other country supported as of now
                    classes={styles.fullWidthInput}
                    value={countryField.value}
                    onChange={(newValue: string) => {
                        const event = {
                            target: {
                                name: countryField.name,
                                value: newValue
                            }
                        };
                        countryField.onChange(event);
                    }}
                />
                {countryError}
            </Flexbox>
            <Flexbox flex={1} flexDirection={"column"} marginTop={"10px"}>
                <RegionDropdown
                    disabled={props.disabled}
                    classes={styles.fullWidthInput}
                    country={countryField.value}
                    value={stateField.value}
                    onChange={(newValue: string) => {
                        const event = {
                            target: {
                                name: stateField.name,
                                value: newValue
                            }
                        };
                        stateField.onChange(event);
                    }}
                />
                {stateError}
            </Flexbox>
        </Flexbox>
    );
}

const formStates = {
    INPUT: "INPUT",
    SUBMITTING: "SUBMITTING",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE"
};

export default function CreateVendorRequestBuilder(props: Props) {
    const [state, setState] = useState(formStates.INPUT);
    const { vendorData, submitButtonName } = props;
    const [contact_type, setContact_type] = useState(vendorData && vendorData.contactType ? vendorData.contactType : "Select Contact Type");
    const [selectContactTypeError, setSelectContactTypeError] = useState(false);
    const [files, setFiles] = useState([]);
    const [base64File, setBase64File] = useState([]);
    const [displayUploadedFiles, setUploadedFiles] = useState(
        vendorData
            ? vendorData.vendorAttachments.length
                ? vendorData.vendorAttachments
                : []
            : []
    );
    const [deleteAttachment, setDeleteAttachment] = useState([]);
    const [acceptedFiles, setAcceptedFiles] = useState({});

    const handleKeyPress = (e) => {
        // Prevent non-numeric input
        if (!/[0-9]/.test(e.key)) {
          e.preventDefault();
        }
      };
    
    const createInput = (
        inputName: string,
        inputLabel: string,
        type?: string,
        placeHolderName?: string,
        formik?: FormikValues
      ) => {
        return (
          <Flexbox flexDirection={"column"} >
            <Flexbox flex={1}>
              <label htmlFor={inputName}>{inputLabel}</label>
            </Flexbox>
            <Flexbox >
              {inputName === "phoneNumber" ||
              inputName === "mainNumber" ||
              inputName === "faxNumber" ||
              inputName === "cellNumber" ? (
                <>
                   <PatternFormat
                      className={`bp3-large`}
                      as={InputGroup}
                      name={inputName}
                      placeholder={placeHolderName}
                      format="###-###-####"
                      // mask="_"
                      allowEmptyFormatting
                      type={"tel" || "text"}
                      fill
                      disabled={formik && formik.isSubmitting}
                      value={formik && formik.values[inputName]}
                      onBlur={formik && formik.handleBlur}
                      onValueChange={(values) => {
                        formik && formik.setFieldValue(inputName, values.value);
                      }}
                      style={{ width: 1050, height: "40px", border: "1px solid darkgray" }}
                    />
                  <Label style={{ marginLeft: 10, marginRight: 10 }}>x</Label>
                </>
              ) : (
                <>
                    { inputName ===
                    "phoneNumberExtension"|| inputName ==="mainNumberExtension" || inputName === "faxNumberExtension" || inputName === "cellNumberExtension" ?                   
                    <Field
                        className={`bp3-large`}
                        as={InputGroup}
                        name={inputName}
                        placeholder={placeHolderName}
                        type={"text"}
                        fill
                        maxLength={4}
                        disabled={formik && formik.isSubmitting}
                        onKeyPress={handleKeyPress}
                        style={{ width: 70}}
                    /> :
                    <Field
                        className={`bp3-large`}
                        as={InputGroup}
                        name={inputName}
                        placeholder={placeHolderName}
                        type={"tel"}
                        fill
                        disabled={formik && formik.isSubmitting}
                        style={{ width:"100%",}}
                    />
                    }
                </>
              )}
            </Flexbox>
            <Flexbox
              className={styles.errorText}
              flex={1}
              style={{ overflowWrap: "anywhere" }}
            >
              <ErrorMessage name={inputName} />
            </Flexbox>
          </Flexbox>
        );
      };

  const createSelectInput = (inputName: string, inputLabel: string, select) => {
        return (
            <Flexbox
                flex={1}
                flexDirection={"column"}
                width={"100%"}
                padding={"10px"}
                marginTop={"10px"}
            >
                <Flexbox flex={1}>
                    <label htmlFor={inputName}>{inputLabel}</label>
                </Flexbox>
                <Flexbox width={"100%"}>{select}</Flexbox>
                <Flexbox className={styles.errorText} flex={1}>
                    {inputName == "contact_type" ? (
                        selectContactTypeError ? (
                            "Please select Contact Type"
                        ) : null
                    ) : (
                        <ErrorMessage name={inputName} />
                    )}
                </Flexbox>
            </Flexbox>
        );
    };
        
const handleFileUpload = async (e) => {
    const selectedFiles = e.target.files;

    if (selectedFiles.length > 0) {
        // Check if all selected files are PDFs
        const pdfFiles = Array.from(selectedFiles).every(
            (file) => file.type === "application/pdf");

            if (pdfFiles) {
                setFiles((prevFiles) => [
                    ...prevFiles,
                    ...Array.from(selectedFiles).map((file) => ({
                        name: file.name,
                        base64: URL.createObjectURL(file),
                    })),
                ]);
                const updatedFiles = { ...acceptedFiles };

                // Find the highest key in the current files
                const highestKey = Math.max(...Object.keys(updatedFiles).map(Number), -1);

                let nextKey = highestKey + 1;

                // Convert selectedFiles to an array if it's not already
                const filesArray = Array.isArray(selectedFiles) ? selectedFiles : Array.from(selectedFiles);

                filesArray.forEach((file) => {
                    updatedFiles[nextKey] = file;
                    nextKey += 1; 
                });
                setAcceptedFiles(updatedFiles);

                const base64Files = await Promise.all(
                    Array.from(selectedFiles).map(async (file) => {
                        return new Promise((resolve) => {
                            const reader = new FileReader();

                            reader.onload = () => {
                                resolve(reader.result);
                            };

                            reader.onerror = (err) => {
                                throw err;
                            };

                            reader.readAsDataURL(file);
                        });
                    })
                );
                const fileBase64 = base64File;
                base64Files.map(file=>{
                    fileBase64.push(file);
                })
                setBase64File(fileBase64);
            } else {
                // Handle the case where not all selected files are PDFs
                console.error("Please select only PDF files.");
                // Optionally, you can reset the file input or show a message to the user
            }
        }
    };
    const handleFileDelete = (index) => {
        // Remove the file at the specified index from the files array
        setFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles.splice(index, 1);
            return updatedFiles;
        });
        const updatedFiles = { ...acceptedFiles };
        delete updatedFiles[index]; // Remove the key-value pair

        // Only shift files if the deleted index is not the last one
        if (index < Object.keys(updatedFiles).length) {
            // Shift subsequent files to fill the gap
            for (let i = index; i < Object.keys(updatedFiles).length; i++) {
                updatedFiles[i] = updatedFiles[i + 1] || {};
            }

            // Remove the last key since it is now undefined
            delete updatedFiles[Object.keys(updatedFiles).length - 1];
        }
        setAcceptedFiles(updatedFiles);  
        setBase64File((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles.splice(index, 1);
            return updatedFiles;
        });
    };
    const handleUploadedFileDelete = (index) => {
        console.log('uploadedAttachs', displayUploadedFiles[index]);
        // Remove the file at the specified index from the files array
        setUploadedFiles((prevFiles) => {
            const updatedFiles = [...prevFiles];
            updatedFiles.splice(index, 1);
            return updatedFiles;
        });
        const updatedFiles = deleteAttachment;
        updatedFiles.push(displayUploadedFiles[index].id);
        setDeleteAttachment(updatedFiles);
    };
    const handleViewCertificate = async (request) =>{
  
            if (!request) {
              AppToaster.show({
                message: "File Not uploaded in this Certificate",
                intent: Intent.DANGER,
              });
              return;
            } else {
            const encodedString = (await axios.get(request.getUrl)).data;
            const base = encodedString.split(",")[1];
            const type = encodedString.split(";")[0].split("/")[1];
            const fileType = request.fileType;
            const extension = mime.extension(fileType);
        
            const byteString = atob(base);
        
            const ab = new ArrayBuffer(byteString.length);
            const ia = new Uint8Array(ab);
        
            for (let i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }
          
              let filename = request.title;
          
              const file = new File([ab], filename, { type: type });
          
             
          
              const imageFiles = ["jpg","jpeg","png","gif","webp","tiff","psd","svg"]
          
              if(extension === "pdf"){
          
                let w = window.open("","_blank");
                var x = document.createElement("IFRAME");
                x.setAttribute("src", encodedString);
          
                x.width = "100%";
                x.height = "100%";
          
                  w.document.write(x.outerHTML);
                
              }
              else if(imageFiles.includes(extension)){
                let image = new Image();
                  image.src = encodedString;
          
                  let w = window.open("","_blank");
                  w.document.write(image.outerHTML);
              }
            } 
    }
    let initialValues;

    if (vendorData) {
        initialValues = {
            name: vendorData.name,
            email: vendorData.email,
            phoneNumber: vendorData.phoneNumber && vendorData.phoneNumber.replace(/-/g, ''),
            phoneNumberExtension: vendorData.phoneNumberExtension || "",
            streetAddress: vendorData.address.streetAddress,
            streetAddressTwo: vendorData.address.streetAddressTwo,
            city: vendorData.address.city,
            state: vendorData.address.state,
            country: vendorData.address.country,
            zip: vendorData.address.zip,
            designation: vendorData.designation || "",
            description: vendorData.description || "",
            phoneNumberExtension: vendorData.phoneNumberExtension || '',
            cellNumber: vendorData.cellNumber ?  vendorData.cellNumber.replace(/-/g, '') : '',
            cellNumberExtension: vendorData.cellNumberExtension || "",
            mainNumber: vendorData.mainNumber ? vendorData.mainNumber.replace(/-/g, '') : '',
            mainNumberExtension: vendorData.mainNumberExtension || "",
            faxNumber: vendorData.faxNumber ? vendorData.faxNumber.replace(/-/g, '') : '',
            faxNumberExtension: vendorData.faxNumberExtension || "",
            licenseExpiryDate: vendorData.licenseExpiryDate || "",
            insuranceExpiryDate: vendorData.insuranceExpiryDate || "",
        };
    } else {
        initialValues = {
            name: "",
            email: "",
            phoneNumber: "",
            streetAddress: "",
            streetAddressTwo: "",
            city: "",
            state: "",
            country: "United States",
            licenseExpiryDate: "",
            insuranceExpiryDate: "",
        };
    }

    return (
        <div className={`bp3-running-text bp3-text-large ${styles.formContainer}`}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object({
                    name: Yup.string().required("Required"),
                    email: Yup.string()
                        .email("Invalid email address")
                        .required("Required"),
                    phoneNumber: Yup.string()
                        .matches(phoneRegExp, "Invalid phone number")
                        .required("Required"),
                    phoneNumberExtension: Yup.string().matches(extensionRegex,{message:  "Extension must be at most 4 digits" }),
                    streetAddress: Yup.string().required("Required"),
                    streetAddressTwo: Yup.string(),
                    city: Yup.string().required("Required"),
                    state: Yup.string().required("Required"),
                    zip: Yup.string()
                        .max(10)
                        .matches(zipRegex, { message: "Invalid zip code" })
                        .required("Required"),
                    country: Yup.string().required("Required"),
                    designation: Yup.string(),
                    description: Yup.string(),
                    mainNumber: Yup.string().matches(phoneRegExp, "Invalid main number"),
                    mainNumberExtension: Yup.string().matches(extensionRegex,{message:  "Extension must be at most 4 digits" }),
                    cellNumber: Yup.string().matches(phoneRegExp, "Invalid cell number" ),
                    cellNumberExtension: Yup.string().matches(extensionRegex,{message:  "Extension must be at most 4 digits" }),
                    faxNumber: Yup.string().matches(phoneRegExp, "Invalid fax number"),
                    faxNumberExtension: Yup.string().matches(extensionRegex,{message:  "Extension must be at most 4 digits" }),
                    // licenseExpiryDate: Yup.string(),
                    // insuranceExpiryDate: Yup.string(),
                })}
                onSubmit={async (values) => {
                    if (contact_type == "Select Contact Type") {
                        return setSelectContactTypeError(true);
                    }
                    const address = new Address(
                        values.streetAddress,
                        values.streetAddressTwo,
                        values.city,
                        values.state,
                        values.country,
                        values.zip
                    );
                    let createVendorRequest;
                    let updateVendorData;
                    let createVendorAttachment;
                    const requests = [];
                    const filesArray = Object.values(acceptedFiles)

                    if (base64File) {                        
                        for (
                            let index = 0;
                            index < filesArray.length;
                            index++
                        ) {
                            const file = acceptedFiles[index];
                            const fileString = await new Promise((resolve) => {
                                const reader = new FileReader();

                                reader.onload = () => {
                                    resolve(reader.result);
                                };

                                reader.onerror = (err) => {
                                    throw err;
                                };

                                reader.readAsDataURL(file);
                            });

                            const fileName = file.name;

                            requests.push(
                                new CreateVendorAttachment(
                                    fileName,
                                    fileString.split(":")[1].split(";")[0],
                                    fileString.split(";")[1].split(",")[0],
                                    fileString
                                )
                            );
                        }
                    }
                    createVendorRequest = new CreateVendorRequest(
                        values.name,
                        values.email,
                        values.phoneNumber,
                        values.phoneNumberExtension,
                        address,
                        contact_type,
                        values.cellNumber,
                        values.cellNumberExtension,
                        values.mainNumber,
                        values.mainNumberExtension,
                        values.faxNumber,
                        values.faxNumberExtension,
                        values.designation,
                        values.description,
                        values.licenseExpiryDate,
                        values.insuranceExpiryDate
                    );

                    setState(formStates.INPUT);

                    if (vendorData) {
                        console.log('deleteAttachment', deleteAttachment);
                        updateVendorData = new UpdateVendorRequest(
                            vendorData.id,
                            values.name,
                            values.email,
                            values.phoneNumber,
                            values.phoneNumberExtension,
                            address,
                            contact_type,
                            values.cellNumber,
                            values.cellNumberExtension,
                            values.mainNumber,
                            values.mainNumberExtension,
                            values.faxNumber,
                            values.faxNumberExtension,
                            values.designation,
                            values.description,
                            values.licenseExpiryDate,
                            values.insuranceExpiryDate,
                            deleteAttachment
                        );
                        createVendorAttachment = new VendorAttachment(
                            vendorData.id,
                            requests
                        );
                        await props.onUpdateVendor(updateVendorData);
                        if (requests.length > 0) {
                            await props.onVendorAttachment(
                                createVendorAttachment,
                                updateVendorData,
                                AttachmentTypeEnum.vendor
                            );
                        }
                        setState(formStates.INPUT);
                        setTimeout(() => {
                            props.onNewVendorCreated();
                        }, 500);
                    } else {
                        await props.onNewVendorCreate(
                            createVendorRequest,
                            requests,
                            AttachmentTypeEnum.vendor
                        );
                        setState(formStates.INPUT);
                        setTimeout(() => {
                            props.onNewVendorCreated();
                        }, 500);
                    }
                }}
            >
                {(formik) => (
                    <Form>
                        <Flexbox
                            alignItems={"center"}
                            flexDirection={"column"}
                            className={styles.inputSection}
                        >
                            <Card elevation={1} className={styles.card}>
                                <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                                    <h3>Contact Information</h3>
                                </Flexbox>
                                <Flexbox flex={1} width={"100%"}>
                                    <Divider className={styles.fullWidthInput} />
                                </Flexbox>

                                {createInput("name", "Contact Name")}
                                <Flexbox flex={1} flexDirection={"row"}>
                                {createInput(
                                    "phoneNumber",
                                    "Phone Number",
                                    "phone",
                                    "222-222-2222",
                                    formik
                                )}
                                {createInput("phoneNumberExtension", "Ext.")}
                                </Flexbox>
                                <Flexbox flex={1} flexDirection={"row"}>
                                    {createInput(
                                        "mainNumber",
                                        "Main Number",
                                        "tel",
                                        "222-222-2222",
                                        formik
                                    )}
                                    {createInput("mainNumberExtension", "Ext.")}
                                </Flexbox>{" "}
                                <Flexbox flex={1} flexDirection={"row"}>
                                    {createInput(
                                        "cellNumber",
                                        "Cell Number",
                                        "tel",
                                        "222-222-2222",
                                        formik
                                    )}
                                    {createInput("cellNumberExtension", "Ext.")}
                                </Flexbox>
                                <Flexbox flex={1} flexDirection={"row"}>
                                    {createInput(
                                        "faxNumber",
                                        "Fax",
                                        "tel",
                                        "222-222-2222",
                                        formik
                                    )}
                                    {createInput("faxNumberExtension", "Ext.")}
                                </Flexbox>
                                {createInput("email", "Email", "email")}
                                {createSelectInput(
                                    "contact_type",
                                    "Contact Type",
                                    <select
                                        onChange={(event) => {
                                            if (selectContactTypeError)
                                                setSelectContactTypeError(false);
                                            setContact_type(event.target.value);
                                        }}
                                        name={"contact_type"}
                                        style={{ width: "100%", flex:1, height: 35, marginLeft: -10 }}
                                        className={"bp3-fill"}
                                    >
                                        <option
                                            selected={
                                                contact_type ==
                                                "Select Contact Type"
                                            }
                                            value="Select Contact Type"
                                        >
                                            Select Contact Type
                                        </option>
                                        <option
                                            selected={contact_type == "Vendor"}
                                            value="Vendor"
                                        >
                                            Vendor
                                        </option>
                                        <option
                                            selected={
                                                contact_type == "Donor"
                                                    ? true
                                                    : false
                                            }
                                            value="Donor"
                                        >
                                            Donor
                                        </option>
                                        <option
                                            selected={contact_type == "Other"}
                                            value="Other"
                                        >
                                            Other
                                        </option>
                                    </select>
                                )}
                                {createInput("designation", "Designation")}
                            </Card>

                            <Card elevation={1} className={styles.card}>
                                <Flexbox
                                    flex={1}
                                    alignItems={"flex-start"}
                                    width={"100%"}
                                    className={styles.inputSection}
                                >
                                    <h3>Contact Information</h3>
                                </Flexbox>
                                <Flexbox flex={1} width={"100%"}>
                                    <Divider className={styles.fullWidthInput} />
                                </Flexbox>

                                {createInput("streetAddress", "Street Address")}
                                {createInput("streetAddressTwo", "Street Address Line 2")}
                                {createInput("city", "City")}
                                {createInput("zip", "Zip Code", "number")}
                                <Flexbox
                                    flex={1}
                                    width={"100%"}
                                    paddingTop={"10px"}
                                    paddingBottom={"10px"}
                                    marginTop={"15px"}
                                >
                                    <CountryStateDropdownInput
                                        countryInputName={"country"}
                                        stateInputName={"state"}
                                        disabled={state !== formStates.INPUT}
                                    />
                                </Flexbox>
                                {createInput("description", "Description")}
                            </Card>

                            <Card elevation={1} className={styles.card}>
                                <Flexbox
                                    flex={1}
                                    alignItems={"flex-start"}
                                    width={"100%"}
                                >
                                    <h3>Certificates</h3>
                                </Flexbox>
                                {createSelectInput(
                                    "upload_certificate",
                                    "Upload Certificate",
                                    <FileInput
                                        fill
                                        large
                                        intent={Intent.PRIMARY}
                                        disabled={false}
                                        inputProps={{
                                            accept: ".pdf, application/pdf",
                                            multiple: true,
                                        }}
                                        onInputChange={handleFileUpload}
                                        style={{ width: 340 }}
                                        // disabled={displayUploadedFiles ? true : false}
                                    />
                                )}

                                {files.length > 0 && (
                                    <Flexbox
                                        flex={1}
                                        flexDirection={"column"}
                                        width={340}
                                        padding={"10px"}
                                        marginTop={"10px"}
                                    >
                                        <Flexbox flex={1}>
                                            {files.length > 0 &&
                                                files.map((file, index) => (
                                                    <Tag
                                                        removable={true}
                                                        interactive
                                                        intent={Intent.SUCCESS}
                                                        onClick={() => {
                                                            //file preview for uploaded files from computer
                                                            const pdfWindow =
                                                                window.open();
                                                            pdfWindow.location.href =
                                                                file.base64;
                                                        }}
                                                        onRemove={(event) => {
                                                            event.stopPropagation(); //disables file preview while file removal
                                                            handleFileDelete(index); // Pass the index to handleFileDelete
                                                        }}
                                                    >
                                                        {/* {files[0]} */}

                                                        {file.name}
                                                    </Tag>
                                                ))}
                                        </Flexbox>
                                    </Flexbox>
                                )}
                                <Flexbox
                                    flex={1}
                                    flexDirection={"row"}
                                    width={340}
                                    padding={"10px"}
                                    marginTop={"10px"}
                                    style={{ overflowY: "auto" }}
                                    display="flex"
                                    flexWrap="wrap"
                                >
                                    {/* display files from server */}
                                    {displayUploadedFiles.length > 0 &&
                                        displayUploadedFiles.map(
                                            (file, index) => (
                                                <Flexbox>
                                                    <Tag
                                                        removable={true}
                                                        interactive
                                                        intent={Intent.SUCCESS}
                                                        onClick={() => {
                                                            //file preview for files coming from server
                                                            handleViewCertificate(file)
                                                        }}
                                                        onRemove={(event) => {
                                                            event.stopPropagation(); //disables file preview while file removal
                                                            handleUploadedFileDelete(index)
                                                        }}
                                                    >
                                                        {file.title}
                                                    </Tag>
                                                </Flexbox>
                                            )
                                        )}
                                </Flexbox>
                                {createSelectInput(
                                    "licenseExpiryDate",
                                    "License Expiry Date",
                                    <DateTimeInput
                                        inputName={"licenseExpiryDate"}
                                        selectedDate={
                                            vendorData
                                                ? vendorData.licenseExpiryDate
                                                : null
                                        }
                                        width={340}
                                    />
                                )}
                                {createSelectInput(
                                    "insuranceExpiryDate",
                                    "Insurance Expiry Date",
                                    <DateTimeInput
                                        inputName={"insuranceExpiryDate"}
                                        width={340}
                                        selectedDate={
                                            vendorData
                                                ? vendorData.insuranceExpiryDate
                                                : null
                                        }
                                    />
                                )}
                            </Card>
                            <Flexbox flex={1} marginTop={"15px"}>
                                <Button
                                    type="submit"
                                    className={"bp3-large"}
                                    rightIcon={state === formStates.INPUT ? "arrow-right" : null}
                                    disabled={state !== formStates.INPUT}
                                    loading={state === formStates.SUBMITTING}
                                    icon={state === formStates.SUCCESS ? "saved" : null}
                                >
                                    {state === formStates.INPUT ? submitButtonName : null}
                                </Button>
                            </Flexbox>
                        </Flexbox>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
