//@flow

import React, {useState} from "react";
import Flexbox from "flexbox-react";
import {Button, Card, Spinner} from "@blueprintjs/core";
import Vendor from "../../models/Vendor/Vendor";
import 'react-tiny-fab/dist/styles.css';
import Project from "../../models/Project/Project";
import VendorSuggest from "../VendorSuggest/VendorSuggest";
import {Form, Formik} from "formik";
import styles from "../CreateAssignedTaskRequestBuilder/CreateAssignedTaskRequestBuilder.module.css";
import VendorDisplay from '../VendorDisplay/VendorDisplay'
import { AuthRoles } from "../../blocs/authBloc";

type Props = {
    vendors: ?Array<Vendor>,
    project: Project,
    onVendorRemove: (projectId: string, vendorIdToRemove: string) => Promise<any>,
    onVendorAdd: (projectId: string, vendorIdToAdd: string) => Promise<any>,
}

const formStates = {
    INPUT: 'INPUT',
    SUBMITTING: 'SUBMITTING',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE'
};

export default function ProjectVendorsManger(props: Props) {

    const {vendors, onVendorRemove, onVendorAdd, project} = props;
    const [state, setState] = useState(formStates.INPUT);

    if (!vendors || !project) return <Spinner/>;

    const vendorIdsToVendorsMap: Map<string, Vendor> = vendors.reduce((map, vendor) => {
        map.set(vendor.id, vendor);
        return map;
    }, new Map());

    let filteredVendors = vendorIdsToVendorsMap;

    if (project.vendors) {
        for (let vendor of project.vendors) {
            filteredVendors.delete(vendor.id);
        }
    }

    return (
        <Flexbox height={'100%'} width={'100%'} flexDirection={'column'} padding={'20px'}>
            <Formik
                initialValues={{
                    vendorToAdd: '',
                    vendorToRemove: '',
                }}
                onSubmit={async (values, {resetForm}) => {
                    if (!vendors || !project) return;

                    if (values.vendorToAdd) {
                        setState(formStates.SUBMITTING);
                        await onVendorAdd(project.id, values.vendorToAdd);
                        setState(formStates.SUCCESS);
                    }

                    if (values.vendorToRemove) {
                        setState(formStates.SUBMITTING);
                        await onVendorRemove(project.id, values.vendorToRemove);
                        setState(formStates.SUCCESS);
                    }

                    setState(formStates.INPUT);
                    resetForm({vendorToAdd: '', vendorToRemove: ''});
                }}>
                {formik => {
                    return <Form>
                        <Flexbox flexDirection={'column'}>
                            <Flexbox alignItems={'center'} flexDirection={'row'} className={styles.inputSection}>
                                <VendorSuggest
                                    vendors={Array.from(filteredVendors.values())}
                                    inputName={'vendorToAdd'}
                                />
                                <Button type="submit" minimal
                                        disabled={state !== formStates.INPUT || !formik.values.vendorToAdd ||    AuthRoles.COMPLIANCE_AUDITOR === props.currentUserRole }
                                        loading={state === formStates.SUBMITTING}
                                        icon={state === formStates.INPUT ? 'plus' : null}>
                                    {state === formStates.SUBMITTING ? <Spinner size={1}/> : null}
                                </Button>
                            </Flexbox>
                            <Flexbox flexDirection={'column'}>
                                {project.vendors && project.vendors.map(vendor => {
                                    return <Card className={`bp3-ui-text ${styles.card}`}>
                                        <Flexbox flexDirection={'column'}>
                                            <VendorDisplay
                                            vendor={vendor}
                                            />
                                        </Flexbox>
                                      {    AuthRoles.COMPLIANCE_AUDITOR !== props.currentUserRole &&  <Button text={'Remove'} rightIcon={'cross'} onClick={()=>{
                                            onVendorRemove(project.id, vendor.id)
                                        }}/>}
                                    </Card>
                                })}
                            </Flexbox>
                        </Flexbox>
                    </Form>
                }}
            </Formik>
        </Flexbox>
    )
}
