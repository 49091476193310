import React, { useContext, useEffect, useState } from "react";
import BlocRegistry from "../../blocs/registry";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField, Button, Container, Stack } from '@mui/material';
import { Link } from "react-router-dom"
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { AppToaster } from "../../components/Toaster/Toaster";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from "./ManageNotesView.module.css";
import {
  Intent,
} from "@blueprintjs/core";
import Compressor from "compressorjs";
import { GlobalConstanst } from "../../config/Constant";

import {
  NotesEventTypesEnum as NotesEvent, CreateNoteEvent, UpdateNoteEvent, AddAttachmentsEvent
} from "../../blocs/notesBloc";
import { Spinner } from "@blueprintjs/core";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  outline: 'none'
};

export default function AddUpdateNote({ open, projectId, isEdit, currentNote, handleClose, newlyCreatedNote }) {
  const [notesTitle, setNotesTitle] = useState(isEdit ? currentNote?.title : '')
  const [description, setDescription] = useState(isEdit ? currentNote?.description : '')
  const [title, setTitle] = useState("");
  const [dataUris, setDataUri] = useState([]);
  const [includeInEr, setIncludeInEr] = useState(true);
  const [fileName, SetFileName] = useState("");
  const [isNoteEdit, setIsEdit] = useState(false);
  const [notesLoader, setNotesLoader] = useState(false);
  
  const { notesBloc } = useContext(BlocRegistry);

  const handlePhotoUpload = async (e, dragged = false) => {
    let images = [];
    images = e.target.files;
    for (const image of images) {
      if (!image?.name?.match(/\.(jpg|jpeg|png|gif|webp|tiff|psd|svg)$/)) {
        AppToaster.show({
          message: "Please select valid image",
          intent: Intent.DANGER,
        });
        return false;
      } else {
        let imageBlob = new Blob();
        let imageCompressionRatio = getCompressionRatio(image.size);
        if (imageCompressionRatio) {
          imageBlob = await new Promise((resolve, reject) => {
            new Compressor(image, {
              quality: imageCompressionRatio,
              success: (compressedResult) => {
                resolve(compressedResult);
              },
            });
          });
        } else {
          imageBlob = image;
        }
        const fileString = await new Promise((resolve) => {
          const reader = new FileReader();

          reader.onload = () => {
            resolve(reader.result);
          };

          reader.onerror = (err) => {
            throw err;
          };
          reader.readAsDataURL(imageBlob);
        });
        let fileName;
        fileName =
          title === ""
            ? image?.name
            : `C_${title}.${image?.name.slice(
              ((image?.name.lastIndexOf(".") - 1) >>> 0) + 2
            )}`;
        SetFileName(fileName);
        setDataUri(oldArray => [...oldArray, { dataUri: fileString, fileName: fileName }]);
      }
    };
  };

  const getCompressionRatio = (imageSize) => {
    let ratio = 0.4;
    if (imageSize <= GlobalConstanst.FILE_SIZE_LOW) {
      return false;
    } else if (
      imageSize > GlobalConstanst.FILE_SIZE_LOW &&
      imageSize < GlobalConstanst.FILE_SIZE_MEDIUM
    ) {
      ratio = 0.8;
    } else if (
      imageSize >= GlobalConstanst.FILE_SIZE_MEDIUM &&
      imageSize < GlobalConstanst.FILE_SIZE_MAXIMUM
    ) {
      ratio = 0.7;
    }
    return ratio;
  };

  function handleSubmit(event) {
    event.preventDefault();
    setNotesLoader(true);
    let noteDetail = {};
    noteDetail = {
      "title": notesTitle,
      "description": description,
      "projectId": projectId
    };
    if (isEdit) {
      notesBloc.sendEvent(new UpdateNoteEvent(noteDetail, currentNote?.id));
      setIsEdit(true);
    } else {
      notesBloc.sendEvent(new CreateNoteEvent(noteDetail));
    }
  }

  useEffect(() => {
    const [imageUrlArray, imageArray] = attachmentData();
    if (isNoteEdit && currentNote?.id) {
      notesBloc.sendEvent(new AddAttachmentsEvent({ "attachments": imageArray, "id": currentNote?.id }, imageUrlArray));
    }
  }, [isNoteEdit]);

  useEffect(() => {
    const [imageUrlArray, imageArray] = attachmentData();
    if (newlyCreatedNote) {
      notesBloc.sendEvent(new AddAttachmentsEvent({ "attachments": imageArray, "id": newlyCreatedNote || currentNote?.id }, imageUrlArray));
    }
  }, [newlyCreatedNote]);

  function attachmentData() {
    let imageArray = [];
    let imageUrlArray = [];
    dataUris.forEach(image => {
      let payload2 = {
        "title": image?.fileName,
        "fileType": image?.dataUri.substring(
          image?.dataUri.indexOf(":") + 1,
          image?.dataUri.indexOf(";")
        ),
        "contentType": image?.dataUri.substring(
          image?.dataUri.indexOf(";") + 1,
          image?.dataUri.indexOf(",")
        )
      }
      imageArray.push(payload2);
      let request = {
        projectId: projectId,
        title: image?.fileName,
        description: description,
        fileType: image?.dataUri.substring(
          image?.dataUri.indexOf(":") + 1,
          image?.dataUri.indexOf(";")
        ),
        contentType: image?.dataUri.substring(
          image?.dataUri.indexOf(";") + 1,
          image?.dataUri.indexOf(",")
        ),
        payload: image?.dataUri,
        includeInEr: includeInEr,
      }
      imageUrlArray.push(request);
    });
    return [imageUrlArray, imageArray]
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <React.Fragment>
          <h2>{isEdit ? 'Update Note' : 'Add Note'} </h2>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <form onSubmit={handleSubmit} action={<Link to="" />}>
            <Stack spacing={2}>
              <TextField
                type="text"
                variant='outlined'
                color='primary'
                label="Title"
                onChange={e => setNotesTitle(e.target.value)}
                value={notesTitle}
                fullWidth
                required
                inputProps={{ maxLength: 255 }}
              />
              <TextField
                type="text"
                variant='outlined'
                color='primary'
                label="Description"
                multiline
                rows={2}
                maxRows={4}
                onChange={e => setDescription(e.target.value)}
                value={description}
                fullWidth
                required
                inputProps={{ maxLength: 500 }}
              />
              <TextField
                id="outlined-basic"
                variant="outlined"
                type="file"
                inputProps={{
                  multiple: true,
                  accept: "image/*"
                }}
                text="Upload Photo..."
                onChange={e => handlePhotoUpload(e)}
              />
              {currentNote?.notesAttachments?.map((img, index) => {
                return (<div key={index}>
                  - <span>{img?.title}</span>
                </div>)
              })
              }
              {
                dataUris?.map((img, index) => {
                  return (<div key={index}>
                    - <span>{img?.fileName}</span>
                  </div>)
                })
              }
              { notesLoader && <Spinner/>}
              <Button className={styles.submitBtn} size="small" variant="outlined" color="primary" type="submit">{isEdit ? 'Update' : 'Create'}</Button>
            </Stack>
          </form>
        </React.Fragment>
      </Box>
    </Modal>
  );
}
