//@flow

import React from "react";
import { useField } from "formik";
import styles from "../CreateProjectRequestBuilder/CreateProjectRequestBuilder.module.css";
import { Button, MenuItem, Spinner } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { useState } from "react";

type Props = {
  items: Array<string>,
  inputName: string,
  disabled?: boolean,
  onItemSelect: () => void,
  selectedItem?: any,
};

export default function NumberOfRowSlect(props: Props) {
  const items = [1, 2, 3];
  const renderedItems = items.filter((item) => item != null);
  const [selected, setSelectedItem] = useState(null);
  //   if (!props.items) return <Spinner />;
  return (
    <Select
      filterable={false}
      items={props.items}
      key={props.inputName}
      activeItem={
        selected ? selected : props.selectedItem ? props.selectedItem : null
      }
      onItemSelect={(item) => {
        props.onItemSelect(item);
        setSelectedItem(item);
      }}
      itemRenderer={(item, { handleClick, modifiers }) => {
        return (
          <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            key={item}
            onClick={handleClick}
            text={item}
          />
        );
      }}
      closeOnSelect
    >
      <Button
        rightIcon={props.disabled ? null : "caret-down"}
        text={
          selected
            ? selected
            : props.selectedItem
            ? props.selectedItem
            : "- select -"
        }
      />
    </Select>
  );
  //   }
}
