// @flow

import styles from './NavigationDrawer.module.css'
import React, {useContext, useEffect, useState} from 'react';
import {Divider, Drawer, Menu, MenuItem, Spinner,} from '@blueprintjs/core'
import Flexbox from 'flexbox-react'
import BlocRegistry from "../../blocs/registry";
import {AuthContext, AuthEvents, AuthStates} from "../../blocs/authBloc";
import type {Subscription} from "rxjs";

type Props = { isOpen: boolean, onClose: ()=>any }

export default function NavigationDrawer(props: Props) {

    const {isOpen, onClose} = props;
    const [userInfo, setUserInfo] = useState(null);
    const [userRole, setUserRole] = useState(null);

    const {authBloc}= useContext(BlocRegistry);

    const authEventHandler = {
        next(authContext: AuthContext) {
            switch (authContext.State) {
                case AuthStates.UNINITIALIZED:
                case AuthStates.UNAUTHENTICATED: {
                    break;
                }
                case AuthStates.AUTHENTICATING: {
                    break;
                }
                case AuthStates.AUTHENTICATED: {
                    setUserInfo(authContext.OAuthAccount);
                    localStorage.setItem("CurrentUser", authContext?.OAuthAccount?.name);
                    setUserRole(authContext.role);
                    break;
                }
                default: {
                    throw new Error("Unknown auth state: " + authContext.state);
                }
            }
        },
        error(err: Error) {
            throw err;
        }
    };

    useEffect(() => {
        const subscription: Subscription = authBloc.subscribeToAuthContext(authEventHandler);
        return () => {
            subscription.unsubscribe();
        }
    }, [authBloc]);

    let userInfoSection = <Spinner/>;

    if(userRole && userInfo) {
        userInfoSection = [<h3 className={styles.userInfo}>{`${userInfo.name}`}</h3>,<span className={`bp3-text-muted ${styles.userRole}`}>{`${userRole.description}`}</span>];
    }

    return (
        <Drawer
            canOutsideClickClose
            hasBackdrop
            usePortal
            autoFocus
            isOpen={isOpen}
            onClose={onClose}
            size={'200px'}
        >
            <Flexbox padding={'20px'} flex={1} alignContent={'center'} flexDirection={'column'}>
                {userInfoSection}
                <Divider/>
                <Menu>
                    <MenuItem icon="log-out" text="Logout" onClick={()=>{authBloc.sendEvent(AuthEvents.LOGOUT)}}/>
                </Menu>
            </Flexbox>
        </Drawer>
    )
}
