//@flow

import React, {useState} from "react";
import {useField} from "formik";
import {Checkbox} from "@blueprintjs/core";

type Props = {
    inputName: string,
    disabled?: boolean,
}

export default function CheckboxSelect(props: Props) {
    const [itemField, itemMeta] = useField(props.inputName);
    const valueChangeHandler = itemField.onChange;

    const [checked,setChecked] = useState(itemField.value=== "Yes" ? true:false);

    return (
        <Checkbox
            large
            disabled={props.disabled}
            key={props.inputName}
            checked={checked}
            onChange={(event) => {
                const newValue = checked ? "No" : 'Yes';
                const newEvent = {
                    target: {
                        name: itemField.name,
                        value: newValue,
                    }
                };
                setChecked(newValue === 'Yes');
                valueChangeHandler(newEvent)
            }}/>);
}
