//@Flow
import React, {useEffect, useContext} from 'react';
import './App.css';
import {Switch, Route, Redirect} from 'react-router-dom'
import AppView from "./views/AppView/AppView";
import LoginView from "./views/LoginView/LoginView";
import AuthCallbackView from "./views/AuthCallbackView/AuthCallbackView";
import Forbidden from "./views/Forbidden/Forbidden";
import SecureContainer from "./components/SecureContainer/SecureContainer";
import BlocRegistry from './blocs/registry'

const SecureAppView = SecureContainer(AppView);

function App() {
    const blocRegistry = useContext(BlocRegistry);

    useEffect(() => {
        return () => {
            Object.keys(blocRegistry).forEach(bloc => bloc.dispose)
        }
    }, []);

    return (
        <BlocRegistry.Provider value={blocRegistry}>
            <div className="App App-container">
                <Switch>
                    <Redirect from="/" exact to="/app" />
                    <Route path='/app' render={(props) => <SecureAppView {...props} />} />
                    <Route path='/login' render={(props) => <LoginView {...props} />} />
                    <Route path='/auth/callback' render={(props) => <AuthCallbackView {...props} />} />
                    <Route path='/forbidden' render={(props) => <Forbidden {...props} />} />
                </Switch>
            </div>
        </BlocRegistry.Provider>
    );
}

export default App;
