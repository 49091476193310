//@Flow
import {PartialObserver, Subject} from 'rxjs'
import Attachment, {
    AttachmentTypeEnum,
    CreateAttachmentRequest,
    GetAttachmentResponse,
    PostAttachmentResponse
} from "../models/Attachment/Attachment";
import AttachmentService from "../service/AttachmentService/AttachmentService";
import React from "react";
import type {AttachmentTypes} from "../models/Attachment/Attachment";

export const AttachmentEventTypesEnum = Object.freeze({
    CREATE_ATTACHMENT: Symbol("CREATE ATTACHMENT"),
    DELETE_ATTACHMENT: Symbol("DELETE ATTACHMENT"),
    FETCH_ALL_ATTACHMENTS_FOR_PROJECT: Symbol("FETCH ALL ATTACHMENTS FOR PROJECT"),
});

export type AttachmentEventTypes = $Keys<typeof AttachmentEventTypesEnum>;

export class AttachmentEvent {
    type: AttachmentEventTypes;
    attachmentType: AttachmentEventTypes;

    constructor(type: AttachmentEventTypes, attachmentType: AttachmentTypes) {
        this.type = type;
        this.attachmentType = attachmentType;
    }
}

export class FetchAttachmentsForProjectEvent extends AttachmentEvent {

    projectId: string;

    constructor(projectId: string, attachmentType: AttachmentTypes) {
        super(AttachmentEventTypesEnum.FETCH_ATTACHMENTS_FOR_PROJECT, attachmentType);
        this.projectId = projectId;
    }
}

export class DownloadAllAttachmentsEvents extends AttachmentEvent {

    projectId: string;
    isImages: Boolean;

    constructor(projectId: string, isImages: Boolean) {
        super(AttachmentEventTypesEnum.FETCH_ALL_ATTACHMENTS_FOR_PROJECT);
        this.projectId = projectId;
        this.isImages = isImages;
    }
}

export class CreateAttachmentForProjectEvent extends AttachmentEvent {

    request: CreateAttachmentRequest;

    constructor(attachmentType: AttachmentTypes, createAttachmentRequest: CreateAttachmentRequest) {
        super(AttachmentEventTypesEnum.CREATE_ATTACHMENT, attachmentType);
        this.request = createAttachmentRequest;
    }
}

export class DeleteAttachmentForProjectEvent extends AttachmentEvent {

    projectId: string;
    attachmentId: string;

    constructor(projectId: string, attachmentType: AttachmentTypes, attachmentId: string) {
        super(AttachmentEventTypesEnum.DELETE_ATTACHMENT, attachmentType);
        this.projectId = projectId;
        this.attachmentId = attachmentId;
    }
}

export class AttachmentBloc {
    _outAttachmentStream: Subject = new Subject();

    subscribeToAttachmentContext(observer?: PartialObserver<AttachmentOutEvent>) {
        return this._outAttachmentStream.subscribe(observer);
    }

    _eventController: Subject = new Subject();

    sendEvent(event: AttachmentEvent) {
        return this._eventController.next(event)
    }

    _attachmentService: AttachmentService;

    constructor(attachmentService: AttachmentService) {
        this._attachmentService = attachmentService;
        this._eventController.subscribe(this.buildEventHandler())
    }

    buildEventHandler = () => {
        return {
            next: async (event: AttachmentEvent) => {
                switch (event.type) {
                    case AttachmentEventTypesEnum.FETCH_ATTACHMENTS_FOR_PROJECT: {
                        const projectId = (event: FetchAttachmentsForProjectEvent).projectId;
                        const attachmentType = event.attachmentType;
                        const attachments = await this._attachmentService.getAttachmentsForProject(projectId, attachmentType);
                        this._outAttachmentStream.next(new FetchAttachmentsForProjectOutEvent(projectId, attachments));
                        break;
                    }
                    case AttachmentEventTypesEnum.FETCH_ALL_ATTACHMENTS_FOR_PROJECT: {
                        const projectId = (event: any).projectId;
                        const isImages = (event: any).isImages;
                        const attachments = await this._attachmentService.downloadAllAttachments(projectId, isImages);
                        break;
                    }
                    case AttachmentEventTypesEnum.CREATE_ATTACHMENT: {
                        const projectId = (event: CreateAttachmentForProjectEvent).request.projectId;
                        const attachmentType = (event: CreateAttachmentForProjectEvent).attachmentType;
                        const attachment = await this._attachmentService.createAttachment(attachmentType, (event: CreateAttachmentForProjectEvent).request);
                        this._outAttachmentStream.next(new AddAttachmentsForProjectOutEvent(projectId, [attachment]));
                        break;
                    }
                    case AttachmentEventTypesEnum.DELETE_ATTACHMENT: {
                        const attachmentType = event.attachmentType;
                        const {projectId, attachmentId} = (event: DeleteAttachmentForProjectEvent);
                        await this._attachmentService.deleteAttachment(projectId, attachmentId, attachmentType);
                        this._outAttachmentStream.next(new DeleteAttachmentsForProjectOutEvent(projectId, [attachmentId]));
                        break;
                    }
                    default: {
                        throw new Error("Unknown attachment event: " + event.type);
                    }
                }
            },
            error(err) {
                throw err
            },
        };
    };

    dispose() {
        this._outAttachmentStream.complete();
        this._eventController.complete();
    }
}


export const AttachmentOutEventTypesEnum = Object.freeze({
    FETCHED: Symbol("FETCHED"),
    CREATED: Symbol("CREATED"),
    DELETED: Symbol("DELETED"),
});

export type AttachmentOutEventTypes = $Keys<typeof AttachmentOutEventTypesEnum>;

export class AttachmentOutEvent {
    type: AttachmentOutEventTypes;

    constructor(type: AttachmentOutEventTypes) {
        this.type = type;
    }
}

export class FetchAttachmentsForProjectOutEvent extends AttachmentOutEvent {

    projectId: string;
    attachments: Array<GetAttachmentResponse>;

    constructor(projectId: string, attachments: Array<Attachment>) {
        super(AttachmentOutEventTypesEnum.FETCHED);
        this.attachments = attachments;
        this.projectId = projectId;
    }
}

export class AddAttachmentsForProjectOutEvent extends AttachmentOutEvent {

    projectId: string;
    newAttachments: Array<PostAttachmentResponse>;

    constructor(projectId: string, newAttachments: Array<Attachment>) {
        super(AttachmentOutEventTypesEnum.CREATED);
        this.newAttachments = newAttachments;
        this.projectId = projectId;
    }
}

export class DeleteAttachmentsForProjectOutEvent extends AttachmentOutEvent {

    projectId: string;
    deletedAttachmentIds: Array<string>;

    constructor(projectId: string, deletedAttachmentIds: Array<string>) {
        super(AttachmentOutEventTypesEnum.DELETED);
        this.deletedAttachmentIds = deletedAttachmentIds;
        this.projectId = projectId;
    }
}
