// @flow

import TaskGroup, {CreateTaskGroupRequest} from "../../models/TaskGroup/TaskGroup";
import TaskGroupApi from "../../api/TaskGroupApi/TaskGroupApi";

class TaskGroupService {

    taskGroupApi: TaskGroupApi;

    constructor(taskGroupApi: TaskGroupApi) {
        this.taskGroupApi = taskGroupApi;
    }

    async getAllTaskGroups(taskListId) : Promise<Array<TaskGroup>>{
        return await this.taskGroupApi.getAll(taskListId);
    }

    async createTaskGroup(createTaskGroupRequest: CreateTaskGroupRequest) : Promise<TaskGroup>{
        return await this.taskGroupApi.createTaskGroup(createTaskGroupRequest);
    }
}

export default TaskGroupService;
