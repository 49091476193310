// @flow

import React from 'react';
import { match } from "react-router-dom";
import styles from "./MarketingDashboardView.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faPlusCircle, faTools, faUsers, faBuilding } from '@fortawesome/free-solid-svg-icons'
import { Button } from "@blueprintjs/core";
import DashboardView from "../DashboardView/DashboardView";

type Props = {
  match: match,
  onManageProjectsButtonPress?: () => void,
  onProjectGroupsButtonPress?: () => void,
  onBrandButtonPress?: () => void,
}

const createButton = (index: number, containerStyle, icons: Array<FontAwesomeIcon>, label: string, onClick?: () => void) => {
  let style = ``;

  return (
    <div key={`${index}`} className={containerStyle}>
      <Button onClick={onClick} fill minimal className={`${styles.gridButton} ${style}`} icon={
        <span className={`fa-layers fa-fw ${styles.faIcon}`}>
          {icons}
        </span>
      }>
        <div className={`bp3-ui-text ${styles.buttonLabel}`}>
          {label}
        </div>
      </Button>
    </div>
  )
};

export default function MarketingDashboardView(props: Props) {
  // const disableEdit = AuthRoles.COMPLIANCE_AUDITOR === currentUserRole;
  const buttons = [
    createButton(
      0,
      styles.projectsButton,
      [
        <FontAwesomeIcon icon={faHome} color="#ffffff" transform="grow-60" />,
        <FontAwesomeIcon icon={faTools} color="#43b02a" transform="grow-5" />,
      ],
      "Projects",
      props.onManageProjectsButtonPress
    ),
    createButton(
      1,
      styles.projectGroupsButton,
      [
        <FontAwesomeIcon icon={faHome} color="#ffffff" transform="grow-60" />,
        <FontAwesomeIcon
          icon={faPlusCircle}
          color="#00afd7"
          transform="grow-5"
        />,
      ],
      "Project Groups",
      props.onProjectGroupsButtonPress
    ),
    createButton(
      2,
      styles.brandsButton,
      [
        <FontAwesomeIcon
          icon={faBuilding}
          color="#ffffff"
          transform="grow-60"
        />,

      ],
      "Brand Management",
      props.onBrandButtonPress
    ),
  ];

  return (
    <DashboardView buttons={buttons}>
    </DashboardView>
  )
}
