// @flow

import UserApi from "../../api/UserApi/UserApi";
import Team from "../../models/Team/Team";

class UserService {

    userApi: UserApi;

    constructor(userApi: UserApi) {
        this.userApi = userApi;
    }

    async getTeam() : Promise<Team>{
        const projectManagers = await this.userApi.getProjectMangers();
        const tenantAdmins = await this.userApi.getTenantAdmins();
        const projectAdmins = await this.userApi.getProjectAdmins();

        return new Team(projectManagers, tenantAdmins,projectAdmins)
    }
}

export default UserService;
