//@flow

import styles from "./BrandDetailsView.module.css";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage, Field, Form, useField, useFormikContext,FormikValues } from "formik";
import { Button, Intent, FileInput, Checkbox, Label } from "@blueprintjs/core";
import Flexbox from "flexbox-react";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { InputGroup, Divider, Card } from "@blueprintjs/core";
import FocusError from "../FocusError/FocusError";
import TextSelect from "../TextSelect/TextSelect";
import { AuthRoles } from "../../blocs/authBloc";
import { HOMEOWNER_APP_SECTIONS } from "../../config/Constant";
import _ from "lodash";
import { PatternFormat } from "react-number-format";

type Props = {
  onNewProjectCreated: () => void,
  brand: ?any,
  buttonTitle: ?string,
  currentUserRole: ?Boolean
};
type CountryProps = {
  countryInputName: string,
  stateInputName: string,
  disabled?: boolean
};


const zipRegex = /^(?!0{3})[0-9]{5,5}$/;
 //const phoneRegExp = /^[1-9]\d{2}-\d{3}-\d{4}$/;
const phoneRegExp = /^(?!0{10})[0-9]{2}[0-9]{8}$/;
const extensionRegex = /^[0-9]{0,4}$/;

function CountryStateDropdownInput(props: CountryProps) {
  // this will return field props for an <input />
  const [countryField, countryMeta] = useField(props.countryInputName);
  const [stateField, stateMeta] = useField(props.stateInputName);

  const countryError =
    countryMeta.error && countryMeta.touched ? (
      <div className={styles.errorText}>{countryMeta.error}</div>
    ) : (
      <div />
    );
  const stateError =
    stateMeta.error && stateMeta.touched ? (
      <div className={styles.errorText}>{stateMeta.error}</div>
    ) : (
      <div />
    );

  return (
    <Flexbox flex={1} flexDirection={"column"}>
      <Flexbox flex={1} flexDirection={"column"}>
        <CountryDropdown
          disabled={true} //no other country supported as of now
          classes={styles.fullWidthInput}
          value={countryField.value}
          onChange={(newValue: string) => {
            const event = {
              target: {
                name: countryField.name,
                value: newValue
              }
            };
            countryField.onChange(event);
          }}
        />
        {countryError}
      </Flexbox>
      <Flexbox flex={1} flexDirection={"column"} marginTop={"10px"}>
        <RegionDropdown
          disabled={props.disabled}
          classes={styles.fullWidthInput}
          country={countryField.value}
          value={stateField.value}
          onChange={(newValue: string) => {
            const event = {
              target: {
                name: stateField.name,
                value: newValue
              }
            };
            stateField.onChange(event);
          }}
        />
        {stateError}
      </Flexbox>
    </Flexbox>
  );
}

const formStates = {
  INPUT: "INPUT",
  SUBMITTING: "SUBMITTING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE"
};

export default function BrandDetailsView(props: Props) {
  const [state, setState] = useState(formStates.INPUT);
  const [logo, setLogo] = useState({});
  const [selectedFile, setSelectedFile] = useState(null)
  const [validFileType, setValidFileType] = useState(false)
  const { brand, buttonTitle, loadingBrandInfo } = props;

    const {
    organization_name,
    firstname,
    lastname,
    phonenumber,
    email,
    address1,
    address2,
    city,
    zipcode,
    country,
    organization_phone,
    licenseNumber,
    website,
    county,
    address_type,
    hoaSectionManager,
    phoneNumberExtension,
    organizationPhoneExtension,
  } = {...brand};
  const [appSections, setAppSections] = useState({...hoaSectionManager});
  const handleKeyPress = (e) => {
    // Prevent non-numeric input
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  const createInput = (
    inputName: string,
    inputLabel: string,
    type?: string,
    placeHolderName?: string,
    formik?: FormikValues
  ) => {
    return (
      <Flexbox flex={1} flexDirection={"column"} width={"100%"}>
        <Flexbox flex={1}>
          <label htmlFor={inputName} style={{paddingBottom: 5, paddingTop: 10}}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1} style={{ position: "relative", width: "100%" }}>
          {inputName === "phonenumber" || inputName === "organization_phone" ? (
            <>
               <PatternFormat
                  className={`bp3-large`}
                  as={InputGroup}
                  name={inputName}
                  placeholder={placeHolderName}
                  format="###-###-####"
                  // mask="_"
                  allowEmptyFormatting
                  type={"tel" || "text"}
                  fill
                  disabled={formik && formik.isSubmitting}
                  value={formik && formik.values[inputName]}
                  onBlur={formik && formik.handleBlur}
                  onValueChange={(values) => {
                    formik && formik.setFieldValue(inputName, values.value);
                  }}
                  style={{ width: 1050, height: "40px", border: "1px solid darkgray" }}
                />
              <Label style={{ marginLeft: 10, marginRight: 10 }}>x</Label>
            </>
          ) : (
            <>
            { inputName === "phoneNumberExtension" || inputName === "organizationPhoneExtension" ?
              <Field
                className={`bp3-large`}
                as={InputGroup}
                name={inputName}
                placeholder={placeHolderName}
                type={"tel"}
                fill
                maxLength={4}
                disabled={formik && formik.isSubmitting}
                onKeyPress={handleKeyPress}
                style={{ width: 70 }}
              /> :
              <Field
                className={`bp3-large`}
                as={InputGroup}
                name={inputName}
                placeholder={placeHolderName}
                type={"text"}
                fill
                disabled={formik && formik.isSubmitting}
                style={{ width:"100%" }}
              />
            }
          </>
          )}
        </Flexbox>
        <Flexbox
          className={styles.errorText}
          flex={1}
          style={{
            width: "100%",
            overflowWrap: "anywhere",
          }}
        >
          <ErrorMessage name={inputName} component="div" />
        </Flexbox>
      </Flexbox>
    );
  };
  const createSelectInput = (inputName: string, inputLabel: string, select) => {
    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>{select}</Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if(file.type==="image/png"){
       setValidFileType(false)
        const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    }else{
      setValidFileType(true)
    }
    })
  }

  const setLogoInformation = (file, base64) => {
    let logo = {
      payload: base64,
      fileType: file.type
    }
    setLogo(logo);
  }

  const setAccessRoles = (roleName) => {
    const hoaUpdated = {
      ...appSections,
      [roleName]: !appSections[roleName],
    };
    setAppSections(hoaUpdated);
  };

  useEffect(() => {
    if (hoaSectionManager && Object.keys(hoaSectionManager).length != 0) {
      setAppSections({ ...hoaSectionManager });
    }
  }, []);

  return (
    <div className={`bp3-running-text bp3-text-large ${styles.formContainer}`}>
      <Formik
        initialValues={{
          firstname,
          lastname,
          organization_phone: organization_phone ? organization_phone.replace(/-/g, '') : '',
          email,
          phonenumber: phonenumber ? phonenumber.replace(/-/g, '') : '',
          address1,
          address2,
          website,
          address_type,
          city,
          county,
          zipcode,
          organization_name,
          region: brand && brand ? brand.region : "",
          country: "United States",
          hoaSectionManager: { ...hoaSectionManager },
          licenseNumber: licenseNumber ? licenseNumber : "",
          phoneNumberExtension: phoneNumberExtension ? phoneNumberExtension : "",
          organizationPhoneExtension: organizationPhoneExtension ? organizationPhoneExtension : "",
        }}
        enableReinitialize
        validationSchema={Yup.object({
          organization_phone: Yup.string()
            .matches(phoneRegExp, "Invalid affiliate number")
            .required("Required"),
          firstname: Yup.string().required("Required"),
          lastname: Yup.string().required("Required"),
          email: Yup.string().nullable().email("Invalid email address").required("Required"),
          phonenumber: Yup.string()
            .matches(phoneRegExp, "Invalid phone number")
            .required("Required"),
          address1: Yup.string().required("Required"),
          address2: Yup.string().nullable(),
          organization_name: Yup.string().required("Required"),
          city: Yup.string().required("Required"),
          region: Yup.string().required("Required"),
          zipcode: Yup.string()
            .max(10)
            .matches(zipRegex, { message: "Invalid Zip code" })
            .required("Required"),
          county: Yup.string().required("Required"),
          address_type: Yup.string().required("Required"),
          country: Yup.string().required("Required"),
          licenseNumber: Yup.string(),
          phoneNumberExtension: Yup.string().matches(extensionRegex,{message:  "Extension must be at most 4 digits" }),
          organizationPhoneExtension: Yup.string().matches(extensionRegex,{message:  "Extension must be at most 4 digits" }),
        })}
        onSubmit={async (values) => {
          const hoaData = {
            ...values,
            hoaSectionManager: appSections,
          };
          props.onCreateOrUpdateBrand(hoaData, brand && brand.email ? true : false, logo);
          setSelectedFile(null);
        }}
      >
        {(formik) => (
          <Form>
            <Card elevation={1} className={styles.card}>
              <Flexbox
                flex={1}
                alignItems={"flex-start"}
                width={"100%"}
                className={styles.inputSection}
              >
                <h3>Affiliate Information</h3>
              </Flexbox>
              <Flexbox flex={1} width={"100%"}>
                <Divider className={styles.fullWidthInput} />
              </Flexbox>
              {createInput("organization_name", "Affiliate Name")}
              {createInput("address1", "Street Address")}
              {createInput("address2", "Street Address Line 2")}
              <Flexbox flexDirection={"row"}>
                <Flexbox style={{ marginTop: 25 }}>
                  {createSelectInput(
                    "address_type",
                    
                    <TextSelect
                      items={["City", "Village", "Township"]}
                      inputName={"address_type"}
                    />
                  )}
              </Flexbox>
              <Flexbox width={"100%"}>
              {createInput("city", "City/Village/Township")}
              </Flexbox>
            </Flexbox>
            
            {createInput("county", "County")}
            {createInput("zipcode", "Zip Code", "number")}
              <Flexbox flex={1} flexDirection={"row"}>
                {createInput("organization_phone", 
                "Affiliate Phone",  
                "tel",
                "222-222-2222",
                formik)}
                {createInput("organizationPhoneExtension", "Ext.")}
              </Flexbox>
            {createInput("licenseNumber", "License Number")}
            {createInput("website", "Website")}
            <div class="sc-bdVaJa kZPOHL" style={{ paddingBottom: 5, paddingTop: 10, }}>
              <label for="profile">Logo</label>
            </div>
          <FileInput
            fill
            large
            intent={Intent.PRIMARY}
              disabled={false}
              inputProps={{ accept: "image/*" }}
              text= {selectedFile ? selectedFile.name : "Upload Photo..."}
              onInputChange={async (event)=>{
                let file = event.target.files[0];
                setSelectedFile(file);
                let data = await convertBase64(file);
                setLogoInformation(file, data);
              }}
            />
            <Flexbox style={{color:"red"}}>{validFileType?"Only PNG format is allowed.":""}</Flexbox>
            <Flexbox
              flex={1}
              width={"100%"}
              paddingTop={"10px"}
              paddingBottom={"10px"}
              marginTop={"15px"}
            >
              <CountryStateDropdownInput
                countryInputName={"country"}
                stateInputName={"region"}
                disabled={state !== formStates.INPUT}
              />
            </Flexbox>
          </Card>
          <Flexbox
            alignItems={"center"}
            flexDirection={"column"}
            className={styles.inputSection}
          >
            <Card elevation={1} className={styles.card}>
              <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                <h3>Tenant Contact Information</h3>
              </Flexbox>
              <Flexbox flex={1} width={"100%"}>
                <Divider className={styles.fullWidthInput} />
              </Flexbox>

              {createInput("firstname", "First Name")}
              {createInput("lastname", "Last Name")}
                <Flexbox flex={1} flexDirection={"row"}>
                  {createInput(
                    "phonenumber",
                    "Phone Number",
                    "tel",
                    "222-222-2222",
                    formik
                  )}
                  {createInput("phoneNumberExtension", "Ext.")}
                </Flexbox>
              {createInput("email", "Email", "email")}
            </Card>
            <Card elevation={1} className={styles.card}>
              <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                <h3>Homeowner Application Setting</h3>
              </Flexbox>
              <Flexbox flex={1} width={"100%"}>
                <Divider className={styles.fullWidthInput} />
              </Flexbox>
              <div>
                <Checkbox
                  label="Applicant Information"
                  checked={true}
                  disabled
                  onChange={(event) => {
                    setAccessRoles(HOMEOWNER_APP_SECTIONS.APP_INFORMATION);
                  }}
                />
                <Checkbox
                  label="Documenting Client Status as 'Severely Disabled Adult"
                  checked={appSections?.showDocumentClientAsSDA}
                  onChange={(event) => {
                    setAccessRoles(HOMEOWNER_APP_SECTIONS.CLIENT_AS_SDA);
                  }}
                />
                <Checkbox
                  label="Certification Regarding Compliance with Lead-safe Housing Rule"
                  checked={appSections?.showCertifyComplianceWithLSHR}
                  onChange={(event) => {
                    setAccessRoles(HOMEOWNER_APP_SECTIONS.COMPLIENCE_WITH_LSHR);
                  }}
                />
                <Checkbox
                  label="Conflict of Interest Disclosure"
                  checked={appSections?.showConflictOfInterestDisclosure}
                  onChange={(event) => {
                    setAccessRoles(HOMEOWNER_APP_SECTIONS.CONFLICT_OF_INTEREST);
                  }}
                />
                <Checkbox
                  label="Homeowner's Agreement"
                  checked={appSections?.showHomeownerAgreement}
                  onChange={(event) => {
                    setAccessRoles(HOMEOWNER_APP_SECTIONS.HOMEOWNER_AGREEMENT);
                  }}
                />
              </div>

            </Card>
            {props.currentUserRole !== AuthRoles.MARKETING_MANAGER &&
              <Flexbox flex={1} marginTop={"15px"}>
                <Button
                  type="submit"
                  className={"bp3-large"}
                  rightIcon={state === formStates.INPUT ? "arrow-right" : null}
                  disabled={state !== formStates.INPUT || validFileType}
                  loading={loadingBrandInfo}
                  icon={state === formStates.SUCCESS ? "saved" : null}
                >
                  {brand && brand ? "Update" : "Create"}
                </Button>
              </Flexbox>
            }            
            </Flexbox>
            <FocusError />
          </Form>
        )}
      </Formik>
    </div>
  );
}
