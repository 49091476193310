import { Alert, Intent, ProgressBar } from "@blueprintjs/core";
import React, { useState } from "react";
type Props = {
  isOpen: boolean,
  confirmButtonText: string,
  icon: string,
  cancelButtonText: string,
  type: Intent,
  onConfirm: () => any,
  onCancel: () => any,
  message: string,
  message2: string,
  confirmPressed: Boolean,
  showProgressBar: boolean,
  progressBarSteps: number,
};

export default function DeleteConfirmationPopup(props: Props) {
  const {
    isOpen,
    confirmButtonText,
    icon,
    cancelButtonText,
    type,
    onConfirm,
    onCancel,
    message,
    message2,
    confirmPressed,
    showProgressBar,
    progressBarSteps,
    notes,
  } = props;
  const totalSteps = 3;
  return (
    <Alert
      isOpen={isOpen}
      cancelButtonText={cancelButtonText}
      confirmButtonText={confirmButtonText}
      icon={icon}
      intent={type}
      onCancel={() => onCancel()}
      onConfirm={id => onConfirm(id)}
    >
      <p>{message}</p>
      <p>{message2}</p>
      { notes && notes?.length &&
        <p>{notes}</p>
      }
      {showProgressBar && confirmPressed && (
        <ProgressBar />
      )}
    </Alert>
  );
}
