//@flow

import React from "react";
import { MenuItem, Spinner } from "@blueprintjs/core";
import { Suggest } from "@blueprintjs/select";
import type User from "../../models/User/User";
import { useField } from "formik";
import styles from "../CreateProjectRequestBuilder/CreateProjectRequestBuilder.module.css";
import CustomSuggest from "../CustomSuggest/CustomSuggest";
import type { QuantityTypeEnumTypes } from "../../models/Task/Task";
import { QuantityTypeEnum } from "../../models/Task/Task";

type Props = {
  units: ?Array<string>,
  inputName?: string,
  disabled:boolean
};

export default function UnitSuggest(props: Props) {
  const selectedItem = findUnitById(props.selectedItem, props.units);

  return (
    <CustomSuggest
      disabled={props.disabled}
      inputName={props.inputName}
      items={props.units}
      filterItems={filterUnits}
      inputValueRenderer={inputValueRenderer}
      inputSelectValueMapper={inputSelectValueMapper}
      itemToKeyMapper={itemToKeyMapper}
      defaultSelectedItem={selectedItem}
    />
  );
}

function itemToKeyMapper(unit: QuantityTypeEnumTypes) {
  return unit;
}

function inputSelectValueMapper(unit: QuantityTypeEnumTypes) {
  return unit;
}

function inputValueRenderer(unit: QuantityTypeEnumTypes) {
  switch (unit) {
    case QuantityTypeEnum.FLOAT: {
      return "Number";
    }
    default: {
      return unit;
    }
  }
}

function filterUnits(query, unit, _index, exactMatch) {
  const normalizedUnit = unit.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedUnit === normalizedQuery;
  } else {
    return `${normalizedUnit}`.indexOf(normalizedQuery) >= 0;
  }
}

const findUnitById = (unitId: ?string, units) => {
  if (!units || !unitId) return null;
  return units.find(unit => {
    return unit === unitId;
  });
};
