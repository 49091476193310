//@flow

import React from "react";
import CustomSuggest from "../CustomSuggest/CustomSuggest";
import type { PriceTypeEnumTypes } from "../../models/Task/Task";
import { PriceTypeEnum } from "../../models/Task/Task";

type Props = {
  priceTypes: ?Array<string>,
  inputName?: string
};

export default function PriceTypeSuggest(props: Props) {
  const selectedItem = findUnitById(props.selectedItem, props.priceTypes);
  return (
    <CustomSuggest
      inputName={props.inputName}
      items={props.priceTypes}
      filterItems={filterPriceTypes}
      inputValueRenderer={inputValueRenderer}
      inputSelectValueMapper={inputSelectValueMapper}
      itemToKeyMapper={itemToKeyMapper}
      defaultSelectedItem={selectedItem}
    />
  );
}

function itemToKeyMapper(priceType: PriceTypeEnumTypes) {
  return priceType;
}

function inputSelectValueMapper(priceType: PriceTypeEnumTypes) {
  return priceType;
}

function inputValueRenderer(priceType: PriceTypeEnumTypes) {
  switch (priceType) {
    case PriceTypeEnum.PER_BID: {
      return "Per Bid";
    }
    default: {
      return priceType;
    }
  }
}

function filterPriceTypes(query, priceType, _index, exactMatch) {
  const normalizedPriceType = priceType.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedPriceType === normalizedQuery;
  } else {
    return `${normalizedPriceType}`.indexOf(normalizedQuery) >= 0;
  }
}

const findUnitById = (unitId: ?string, units) => {
  if (!units || !unitId) return null;
  return units.find(unit => {
    return unit === unitId;
  });
};
