//@flow

import React from "react";
import CustomSuggest from "../CustomSuggest/CustomSuggest";
import User from "../../models/User/User";

type Props = {
  users: ?Array<User>,
  inputName?: string
};

export default function UserSuggest(props: Props) {
  const selectedUser = findUserById(props.selectedItem, props.users);

  return (
    <CustomSuggest
      inputName={props.inputName}
      items={props.users}
      filterItems={filterUser}
      inputValueRenderer={inputValueRenderer}
      inputSelectValueMapper={inputSelectValueMapper}
      itemToKeyMapper={itemToKeyMapper}
      itemsEqual={"id"}
      defaultSelectedItem={selectedUser}
    />
  );
}

function itemToKeyMapper(user: User) {
  return user.id;
}

function inputSelectValueMapper(user: User) {
  return user.id;
}

function inputValueRenderer(user: User) {
  return `${user.firstName} ${user.lastName}`;
}

function filterUser(query, user, _index, exactMatch) {
  const normalizedName = `${user.firstName} ${user.lastName}`.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedName === normalizedQuery;
  } else {
    return `${normalizedName}`.indexOf(normalizedQuery) >= 0;
  }
}

const findUserById = (userId: ?string, users: ?Array<User>): ?User => {
  if (!users || !userId) return null;
  return users.find(user => {
    return user.id === userId;
  });
};
