// @flow

import type { AuthBloc } from "../../blocs/authBloc";
import type Project, {
  UpdateProjectRequest
} from "../../models/Project/Project";
import { apiAxios } from "../../config/AxiosConfig";
import { CreateProjectRequest } from "../../models/Project/Project";
import { AppToaster } from "../../components/Toaster/Toaster";
import { Intent, Spinner } from "@blueprintjs/core";
import history from "../../history";
class ProjectApi {
  authBloc: AuthBloc;

  constructor(authBloc: AuthBloc) {
    this.authBloc = authBloc;
  }

  async getAll(projectStates:boolean): Promise<Array<Project>> {
    const tenantId = this.authBloc.getCurrentTenant();

    const response = await apiAxios.get(
      `/tenants/${tenantId}/projects?projectStates=${projectStates ? projectStates:[]}`,
      this.buildHeaders()
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.status);
    }
  }

  async createProject(
    createProjectRequest: CreateProjectRequest
  ): Promise<Project> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.post(
        `/tenants/${tenantId}/projects`,
        createProjectRequest,
        this.buildHeaders()
      );
      if (response.status === 201) {
        AppToaster.show({
          message: "Project Created",
          intent: Intent.PRIMARY
        });

        history.goBack();
        return response.data;
      } else {
        throw new Error(response.status);
      }
    } catch (error) {
      if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER
        });
      } else {
        throw new Error(error);
      }
    }
  }

  async updateCustomMilestone(
    updateCustomMilestone: any,
    projectId: string,
  ): Promise<any> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.post(
        `/tenants/${tenantId}/projects/${projectId}/milestones`,
        updateCustomMilestone,
        this.buildHeaders()
      );
      if (response.status === 200 || response.status === 204) {
        return response.data;
      } else {
        throw new Error(response.status);
      }
    } catch (error) {
      if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER
        });
      } else {
        throw new Error(error);
      }
    }
  }

  async updateCustomMilestoneDate(
    request: any,
    projectId: string,
  ): Promise<any> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.patch(
        `/tenants/${tenantId}/projects/${projectId}`,
        request,
        this.buildHeaders()
      );
      if (response.status === 200 || response.status === 204) {
        return response.data;
      } else {
        throw new Error(response.status);
      }
    } catch (error) {
      if (error.response.data.message) {
        AppToaster.show({
          message: error.response.data.message,
          intent: Intent.DANGER
        });
      } else {
        throw new Error(error);
      }
    }
  }

  async updateProject(
    projectId: string,
    updateProjectRequest: UpdateProjectRequest
  ): Promise<void> {
    const tenantId = this.authBloc.getCurrentTenant();
    const response = await apiAxios
      .patch(
        `/tenants/${tenantId}/projects/${projectId}`,
        updateProjectRequest,
        this.buildHeaders()
      )
      .then(response => {
        if (response.status === 204) {
          if (updateProjectRequest.fromEditProject) {
            AppToaster.show({
              message: "Project Updated",
              intent: Intent.PRIMARY
            });
            history.goBack();
          }
          if(updateProjectRequest.projectState){
            AppToaster.show({
              message: `Project ${updateProjectRequest.projectState}`,
              intent: Intent.PRIMARY
            });
          }
          return response.data;
        } else {
          throw new Error(response.status);
          // return false
        }
      })
      .catch(error => {
        const response = error.response;
        const data = response && response.data ? response.data : "";
        const message = data && data.message ? data.message : "";
        if (message) {
          AppToaster.show({
            message: message,
            intent: Intent.DANGER
          });
        } else {
          throw new Error(error);
        }
        return false;
      });
    return response;
  }

  async addVendorToProject(
    projectId: string,
    vendorIdToAdd: string
  ): Promise<Project> {
    const tenantId = this.authBloc.getCurrentTenant();

    const response = await apiAxios.post(
      `/tenants/${tenantId}/projects/${projectId}/vendors`,
      { id: vendorIdToAdd },
      this.buildHeaders()
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.status);
    }
  }

  async removeVendorFromProject(
    projectId: string,
    vendorToRemove: string
  ): Promise<Project> {
    const tenantId = this.authBloc.getCurrentTenant();

    const response = await apiAxios.delete(
      `/tenants/${tenantId}/projects/${projectId}/vendors/${vendorToRemove}`,
      this.buildHeaders()
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.status);
    }
  }

  async removeProject(projectId: string): Promise<Project> {
    const tenantId = this.authBloc.getCurrentTenant();
    const response = await apiAxios
      .delete(`/tenants/${tenantId}/projects/${projectId}`, this.buildHeaders())
      .then(response => {
        if (response.status === 200) {
          AppToaster.show({
            message: "Project Deleted",
            intent: Intent.PRIMARY
          });
          return response.data;
        } else {
          throw new Error(response.status);
        }
      })
      .catch(err => {
        const { message } = err.response.data;
        if (message) {
          AppToaster.show({
            message: message,
            intent: Intent.DANGER
          });
        }
       
      });
  }

  buildHeaders = () => {
    const accessToken = this.authBloc.getCurrentToken();
    const auth = "Bearer " + accessToken;
    return { headers: { Authorization: auth } };
  };
}

export default ProjectApi;
