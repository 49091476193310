//@flow

import React from "react";
import ProjectGroup from "../../models/ProjectGroup/ProjectGroup";
import CustomMultiSuggest from "../CustomMultiSuggest/CustomMultiSuggest";

type Props = {
  projectGroups: ?Array<ProjectGroup>,
  inputName?: string
};

export default function ProjectGroupSuggest(props: Props) {
  return (
    <CustomMultiSuggest
      inputName={props.inputName}
      items={props.projectGroups}
      filterItems={filterProjectGroup}
      inputValueRenderer={inputValueRenderer}
      inputSelectValueMapper={inputSelectValueMapper}
      itemToKeyMapper={itemToKeyMapper}
      itemsEqual={"id"}
      selectedItems={props.selectedItems}
    />
  );
}

function itemToKeyMapper(projectGroup: ProjectGroup) {
  return projectGroup.id;
}

function inputSelectValueMapper(
  projectGroups: Array<ProjectGroup>,
  projectGroup: ProjectGroup
) {
  let index = projectGroups.findIndex(group => group.id === projectGroup.id);
  if (index !== -1) {
    projectGroups.splice(index, 1);
  } else {
    projectGroups.push(projectGroup);
  }
  return projectGroups;
}

function inputValueRenderer(projectGroup: ProjectGroup) {
  return `${projectGroup.name}`;
}

function filterProjectGroup(query, projectGroup, _index, exactMatch) {
  const normalizedName = `${projectGroup.name}`.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedName === normalizedQuery;
  } else {
    return `${normalizedName}`.indexOf(normalizedQuery) >= 0;
  }
}
