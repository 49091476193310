// @flow

import type Attachment, {AttachmentTypes} from "../../models/Attachment/Attachment";
import AttachmentStorageApi from "../../api/AttachmentApi/AttachmentStorageApi";
import {AttachmentTypeEnum, GetAttachmentResponse, PostAttachmentResponse} from "../../models/Attachment/Attachment";
import InvoiceApi from "../../api/InvoiceApi/InvoiceApi";
import { CreateInvoiceRequest, PostInvoiceResponse, UpdateInvoiceRequest } from "../../models/Invoice/Invoice";

class InvoiceService {

    invoiceApi: InvoiceApi;
    attachmentStorageApi: AttachmentStorageApi;

    constructor(invoiceApi: InvoiceApi,attachmentStorageApi: AttachmentStorageApi) {
        this.invoiceApi = invoiceApi;
        this.attachmentStorageApi = attachmentStorageApi;
    }

    async getInvoicesForProject(projectId: string, attachmentType: AttachmentTypes): Promise<Array<GetAttachmentResponse>> {
        return await this.invoiceApi.getInvoicesForProject(projectId, attachmentType);
    }

    async createInvoice(attachmentType: AttachmentTypeEnum, createInvoiceRequest: CreateInvoiceRequest): Promise<PostInvoiceResponse> {
        const invoice = await this.invoiceApi.createInvoice(attachmentType, createInvoiceRequest);
        if(invoice.attachment){
        await this.attachmentStorageApi.putAttachmentPayload(invoice.attachment.putUrl, createInvoiceRequest, attachmentType);
        }
        return invoice;
    }

    async deleteInvoice(projectId: string, invoiceId: string, attachmentType: AttachmentTypes): Promise<void> {
        await this.invoiceApi.deleteInvoice(projectId, attachmentType, invoiceId);
    }

    async updateInvoice(attachmentType: AttachmentTypeEnum, updateInvoiceRequest: UpdateInvoiceRequest): Promise<PostInvoiceResponse> {
        const invoice = await this.invoiceApi.updateInvoice(attachmentType, updateInvoiceRequest);
        if(invoice){
            await this.attachmentStorageApi.putAttachmentPayload(invoice.putUrl, updateInvoiceRequest, attachmentType);
            }
        return invoice;
    }
}

export default InvoiceService;
