// @flow

export const AttachmentTypeEnum = Object.freeze({
    image: 'images',
    file: 'files',
    invoice:'invoices',
    vendor: 'vendor',
});

export type AttachmentTypes = $Keys<typeof AttachmentTypeEnum>;

export default class Attachment{
    id: string;
    projectId: string;
    title: ?string;
    description: ?string;
    tenantId: string;
    fileType: string;
    attachmentType: AttachmentTypes;
    includeInEr:?boolean;
    vendorId: String;

    constructor(id: string,
                projectId: string,
                title: string,
                description: string,
                tenantId: string,
                fileType: string,
                attachmentType: AttachmentTypes,
                includeInEr:?boolean,
                vendorId: String,) {
        this.id = id;
        this.projectId = projectId;
        this.title = title;
        this.description = description;
        this.tenantId = tenantId;
        this.fileType = fileType;
        this.attachmentType = attachmentType;
        this.includeInEr = includeInEr;
        this.vendorId = vendorId;
    }
}

export class CreateAttachmentRequest{
    projectId: string;
    title: ?string;
    description: ?string;
    fileType: string;
    contentType: ?string;
    payload:  string | Buffer;
    includeInEr:?boolean;
    vendorId: String;

    constructor(projectId: string, title: string, description: string, fileType: string, contentType: ?string, payload: string | Buffer, includeInEr:?boolean, vendorId: string,) {
        this.projectId = projectId;
        this.title = title;
        this.description = description;
        this.fileType = fileType;
        if(contentType) this.contentType = contentType;
        this.payload = payload;
        if(includeInEr)
        this.includeInEr = includeInEr;
        this.vendorId = vendorId;
    }
}

export class GetAttachmentResponse{
    id: string;
    projectId: string;
    title: ?string;
    description: ?string;
    tenantId: string;
    attachmentType: AttachmentTypes;
    fileType: string;
    getUrl: string;
    vendorId: String;

    constructor(attachment: Attachment, getUrl: string) {
        this.id = attachment.id;
        this.projectId = attachment.projectId;
        this.title = attachment.title;
        this.description = attachment.description;
        this.tenantId = attachment.tenantId;
        this.fileType = attachment.fileType;
        this.attachmentType = attachment.attachmentType;
        this.getUrl = getUrl;
        this.vendorId = attachment.vendorId;
    }
}

export class PostAttachmentResponse{
    id: string;
    projectId: string;
    title: ?string;
    description: ?string;
    tenantId: string;
    attachmentType: AttachmentTypes;
    fileType: string;
    putUrl: string;
    vendorId: String;

    constructor(attachment: Attachment, putUrl: string) {
        this.id = attachment.id;
        this.projectId = attachment.projectId;
        this.title = attachment.title;
        this.description = attachment.description;
        this.tenantId = attachment.tenantId;
        this.fileType = attachment.fileType;
        this.attachmentType = attachment.attachmentType;
        this.putUrl = putUrl;
        this.vendorId = attachment.vendorId
    }
}
