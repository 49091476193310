//@flow

import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage, Field, Form, useField } from "formik";
import Flexbox from "flexbox-react";
import {
  Button,
  Spinner,
  InputGroup,
  Divider,
  Card,
  Checkbox,
} from "@blueprintjs/core";
import styles from "./CreateProjectGroupRequestBuilder.module.css";
import UnitSuggest from "../UnitSuggest/UnitSuggest";
import ProjectGroup, {
  ProjectGroupStatusTypeEnum,
  CreateProjectGroupRequest,
  UpdateProjectGroupRequest,
} from "../../models/ProjectGroup/ProjectGroup";
import Task from "../../models/Task/Task";
import CheckboxSelect from "../CheckboxSelect/CheckboxSelect";
import TaskGroup from "../../models/TaskGroup/TaskGroup";
import AddDefaultTask from "../AddDefaultTask/AddDefaultTask";

type Props = {
  tasks: ?Array<Task>,
  onAddProjectGroupButtonClick: (
    projectGroupToCreate: CreateProjectGroupRequest
  ) => any,
  onUpdateProjectGroupButtonClick: (
    updateProjectGroupRequest: UpdateProjectGroupRequest
  ) => any,
  projectGroupData: ?any,
  submitButtonTitle: ?any,
  taskGroups: ?Array<TaskGroup>
};

const formStates = {
  INPUT: "INPUT",
  SUBMITTING: "SUBMITTING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};

function CreateProjectGroupRequestBuilder(props: Props) {
  const [state, setState] = useState(formStates.INPUT);

  const {
    tasks,
    taskGroups,
    onAddProjectGroupButtonClick,
    onUpdateProjectGroupButtonClick,
    projectGroupData,
    submitButtonTitle,
  } = props;

  let initialValues;

  if (projectGroupData) {
    initialValues = {
      name: projectGroupData.name || "",
      status: projectGroupData.status || "",
      grantNumber: projectGroupData.grantNumber || "",
      grantName: projectGroupData.grantName || "",
      grantAmount: projectGroupData.grantAmount || "",
    };

    if (projectGroupData && projectGroupData.taskList.length) {
      projectGroupData.taskList.map(
        (assignedTask) => (initialValues[assignedTask.taskId] = "Yes")
      );
    }

  } else {
    initialValues = {
      name: "",
      grantNumber: "",
      grantName: "",
      grantAmount: "",
    };
  }

  const createInput = (
    inputName: string,
    inputLabel: string,
    type?: string,
    placeHolderName?: string
  ) => {
    return (
      <Flexbox flex={1} flexDirection={"column"} width={"100%"}>
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>
          <Field
            className={`bp3-large`}
            as={InputGroup}
            name={inputName}
            placeholder={placeHolderName}
            type="text"
            fill
            disabled={state !== formStates.INPUT}
          />
        </Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const createSuggestionInput = (
    inputName: string,
    inputLabel: string,
    input
  ) => {
    return (
      <Flexbox flex={1} flexDirection={"column"} width={"100%"}>
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>{input}</Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const createSelectInput = (inputName: string, inputLabel: string, select) => {
    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
          <Flexbox flex={1}>
            {select} <label htmlFor={inputName}>{inputLabel}</label>
          </Flexbox>
        </Flexbox>

        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };
  

  let tasklist = <Spinner />;

  if (tasks && tasks.length) {
    tasklist = tasks
      .sort(function (a, b) {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
      .map((task, index) => (
        <Flexbox width={"50%"}>
          {createSelectInput(
            task.id,
            task.title,
            <CheckboxSelect
              inputName={task.id}
              // disabled={disableEdit}
            />
          )}
        </Flexbox>
      ));
  }
  let disabled = tasks?.length && tasks[0]?.is_master != null ? tasks[0]?.is_master : false;
  return (
    <div className={`bp3-running-text bp3-text-large ${styles.formContainer}`}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object({
          name: Yup.string().max(128).required("Required"),
          grantNumber: Yup.string(),
          grantName: Yup.string(),
          grantAmount: Yup.string(),
        })}
        onSubmit={async (values) => {
          let copyRequest = { ...values };

          delete copyRequest.name;
          delete copyRequest.status;
          delete copyRequest.grantNumber;
          delete copyRequest.grantName;
          delete copyRequest.grantAmount;

          Object.keys(copyRequest).map((key) => {
            if (copyRequest[key] === "No") {
              delete copyRequest[key];
            }
          });

          let seletedTasks = Object.keys(copyRequest);

          const taskIdList = seletedTasks.map((task) => ({ id: task }));

         

          if (projectGroupData) {
            const updateProjectGroupRequest = new UpdateProjectGroupRequest(
              projectGroupData.id,
              values.name,
              projectGroupData.status,
              taskIdList,
              false,
              values.grantNumber,
              values.grantName,
              values.grantAmount
            );

         

            await onUpdateProjectGroupButtonClick(updateProjectGroupRequest);
            setState(formStates.INPUT);
          } else {
            const createProjectGroupRequest = new CreateProjectGroupRequest(
              values.name,
              values.status,
              taskIdList,
              values.grantNumber,
              values.grantName,
              values.grantAmount
            );

            await onAddProjectGroupButtonClick(createProjectGroupRequest);
            setState(formStates.INPUT);
          }
        }}
      >
        <Form>
          <Flexbox
            alignItems={"center"}
            flexDirection={"column"}
            className={styles.inputSection}
          >
            <Card elevation={1} className={styles.card}>
              <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                <h3>
                  {submitButtonTitle
                    ? "Update Project Group"
                    : "Add Project Group"}
                </h3>
              </Flexbox>
              <Flexbox flex={1} width={"100%"}>
                <Divider className={styles.fullWidthInput} />
              </Flexbox>
              {createInput("name", "Group Name")}
              {createInput("grantNumber", "Grant No")}
              {createInput("grantName", "Grant Name")}
              {createInput("grantAmount", "Grant Amount")}

              {/* {projectGroupData &&
                createSuggestionInput(
                  "status",
                  "Status",
                  <UnitSuggest
                    disabled={projectGroupData.status==="CLOSED"}
                    units={Object.values(ProjectGroupStatusTypeEnum)}
                    inputName={"status"}
                    selectedItem={projectGroupData && projectGroupData.status}
                  />
                )} */}

              <br />
              <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                <h5>Add Default Tasks</h5>
              </Flexbox>

              {!disabled ? (
                <AddDefaultTask
                  taskGroups={taskGroups}
                  tasks={tasks}
                  projectGroupData={projectGroupData}
                />
              ) : (
                <Flexbox
                  style={{ color: "red" }}
                  alignContent={"center"}
                  justifyContent={"center"}
                >
                  Please create new Task List from Task & Pricing
                </Flexbox>
              )}
            </Card>

            <Flexbox flex={1} marginTop={"15px"}>
              <Button
                type="submit"
                className={"bp3-large"}
                rightIcon={state === formStates.INPUT ? "arrow-right" : null}
                disabled={state !== formStates.INPUT}
                loading={state === formStates.SUBMITTING}
                icon={state === formStates.SUCCESS ? "saved" : null}
              >
                {submitButtonTitle ? submitButtonTitle : "CREATE"}
              </Button>
            </Flexbox>
          </Flexbox>
        </Form>
      </Formik>
    </div>
  );
}

export default CreateProjectGroupRequestBuilder;
