// @flow

import React from "react";
import {
  Button,
  Classes,
  Dialog,
  H5,
  Intent,
  FileInput,
  Card,
} from "@blueprintjs/core";
import Flexbox from "flexbox-react";

type Props = {
  isPhotoOption: Boolean,
  onClose: () => void,
  setCameraOpen: () => void,
  handlePhotoUpload: () => void,
};

function PhotoOptionOverlay(props: Props) {
  const { isPhotoOption, onClose, setCameraOpen, handlePhotoUpload } = props;

  return (
    <Dialog
      isOpen={isPhotoOption}
      canOutsideClickClose={false}
      icon="media"
      onClose={() => onClose(false)}
      title="Add Photos"
    >
      <div className={Classes.DIALOG_BODY}>
          <Card>
        <Flexbox
          height={"100%"}
          width={"100%"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <Flexbox alignItems={"center"} justifyContent={"center"}>
          </Flexbox>
          <Flexbox padding={"10px"}>
            <Button
                active
                large
                intent={Intent.PRIMARY}
              text={"Capture Photo"}
              icon={"camera"}
              onClick={() => setCameraOpen(true)}
            />
          </Flexbox>
          <Flexbox padding={"10px"}>
            <FileInput
            fill
            large
            intent={Intent.PRIMARY}
              disabled={false}
              inputProps={{ accept: "image/*" }}
              text="Upload Photo..."
              onInputChange={handlePhotoUpload}
            />
          </Flexbox>
        </Flexbox>
        </Card>
      </div>
     
    </Dialog>
  );
}

export default PhotoOptionOverlay;
