// @flow


import HomeownerApplicationApi from "../../api/HomeownerApplicationApi/HomeownerApplicationApi";

class HomeownerApplicationService {

    homeownerApplicationApi: HomeownerApplicationApi;

    constructor(homeownerApplicationApi: HomeownerApplicationApi) {
        this.homeownerApplicationApi = homeownerApplicationApi;
    }

    async getHomeownerApplicationForProject(projectId: string): Promise<?HomeownerApplication> {
        return await this.homeownerApplicationApi.getHomeownerApplicationForProject(projectId);
    }
    async updateHomeownerApplication(projectId: string,logFileObject:any, homeownerApplication: HomeownerApplication): Promise<HomeownerApplication> {
       return await this.homeownerApplicationApi.updateHomeownerApplicationForProject(projectId,logFileObject, homeownerApplication);
    }
}

export default HomeownerApplicationService;
