// @flow

import React, { useEffect, useContext, useState } from "react";
import type { match } from "react-router-dom";
import { Route, Switch, useLocation } from "react-router-dom";
import NavigationContainer from "../../components/NavigationContainer/NavigationContainer";
import history from "../../history";
import CreateProjectRequestBuilder from "../../components/CreateProjectRequestBuilder/CreateProjectRequestBuilder";
import CreateTaskRequestBuilder from "../../components/CreateTaskRequestBuilder/CreateTaskRequestBuilder";
import Flexbox from "flexbox-react";
import Project, {
  CreateProjectRequest,
  UpdateProjectRequests,
} from "../../models/Project/Project";
import BlocRegistry from "../../blocs/registry";
import type { Subscription } from "rxjs";
import {
  AddVendorEvent,
  CreateProjectEvent,
  CreateProjectGroupEvent,
  ProjectContext,
  ProjectStates,
  RemoveVendorEvent,
  UpdateProjectEvent,
  RemoveProjectEvent,
  UpdateProjectGroupEvent,
  RemoveProjectGroupEvent,
  ExportProjectGroupEvent,
  EditProjectGroupEvent,
  ProjectEventTypesEnum,
} from "../../blocs/projectBloc";
import { UserContext, UserStates } from "../../blocs/userBloc";
import { AuthContext, AuthRoles, AuthStates } from "../../blocs/authBloc";
import ProjectDetailsView from "../ProjectDetailsView/ProjectDetailsView";
import { AppToaster } from "../../components/Toaster/Toaster";
import { Intent, Spinner } from "@blueprintjs/core";
import UserDashboardView from "../UserDashboardView/UserDashboardView";
import AdminDashboardView from "../AdminDashboardView/AdminDashboardView";
import MarketingDashboardView from "../MarketingDashboardView/MarketingDashboardView";
import ManageTasksView from "../ManageTasksView/ManageTasksView";
import {
  CreateTaskEvent,
  CreateTaskGroupEvent,
  TaskContext,
  TaskStates,
  UpdateTaskEvent,
  RemoveTaskFromListEvent,
  GetTaskListEvent,
} from "../../blocs/taskBloc";
import { CreateTaskRequest } from "../../models/Task/Task";
import { CreateTaskGroupRequest } from "../../models/TaskGroup/TaskGroup";
import ManageProjectsView from "../ManageProjectsView/ManageProjectsView";
import type {
  ProjectStatusesTypes,
  ProjectStatesTypes,
  ProjectStates as ProjectStatesString,
} from "../../models/Project/Project";
import { GetAttachmentResponse, AttachmentTypeEnum, AttachmentTypes } from "../../models/Attachment/Attachment";
import { apiAxios } from "../../config/AxiosConfig";
import TeamView from "../TeamView/TeamView";
import ManageProjectGroupsView from "../ManageProjectGroupsView/ManageProjectGroupsView";
import ProjectGroup, {
  CreateProjectGroupRequest,
  UpdateProjectGroupRequest,
} from "../../models/ProjectGroup/ProjectGroup";
import ManageVendorsView from "../ManageVendorsView/ManageVendorsView";
import {
  CreateVendorEvent,
  UpdateVendorEvent,
  RemoveVendorFromListEvent,
  RefreshVendorsEvent,
  VendorBloc as vendorBloc,
  VendorContext,
  VendorStates,
  VendorAttachmentEvent,
  VendorEventTypes
} from "../../blocs/vendorBloc";
import Vendor, {
  CreateVendorAttachment,
  CreateVendorRequest,
  UpdateVendorRequest,
  VendorAttachment,
} from "../../models/Vendor/Vendor";
import CreateVendorRequestBuilder from "../../components/CreateVendorRequestBuilder/CreateVendorRequestBuilder";
import CreateProjectGroupRequestBuilder from "../../components/CreateProjectGroupRequestBuilder/CreateProjectGroupRequestBuilder";
import BrandDetailsView from "../../components/BrandInformation/BrandDetailsView";
import CreateUser from "../../components/UsersManagement/CreateUser";
import UserDetailsView from "../../components/UsersManagement/UserDetailsView";
import AccountantDashboardView from "../AccountManagerDashBoardView/AccountantDashboardView";
import ComplianceDashboardView from '../ComplianceDashboardView/ComplianceDashboardView'
import { RefreshProjectsEvent } from "../../blocs/projectBloc";

type Props = { match: match };

const navigateToCreateProject = () => {
  history.push(`/app/create-project`);
};
const navigateToUpdateProject = (projectId: string = null) => {
  history.push(`/app/update-project/${projectId}`);
};
const navigateToViewProject = (projectId: string) => {
  history.push(`/app/view-project/${projectId}`);
};

const navigateToManageTasks = () => {
  history.push(`/app/manage-tasks`);
};

const navigateToCreateTask = () => {
  history.push(`/app/create-task`);
};

const navigateToManageProjects = () => {
  history.push(`/app/manage-projects?state=OPEN`);
};

const navigateToTeam = () => {
  history.push(`/app/team`);
};

const navigateToProjectGroups = () => {
  history.push(`/app/manage-project-groups?state=[]`);
};

const navigateToVendors = () => {
  history.push(`/app/manage-vendors`);
};

const navigateToAddVendor = () => {
  history.push(`/app/create-vendor`);
};
const navigateToUpdateVendor = (projectId: string) => {
  history.push(`/update-vendor/:vendorId`);
};

const navigateToCreateProjectGroup = () => {
  history.push(`/app/create-project-group`);
};
const navigateToBrandManagement = () => {
  history.push(`/app/brand`);
};
const navigateToUsersManagement = () => {
  history.push(`/app/users`);
};
const navigateToCreateUser = () => {
  history.push(`/app/create-user`);
};
const navigateToUserDetails = (userId: string) => {
  history.push(`/app/view-user/${userId}`);
};
const navigateToUserUpdate = (userId: string) => {
  history.push(`/app/update-user/${userId}`);
};

export default function AppView(props: Props) {
  const { projectBloc, userBloc, authBloc, taskBloc, vendorBloc } =
    useContext(BlocRegistry);
  const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(false);
  const [brand, setBrand] = useState({});
  const [users, setUsers] = useState([]);
  const [projectGroups, setProjectGroups] = useState(null);
  //  const [coverPhotos, setCoverPhotos] = useState(null);
  const [projectManagers, setProjectManagers] = useState(null);
  const [admins, setAdmins] = useState(null);
  const [projectAdmins, setProjectAdmins] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [taskGroups, setTaskGroups] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [vendors, setVendors] = useState(null);
  const [exportProjectGroup, setExportGroup] = useState(null);
  const [editProjectGroupData, setEditProjectGroupData] = useState(null);
  const [loadingBrandInfo, setLoadingBrandInfo] = useState(false);
  const [role, setRole] = useState(false);
  const [getTaskListName, setGetTaskListName] = useState(null);
  const [latestTaskListId, setLatestListId] = useState(null);
  const [refeshTaskList, setRefeshTaskList] = useState(false);
  const [loader, setLoader] = useState(false);
  const [latestTaskList, setLatestTaskList] = useState(null);
  const [isCloneDone, setIsCloneDone] = useState(true);

  const location = useLocation();
  const buildHeaders = () => {
    const accessToken = authBloc.getCurrentToken();
    const auth = "Bearer " + accessToken;
    return { headers: { Authorization: auth } };
  };

  const getBrandInformation = async () => {
    try {
      const tenantId = authBloc.getCurrentTenant();
      const response = await apiAxios.get(
        `/tenants/${tenantId}/brands`,
        buildHeaders()
      );
      setBrand(response.data);
      return response;
    } catch (error) { }
  };

  const getUsersList = async () => {
    try {
      const tenantId = authBloc.getCurrentTenant();
      const response = await apiAxios.get(
        `/tenants/${tenantId}/users`,
        buildHeaders()
      );
      if (response && response.data) {
        setUsers(response.data);
      } else {
        setUsers([]);
      }
    } catch (error) { }
  };

  const uploadImage = async (logoInformation) => {
    const file = logoInformation.payload;
    if (file) {
      const tenantId = authBloc.getCurrentTenant();
      let logo = { fileType: logoInformation.fileType, contentType: "base64" };
      const type = file.split(";")[1].split(",")[0];
      const buffer = Buffer.from(
        file.replace(/^data:[a-zA-Z0\-\/]*\/\w+;base64,/, ""),
        "base64"
      );

      let URLresponse = await apiAxios.post(
        `/tenants/${tenantId}/logo`,
        logo,
        buildHeaders()
      );

      try {
        let rtesult = await apiAxios.put(URLresponse.data, buffer, {
          headers: {
            "Content-Type": logoInformation.fileType,
            "Content-Encoding": type,
          },
        });
      } catch (error) { }
    }
  };

  const onCreateOrUpdateBrand = async (brand, isUpdateRequest, logo) => {
    try {
      const tenantId = authBloc.getCurrentTenant();
      uploadImage(logo);
      setLoadingBrandInfo(true);
      let response = null;
      if (isUpdateRequest) {
        response = await apiAxios.put(
          `/tenants/${tenantId}/brands`,
          brand,
          buildHeaders()
        );
        AppToaster.show({
          message: "Brand Information Updated",
          intent: Intent.SUCCESS,
        });
      } else {
        response = await apiAxios.post(
          `/tenants/${tenantId}/brands`,
          brand,
          buildHeaders()
        );
        AppToaster.show({
          message: "Brand Information Created",
          intent: Intent.SUCCESS,
        });
      }
      setTimeout(() => {
        getBrandInformation();
      }, 2000);
      setLoadingBrandInfo(false);
      setBrand(response);
    } catch (error) {
      setLoadingBrandInfo(false);
      AppToaster.show({
        message: "Something went wrong while updating!",
        intent: Intent.DANGER,
      });
    }
  };

  const deleteUser = async (userId) => {
    try {
      const tenantId = authBloc.getCurrentTenant();
      await apiAxios.delete(
        `/tenants/${tenantId}/users/${userId}`,
        buildHeaders()
      );
      getUsersList();
      AppToaster.show({
        message: "User deleted successfully",
        intent: Intent.SUCCESS,
      });
    } catch (error) {
      AppToaster.show({
        message: "Something went wrong while deleting user!",
        intent: Intent.DANGER,
      });
    }
  };

  const onCreateUser = async (userInfo) => {
    const tenantId = authBloc.getCurrentTenant();
    setLoading(true);
    try {
      let createdUserResponse = await apiAxios.post(
        `/tenants/${tenantId}/users`,
        userInfo,
        buildHeaders()
      );
      window.scrollTo(0, 0);
      if (!createdUserResponse.data || createdUserResponse.data.errorMessage) {
        AppToaster.show({
          message: "User Already Exists",
          intent: Intent.DANGER,
        });
      } else {
        AppToaster.show({
          message: "New User Added",
          intent: Intent.SUCCESS,
        });
      }
      getUsersList();
      setLoading(false);
      navigateToTeam();
    } catch (error) {
      window.scrollTo(0, 0);
      AppToaster.show({
        message: "Something went wrong while creating user!",
        intent: Intent.DANGER,
      });
      setLoading(false);
    }
  };

  const onUpdateUser = async (userInfo, userId) => {
    const tenantId = authBloc.getCurrentTenant();
    setLoading(true);
    try {
      let response = await apiAxios.put(
        `/tenants/${tenantId}/users/${userId}`,
        userInfo,
        buildHeaders()
      );
      window.scrollTo(0, 0);
      if (response.data.errorMessage) {
        AppToaster.show({
          message: "User Email Already Exists",
          intent: Intent.DANGER,
        });
      } else {
        AppToaster.show({
          message: "User Updated",
          intent: Intent.SUCCESS,
        });
      }
      getUsersList();
      setLoading(false);
      navigateToTeam();
    } catch (error) {
      setLoading(false);
    }
  };

  const handleResetProjectCloneFlag = () => {
    console.log("setIsCloneDone");
    setIsCloneDone(true);
  }
  useEffect(() => {
    getUsersList();
    getBrandInformation();
  }, []);

  const projectEventHandler = {
    next(projectContext: ProjectContext) {
      switch (projectContext.state) {
        case ProjectStates.UNINITIALIZED:
        case ProjectStates.INITIALIZING: {
          break;
        }
        case ProjectStates.REFRESHED:
        case ProjectStates.INITIALIZED: {
          setProjects(Array.from(projectContext.projects));
          setProjectGroups(Array.from(projectContext.projectGroups));
          projectContext.selectedProjctGroup &&
            setExportGroup(Array.from(projectContext.selectedProjctGroup));
          projectContext.editedProjectGroup &&
            setEditProjectGroupData(projectContext.editedProjectGroup);
          break;
        }
        case ProjectEventTypesEnum.CLONE_PROJECT_EFFECT: {
          setIsCloneDone(projectContext?.isCloneSuccess);
          if(projectContext?.isCloneSuccess) {
            AppToaster.show({
              message: "Project Cloned Successfully",
              intent: Intent.PRIMARY,
            });
          }
          break;
        }
        default: {
          throw new Error(
            "Unknown project context state: " + projectContext.state
          );
        }
      }
    },
    error(err: Error) {
      throw err;
    },
  };

  useEffect(() => {
    const subscription: Subscription =
      projectBloc.subscribeToProjectContext(projectEventHandler);
    return () => {
      subscription.unsubscribe();
    };
  }, [projectBloc]);

  const taskEventHandler = {
    next(taskContext: TaskContext) {
      switch (taskContext.state) {
        case TaskStates.UNINITIALIZED:
        case TaskStates.REFRESHED:
        case TaskStates.INITIALIZING: {
          break;
        }
        case TaskStates.INITIALIZED: {
          setTaskGroups(Array.from(taskContext.taskGroups?.sort((a, b) => { return a.title.localeCompare(b.title) })));
          setTasks(Array.from(taskContext.tasks));
          break;
        }
        default: {
          throw new Error("Unknown task context state: " + taskContext.state);
        }
      }
    },
    error(err: Error) {
      throw err;
    },
  };
  const getLatestTaskList = async (taskListId) => {
    const tenantId = authBloc.getCurrentTenant();
    setLoader(true)
    const response = await apiAxios.get(`/tenants/${tenantId}/tasklists`, buildHeaders());
    if (response.status == 200) {
      setLoader(false)
      const taskListIdArray = response.data
      setLatestTaskList(taskListIdArray);
      const taskList_Id = taskListId || taskListIdArray[taskListIdArray.length - 1]?.id
      await setLatestListId(taskList_Id)
      setGetTaskListName(taskListIdArray)
      taskListId ? taskBloc.sendEvent(new GetTaskListEvent(taskListId)) : taskBloc.setTaskListEvent(new GetTaskListEvent(taskList_Id))
    }

  }
  useEffect(() => {
    getLatestTaskList()
    const subscription: Subscription =
      taskBloc.subscribeToTaskContext(taskEventHandler);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const getTaskList = async () => {

      const tenantId = authBloc.getCurrentTenant();
      setLoader(true)
      const response = await apiAxios.get(`/tenants/${tenantId}/tasklists`, buildHeaders());
      if (response.status == 200) {
        setLoader(false)
        const taskListIdArray = response.data
        const taskList_Id = taskListIdArray[taskListIdArray.length - 1]?.id
        await setLatestListId(taskList_Id)
        setGetTaskListName(taskListIdArray)
        taskBloc.sendEvent(new GetTaskListEvent(taskList_Id))
      }
    }
    getTaskList()

    return () => {
    };
  }, [refeshTaskList]);

  const userEventHandler = {
    next(userContext: UserContext) {
      switch (userContext.state) {
        case UserStates.UNINITIALIZED:
        case UserStates.INITIALIZING: {
          break;
        }
        case UserStates.INITIALIZED: {
          setProjectManagers(Array.from(userContext.team.projectManagers));
          setAdmins(Array.from(userContext.team.tenantAdmins));
          setProjectAdmins(Array.from(userContext.team.projectAdmins));
          break;
        }
        default: {
          throw new Error(
            "Unknown project context state: " + userContext.state
          );
        }
      }
    },
    error(err: Error) {
      throw err;
    },
  };

  useEffect(() => {
    const subscription: Subscription =
      userBloc.subscribeToUserContext(userEventHandler);
    return () => {
      subscription.unsubscribe();
    };
  }, [userBloc]);

  const authEventHandler = {
    next(authContext: AuthContext) {
      switch (authContext.State) {
        case AuthStates.UNINITIALIZED:
        case AuthStates.AUTHENTICATING: {
          break;
        }
        case AuthStates.UNAUTHENTICATED: {
          history.replace("/login");
          break;
        }
        case AuthStates.FORBIDDEN: {
          history.replace("/forbidden");
          break;
        }
        case AuthStates.AUTHENTICATED: {
          setCurrentUser(authContext.OAuthAccount);
          setCurrentUserRole(authContext.role);
          break;
        }
        default: {
          throw new Error(
            "Unknown project context state: " + authContext.State
          );
        }
      }
    },
    error(err: Error) {
      throw err;
    },
  };

  useEffect(() => {
    const subscription: Subscription =
      authBloc.subscribeToAuthContext(authEventHandler);
    return () => {
      subscription.unsubscribe();
    };
  }, [authBloc]);

  const vendorEventHandler = {
    next(vendorContext: VendorContext) {
      switch (vendorContext.state) {
        case VendorStates.UNINITIALIZED:
        case VendorStates.INITIALIZING: {
          break;
        }
        case VendorStates.REFRESHED:
        case VendorStates.INITIALIZED: {
          setVendors(Array.from(vendorContext.vendors));
          break;
        }
        default: {
          throw new Error(
            "Unknown vendor context state: " + vendorContext.state
          );
        }
      }
    },
    error(err: Error) {
      throw err;
    },
  };

  useEffect(() => {
    const subscription: Subscription =
      vendorBloc.subscribeToVendorContext(vendorEventHandler);
    return () => {
      subscription.unsubscribe();
    };
  }, [vendorBloc]);

  // useEffect(() => {
  //   (async () => {
  //     const tenantId = authBloc.getCurrentTenant();

  //     const buildHeaders = () => {
  //       const accessToken = authBloc.getCurrentToken();
  //       const auth = "Bearer " + accessToken;
  //       return { headers: { Authorization: auth } };
  //     };

  //     if (projects && projects.length > 0) {
  //       const coverPhotoUrlMap = new Map();
  //       for (let project of projects) {
  //         if (!project.coverPhoto) continue;
  //         try {
  //           const coverPhotoResponse = await apiAxios.get(
  //             `tenants/${tenantId}/projects/${project.id}/cover-photo`,
  //             buildHeaders()
  //           );
  //           coverPhotoUrlMap.set(project.id, coverPhotoResponse.data.getUrl);
  //         } catch (err) {
  //           if (err.statusCode === 404) {
  //             console.debug(
  //               "There was not cover photo found for a project. This probably means no cover photo is yet set, but it could indicate a problem with the endpoint"
  //             );
  //           } else {
  //             console.error(err);
  //           }
  //         }
  //       }
  //       setCoverPhotos(coverPhotoUrlMap);
  //     }
  //   })();
  // }, [projects]);

  let filteredProjects = null;
  if (projects) {
    filteredProjects = currentUser
      ? projects.filter((project: Project) => {
        return project.assignedUserId === currentUser.sub || project.assignedProjectAdmin === currentUser.sub;
      })
      : [];
  }

  const getProjectAddressString = (projectId: string) => {
    if (!projects) return "Loading Projects...";
    const project = findProjectById(projectId, projects);
    if (!project) return "Project Not Found";
    const address = project.home.address;
    return `${address.streetAddress}`;
  };

  const getProjectHomeownerString = (projectId: string) => {
    if (!projects) return "Loading Projects...";
    const project = findProjectById(projectId, projects);
    if (!project) return "Project Not Found";
    const homeowner = project.home.homeowner;
    return `${homeowner.firstName} ${homeowner.lastName}`;
  };


  let getDashboardRender = (props: any) => <Spinner />;

  if (currentUserRole) {
    if (currentUserRole === AuthRoles.PROJECT_MANAGER || currentUserRole === AuthRoles.PROJECT_ADMIN)
      getDashboardRender = (props: any) => (
        <UserDashboardView
          onVendorButtuonPress={navigateToVendors}
          // coverPhotos={coverPhotos}
          projects={filteredProjects}
          onNewProjectButtonPress={navigateToCreateProject}
          //  onProjectTileClick={navigateToViewProject}
          onManageProjectsButtonPress={navigateToManageProjects}
          onTeamButtonPress={navigateToTeam}
          onProjectGroupsButtonPress={navigateToProjectGroups}
          onManageTasksButtonPress={navigateToManageTasks}
          {...props}
        />
      );
    else if (currentUserRole === AuthRoles.ADMIN)
      getDashboardRender = (props: any) => (
        <AdminDashboardView
          onVendorButtuonPress={navigateToVendors}
          projects={filteredProjects}
          onNewProjectButtonPress={navigateToCreateProject}
          onManageTasksButtonPress={navigateToManageTasks}
          onManageProjectsButtonPress={navigateToManageProjects}
          onTeamButtonPress={navigateToTeam}
          onProjectGroupsButtonPress={navigateToProjectGroups}
          onBrandButtonPress={navigateToBrandManagement}
          onUsersButtonPress={navigateToUsersManagement}
          {...props}
        />
      );
    else if (currentUserRole === AuthRoles.ACCOUNT_MANAGER)
      getDashboardRender = (props: any) => (
        <AccountantDashboardView
          onVendorButtuonPress={navigateToVendors}
          //  coverPhotos={coverPhotos}
          projects={filteredProjects}
          onNewProjectButtonPress={navigateToCreateProject}
          // onProjectTileClick={navigateToViewProject}
          onManageProjectsButtonPress={navigateToManageProjects}
          onTeamButtonPress={navigateToTeam}
          onProjectGroupsButtonPress={navigateToProjectGroups}
          {...props}
        />
      );
    else if (currentUserRole === AuthRoles.COMPLIANCE_AUDITOR)
      getDashboardRender = (props: any) => (
        <ComplianceDashboardView
          //  coverPhotos={coverPhotos}
          projects={filteredProjects}
          onNewProjectButtonPress={navigateToCreateProject}
          //  onProjectTileClick={navigateToViewProject}
          onManageProjectsButtonPress={navigateToManageProjects}
          onTeamButtonPress={navigateToTeam}
          onProjectGroupsButtonPress={navigateToProjectGroups}
          {...props}
        />
      );
    else if (currentUserRole === AuthRoles.MARKETING_MANAGER)
      getDashboardRender = (props: any) => (
        <MarketingDashboardView
          onVendorButtuonPress={navigateToVendors}
          projects={filteredProjects}
          onNewProjectButtonPress={navigateToCreateProject}
          onManageTasksButtonPress={navigateToManageTasks}
          onManageProjectsButtonPress={navigateToManageProjects}
          onTeamButtonPress={navigateToTeam}
          onProjectGroupsButtonPress={navigateToProjectGroups}
          onBrandButtonPress={navigateToBrandManagement}
          onUsersButtonPress={navigateToUsersManagement}
          {...props}
        />
      );
  }
  const handleProjectMilestoneUpdate = (
    project: Project,
    newStatus: ProjectStatusesTypes,
    dateStamp: any,
    task_list_id: string
  ) => {
    let projObj;
    if (newStatus === "SURVEY_COMPLETED") {
      projObj = {
        status: newStatus,
        dateTimeStamp: dateStamp
      }
    } else {
      projObj = {
        status: newStatus,
        dateTimeStamp: dateStamp
      }
    }
    projectBloc.sendEvent(
      new UpdateProjectEvent(project, projObj, true)
    );
  };

  const handleCloseProjectButtonClick = (
    project: Project,
    newState: ProjectStatesTypes
  ) => {
    projectBloc.sendEvent(
      new UpdateProjectEvent(
        project,
        { projectState: newState, projectGroups: project.projectGroups },
        true
      )
    );
    history.goBack();
  };

  const handleProjectStatusUpdate = (
    project: Project,
    newStatus: ProjectStatusesTypes
  ) => {
    if (project.status === newStatus) return;
    projectBloc.sendEvent(
      new UpdateProjectEvent(
        project,
        { status: newStatus, projectGroups: project.projectGroups },
        true
      )
    );
  };

  const handleSetProjectCoverPhoto = (request: GetAttachmentResponse) => {
    if (!projects) return;
    projectBloc.sendEvent(
      new UpdateProjectEvent(
        projects.find((project) => project.id === request.projectId),
        { coverPhotoId: request.id }
      )
    );
  };

  const handleVendorAdd = (projectId: string, vendorIdToRemove: string) => {
    if (!projects || !vendors) return;
    projectBloc.sendEvent(new AddVendorEvent(projectId, vendorIdToRemove));
  };

  const handleVendorRemove = (projectId: string, vendorIdToRemove: string) => {
    if (!projects || !vendors) return;
    projectBloc.sendEvent(new RemoveVendorEvent(projectId, vendorIdToRemove));
  };

  const handleTaskRemoveFromList = (taskId: string) => {
    if (!tasks) return;
    taskBloc.sendEvent(new RemoveTaskFromListEvent(taskId));
    setTimeout(() => {
      taskBloc.sendEvent(new GetTaskListEvent(latestTaskListId))
    }, 500)
  };
  const handleTaskCreate = async (createTaskReqeust: CreateTaskRequest) => {
    if (!tasks) return;
    taskBloc.sendEvent(new CreateTaskEvent(createTaskReqeust))
    setTimeout(() => {
      taskBloc.sendEvent(new GetTaskListEvent(latestTaskListId))
    }, 500)
  }
  const handleTaskUpdate = async (updateTaskRequest: UpdateTaskRequest) => {
    if (!tasks) return;
    taskBloc.sendEvent(new UpdateTaskEvent(updateTaskRequest));

    setTimeout(() => {
      taskBloc.sendEvent(new GetTaskListEvent(latestTaskListId))
    }, 500)
  }
  const handleVendorRemoveFromList = (vendorId: string) => {
    if (!vendors) return;
    vendorBloc.sendEvent(new RemoveVendorFromListEvent(vendorId));
  };

  const handleProjectUpdate = (
    project: Project,
    request: UpdateProjectRequests
  ) => {
    projectBloc.sendEvent(new UpdateProjectEvent(project, project, true));
  };

  const handleProjectDelete = (projectId: string) => {
    return projectBloc.sendEvent(new RemoveProjectEvent(projectId, true));
  };

  const handleProjectGroupDelete = (projectGroupId: string) => {
    return projectBloc.sendEvent(
      new RemoveProjectGroupEvent(projectGroupId, true)
    );
  };
  const handleProjectGroupExport = async (projectGroupId: string) => {
    return projectBloc.sendEvent(
      new ExportProjectGroupEvent(projectGroupId, true)
    );
  };

  const handleProjectGroupEdit = async (projectGroupId: string) => {
    return projectBloc.sendEvent(new EditProjectGroupEvent(projectGroupId));
  };



  return (
    <Switch>
      <Route
        exact
        path={`${props.match.path}`}
        render={(props) => (
          <NavigationContainer logoUrl={brand.logoUrl} viewTitle={"DASHBOARD"}>
            {getDashboardRender(props)}
          </NavigationContainer>
        )}
      />
      <Route
        path={`${props.match.path}/create-project`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"CREATE PROJECT"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
              justifyContent={"center"}
              padding={"20px"}
            >
              <CreateProjectRequestBuilder
                projectGroups={projectGroups}
                projectManagers={projectManagers}
                projectAdmins={projectAdmins}
                getLatestTaskList={getLatestTaskList}
                latestTaskListId={latestTaskListId}
                latestTaskList={latestTaskList}
                onNewProjectCreate={async (
                  createProjectRequest: CreateProjectRequest
                ) =>
                  projectBloc.sendEvent(
                    new CreateProjectEvent(createProjectRequest)
                  )
                }
                onNewProjectCreated={() => { }}
                project={findProjectById(
                  props.match.params.projectId,
                  projects
                )}
                buttonTitle={"Create"}
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      }/>
      <Route
        path={`${props.match.path}/update-project/:projectId`}
        render={(props) => {
          const queryParams = new URLSearchParams(location.search);
          let clonedTitle = "UPDATE PROJECT";
          if(localStorage.getItem("isCloneInprogress") === 'true') {
            clonedTitle = "UPDATE PROJECT (CLONED)";
          }
          const customTitle = queryParams.get("title") || clonedTitle;
          return (
            <NavigationContainer
              logoUrl={brand.logoUrl}
              viewTitle={customTitle}
              showBackButton={true}
            >
              <Flexbox
                alignItems={"center"}
                flexGrow={1}
                flexDirection={"column"}
                justifyContent={"center"}
                padding={"20px"}
              >
                <CreateProjectRequestBuilder
                  projectGroups={projectGroups}
                  projectAdmins={projectAdmins}
                  getLatestTaskList={getLatestTaskList}
                  latestTaskList={latestTaskList}
                  projectManagers={projectManagers}
                  onNewProjectCreate={handleProjectUpdate}
                  onNewProjectCreated={() => {}}
                  project={findProjectById(
                    props.match.params.projectId,
                    projects
                  )}
                  buttonTitle={"Update"}
                />
              </Flexbox>
            </NavigationContainer>
          );
        }}
      />
      }/>
      <Route
        path={`${props.match.path}/view-project/:projectId`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={getProjectAddressString(props.match.params.projectId)}
            viewSubTitle={getProjectHomeownerString(
              props.match.params.projectId
            )}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
            >
              <ProjectDetailsView
                vendors={vendors}
                onVendorRemove={handleVendorRemove}
                onVendorAdd={handleVendorAdd}
                onProjectMilestoneUpdate={handleProjectMilestoneUpdate}
                onCloseProjectButtonClick={handleCloseProjectButtonClick}
                //   coverPhotos={coverPhotos}
                onSetProjectCoverPhoto={handleSetProjectCoverPhoto}
                onProjectStatusUpdate={handleProjectStatusUpdate}
                taskGroups={taskGroups}
                tasks={tasks}
                currentUserRole={currentUserRole}
                match={props.match}
                project={findProjectById(
                  props.match.params.projectId,
                  projects
                )}
                onAddVendorButtonClick={navigateToAddVendor}
                brand={brand}
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      }/>
      <Route
        path={`${props.match.path}/manage-tasks`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"MANAGE TASKS"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
            >
              <ManageTasksView
                projects={projects}
                projectGroups={projectGroups}
                tasks={tasks}
                taskGroups={taskGroups}
                getTaskListName={getTaskListName}
                currentUserRole={currentUserRole}
                getLatestTaskList={getLatestTaskList}
                latestTaskListId={latestTaskListId}
                refeshTaskList={setRefeshTaskList}
                loader={loader}
                onNewTaskGroupCreate={async (
                  createTaskGroupRequest: CreateTaskGroupRequest
                ) =>
                  taskBloc.sendEvent(
                    new CreateTaskGroupEvent(createTaskGroupRequest)
                  )
                }
                onAddTaskButtonClick={navigateToCreateTask}
                onTaskRemoveFromList={handleTaskRemoveFromList}
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      }/>
      <Route
        path={`${props.match.path}/create-task`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"MANAGE TASKS"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
            >
              <CreateTaskRequestBuilder
                taskGroups={taskGroups}
                refeshTaskList={setRefeshTaskList}

                latestTaskListId={latestTaskListId}
                onNewTaskGroupCreate={async (
                  createTaskGroupRequest: CreateTaskGroupRequest
                ) => {
                  taskBloc.sendEvent(
                    new CreateTaskGroupEvent(createTaskGroupRequest)
                  )

                }
                }
                onNewTaskCreate={handleTaskCreate}
                onNewTaskCreated={() => {
                  AppToaster.show({
                    message: "Task Created",
                    intent: Intent.PRIMARY,
                  });
                  history.goBack();
                }}
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      <Route
        path={`${props.match.path}/update-task/:taskId`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"UPDATE TASK"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
            >
              <CreateTaskRequestBuilder
                taskGroups={taskGroups}
                taskData={findTaskById(props.match.params.taskId, tasks)

                }
                onNewTaskGroupCreate={async (
                  createTaskGroupRequest: CreateTaskGroupRequest
                ) =>
                  taskBloc.sendEvent(
                    new CreateTaskGroupEvent(createTaskGroupRequest)
                  )
                }
                onNewTaskCreate={async (updateTaskRequest: UpdateTaskRequest) => {
                  taskBloc.sendEvent(new CreateTaskEvent(updateTaskRequest))
                  taskBloc.sendEvent(new GetTaskListEvent(latestTaskListId))
                }
                }
                onNewTaskCreated={() => {
                  AppToaster.show({
                    message: "Task Updated",
                    intent: Intent.PRIMARY,
                  });
                  history.goBack();
                }}
                onUpdateTask={handleTaskUpdate}
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      <Route
        path={`${props.match.path}/manage-projects`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"MANAGE PROJECTS"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
            >
              <ManageProjectsView
                projects={projects}
                currentUser={currentUser}
                projectManagers={projectManagers}
                onProjectDetailsButtonClick={navigateToViewProject}
                onAddProjectButtonClick={navigateToCreateProject}
                onEditProjectButtonClick={navigateToUpdateProject}
                onDeleteProjectButtonClick={handleProjectDelete}
                onProjectDeleted={() => { }}
                projectGroups={projectGroups}
                users={users}
                currentUserRole={currentUserRole}
                isCloneDone={isCloneDone}
                resetProjectCloneFlag={handleResetProjectCloneFlag}
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      <Route
        path={`${props.match.path}/manage-project-groups`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"MANAGE PROJECT GROUPS"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
            >
              <ManageProjectGroupsView
                currentUserRole={currentUserRole}
                onAddProjectGroupButtonClick={async (
                  createProjectGroupRequest: CreateProjectGroupRequest
                ) =>
                  projectBloc.sendEvent(
                    new CreateProjectGroupEvent(createProjectGroupRequest)
                  )
                }
                onCreateProjectGroupButtonClick={navigateToCreateProjectGroup}
                projectGroups={projectGroups}
                users={users}
                currentUser={currentUser}
                projectExportData={exportProjectGroup} //remove this after export api integration
                projects={projects} //remove this after export api integration
                onProjectDetailsButtonClick={navigateToViewProject}
                onAddProjectButtonClick={navigateToCreateProject}
                onEditProjectGroupButtonClick={async (
                  updateProjectGroupRequest: UpdateProjectGroupRequest
                ) =>
                  projectBloc.sendEvent(
                    new UpdateProjectGroupEvent(updateProjectGroupRequest)
                  )
                }
                currentUserRole={currentUserRole}
                onDeleteProjectGroupButtonClick={handleProjectGroupDelete}
                onExportProjectGroupButtonClick={handleProjectGroupExport}
                onExportSheetComplete={() => setExportGroup(null)}
                onEditProjectGroupMenuClick={handleProjectGroupEdit}
                onProjectGroupFilterChange={async (
                  selectedFilter
                ) =>
                  projectBloc.sendEvent(
                    new RefreshProjectsEvent(JSON.stringify(selectedFilter), true)
                  )
                }
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      <Route
        path={`${props.match.path}/create-project-group`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"CREATE PROJECT GROUP"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
              padding={"20px"}
            >
              <CreateProjectGroupRequestBuilder
                tasks={tasks}
                taskGroups={taskGroups}
                onAddProjectGroupButtonClick={async (
                  createProjectGroupRequest: CreateProjectGroupRequest
                ) =>
                  projectBloc.sendEvent(
                    new CreateProjectGroupEvent(createProjectGroupRequest)
                  )
                }
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      <Route
        path={`${props.match.path}/update-project-group/:projectGroupId`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"UPDATE PROJECT GROUP"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
              padding={"20px"}
            >
              <CreateProjectGroupRequestBuilder
                tasks={tasks}
                projectGroupData={editProjectGroupData}
                // projectGroupData={findProjectGroupById(
                //   props.match.params.projectGroupId,
                //   projectGroups
                // )}
                taskGroups={taskGroups}
                onUpdateProjectGroupButtonClick={async (
                  updateProjectGroupRequest: UpdateProjectGroupRequest
                ) =>
                  projectBloc.sendEvent(
                    new UpdateProjectGroupEvent(updateProjectGroupRequest)
                  )
                }
                submitButtonTitle={"UPDATE"}
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      <Route
        path={`${props.match.path}/team`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"MANAGE TEAM"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
            >
              <TeamView
                projectManagers={projectManagers}
                currentUserRole={currentUserRole}
                admins={admins}
                users={users}
                deleteUser={deleteUser}
                navigateToUserUpdate={navigateToUserUpdate}
                onUserDetailsButtonClick={navigateToUserDetails}
                onAddUserButtonClick={navigateToCreateUser}
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      <Route
        path={`${props.match.path}/manage-vendors`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"CONTACTS"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
            >
              <ManageVendorsView
                onAddVendorButtonClick={navigateToAddVendor}
                navigateToViewProject={navigateToViewProject}
                onVendorRemoveFromList={handleVendorRemoveFromList}
                vendors={vendors}
                projects={projects}
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      <Route
        path={`${props.match.path}/create-vendor`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"CREATE CONTACT"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
            >
              <CreateVendorRequestBuilder
                onNewVendorCreate={async (
                  createVendorRequest: CreateVendorRequest,
                  requests: CreateVendorAttachment,
                  attachmentType: AttachmentTypes,
                ) =>
                  vendorBloc.sendEvent(
                    new CreateVendorEvent(createVendorRequest, requests, attachmentType)
                  )
                }
                onNewVendorCreated={() => {
                  // AppToaster.show({
                  //   message: "Vendor Created",
                  //   intent: Intent.PRIMARY
                  // });
                  // history.goBack();
                }}
                onVendorAttachment={async (
                  vendorAttachment: VendorAttachment,
                  createVendorRequest: CreateVendorRequest,
                ) => 
                    vendorBloc.sendEvent(
                      new VendorAttachmentEvent(vendorAttachment, createVendorRequest, AttachmentTypeEnum.vendor)
                    )
                  }
                submitButtonName={"Create"}
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      <Route
        path={`${props.match.path}/update-vendor/:vendorId`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"UPDATE VENDOR"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
            >
              <CreateVendorRequestBuilder
                vendorData={findVendorById(
                  props.match.params.vendorId,
                  vendors
                )}
                onUpdateVendor={async (
                  updateVendorRequest: UpdateVendorRequest
                ) => {
                  vendorBloc.sendEvent(
                    new UpdateVendorEvent(updateVendorRequest)
                  );
                }}
                onNewVendorCreated={() => {
                  // AppToaster.show({
                  //   message: "Vendor Updated",
                  //   intent: Intent.PRIMARY
                  // });
                  // history.goBack();
                }}
                onVendorAttachment={async (
                  vendorAttachment: VendorAttachment,
                  updateVendorRequest: UpdateVendorRequest,

                )=> 
                    vendorBloc.sendEvent(
                      new VendorAttachmentEvent(vendorAttachment, updateVendorRequest, AttachmentTypeEnum.vendor)
                    )
                  }
                vendorData={findVendorById(
                  props.match.params.vendorId,
                  vendors
                )}
                submitButtonName={"Update"}
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      <Route
        path={`${props.match.path}/brand`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"Brand"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
            >
              <BrandDetailsView
                brand={brand}
                loadingBrandInfo={loadingBrandInfo}
                onCreateOrUpdateBrand={onCreateOrUpdateBrand}
                currentUserRole={currentUserRole}
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      <Route
        path={`${props.match.path}/create-user`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"Create User"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
            >
              <CreateUser loading={loading}
                project={findProjectById(
                  props.match.params.projectId,
                  projects
                )}
                projectGroups={projectGroups}
                onCreateUser={onCreateUser} />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      <Route
        path={`${props.match.path}/update-user/:userId`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"Update User"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
            >
              <CreateUser
                project={findProjectById(
                  props.match.params.projectId,
                  projects
                )}
                projectGroups={projectGroups}
                buttonTitle={"Update"}
                loading={loading}
                userId={props.match.params.userId}
                onUpdateUser={onUpdateUser}
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
      <Route
        path={`${props.match.path}/view-user/:userId`}
        render={(props) => (
          <NavigationContainer
            logoUrl={brand.logoUrl}
            viewTitle={"User Details"}
            showBackButton={true}
          >
            <Flexbox
              alignItems={"center"}
              flexGrow={1}
              flexDirection={"column"}
            >
              <UserDetailsView
                projects={projects}
                userId={props.match.params.userId}
              />
            </Flexbox>
          </NavigationContainer>
        )}
      />
    </Switch>
  );
}

const findProjectById = (
  projectId: ?string,
  projects: ?Array<Project>
): ?Project => {
  if (!projects || !projectId) return null;
  return projects.find((project) => {
    return project.id === projectId;
  });
};

const findVendorById = (
  vendorId: ?string,
  vendors: ?Array<Vendor>
): ?Vendor => {
  if (!vendors || !vendorId) return null;
  return vendors.find((vendor) => {
    return vendor.id === vendorId;
  });
};

const findTaskById = (taskId: ?string, tasks: ?Array<Task>): ?Project => {
  if (!tasks || !taskId) return null;
  return tasks.find((task) => {
    return task.id === taskId;
  });
};

const findProjectGroupById = (
  projectGroupId: ?string,
  projectgroups: ?Array<ProjectGroup>
): ?Project => {
  if (!projectgroups || !projectGroupId) return null;
  return projectgroups.find((projectgroup) => {
    return projectgroup.id === projectGroupId;
  });
};
