//@Flow
import { BehaviorSubject, PartialObserver, Subject, Subscription } from "rxjs";
import NotesService from "../service/NotesService/NotesService";

export const NotesEventTypesEnum = Object.freeze({
  CREATE_NOTE: Symbol("CREATE NOTE"),
  GET_ALL_NOTES: Symbol("GET ALL NOTES"),
  FETCHED_ALL_NOTES: Symbol("FETCHED ALL NOTES"),
  CREATE_UPDATE_NOTE_SUCCESS: Symbol("CREATE UPDATE NOTE SUCCESS"),
  UPDATE_NOTE: Symbol("UPDATE NOTE"),
  DELETE_NOTE: Symbol("DELETE NOTE"),
  ADD_ATTACHMENTS: Symbol("ADD ATTACHMENTS"),
  ADD_ATTACHMENTS_SUCCESS: Symbol("ADD ATTACHMENTS SUCCESS"),
  EXPORT_NOTES: Symbol("FXPORT NOTES"),
});

export type NotesEventTypes = $Keys<typeof NotesEventTypesEnum>;

export class NotesEvent {
  type: NotesEventTypes;

  constructor(type: NotesEventTypes) {
    this.type = type;
  }
}

export class CreateNoteEvent extends NotesEvent {
  request: any;
  attachments: any;
  attachmentStore: any;

  constructor(requests: any, attachments: any, attachmentStore: any) {
    super(NotesEventTypesEnum.CREATE_NOTE);
    this.request = requests;
    this.attachments = attachments;
    this.attachmentStore = attachmentStore;
  }
}

export class AddAttachmentsEvent extends NotesEvent {
  attachments: any;
  attachmentStore: any;

  constructor(attachments: any, attachmentStore: any) {
    super(NotesEventTypesEnum.ADD_ATTACHMENTS);
    this.attachments = attachments;
    this.attachmentStore = attachmentStore;
  }
}

export class FetchNotesEvent extends NotesEvent {

  request: any;

  constructor(requests: AttachmentTypes) {
    super(NotesEventTypesEnum.GET_ALL_NOTES);
    this.request = requests;
  }
}

export class FetchNotesForProjectOutEvent extends NotesEvent {

  notes: Array<any>;

  constructor(notes: Array<any>) {
    super(NotesEventTypesEnum.FETCHED_ALL_NOTES);
    this.notes = notes;
  }
}

export class CreateNotesOutEvent extends NotesEvent {

  noteDetails: Array<any>;

  constructor(noteDetails: Array<any>) {
    super(NotesEventTypesEnum.CREATE_UPDATE_NOTE_SUCCESS);
    this.noteDetails = noteDetails;
  }
}

export class AddAttachmentOutEvent extends NotesEvent {

  attachmentDetails: Array<any>;

  constructor(attachmentDetails: Array<any>) {
    super(NotesEventTypesEnum.ADD_ATTACHMENTS_SUCCESS);
    this.attachmentDetails = attachmentDetails;
  }
}

export class UpdateNoteEvent extends NotesEvent {
  request: any;
  nodeId: any;
  constructor(requests: any, nodeId: any,) {
    super(NotesEventTypesEnum.UPDATE_NOTE);
    this.nodeId = nodeId;
    this.request = requests;
  }
}

export class DeleteNoteEvent extends NotesEvent {
  nodeId: any;
  constructor(nodeId: any) {
    super(NotesEventTypesEnum.DELETE_NOTE);
    this.nodeId = nodeId;
  }
}

export class ExportNoteEvent extends NotesEvent {

  exportNotes: any;

  constructor(exportNotes: any) {
    super(NotesEventTypesEnum.EXPORT_NOTES);
    this.exportNotes = exportNotes;
  }
}

export class NotesBloc {
  _outNotesStream: Subject = new Subject();

  subscribeToNoteContexts(observer?: PartialObserver<any>) {
    return this._outNotesStream.subscribe(observer);
  }
  _eventController: Subject = new Subject();

  sendEvent(event: NotesEvent) {
    return this._eventController.next(event);
  }

  _notesService: NotesService;

  constructor(
    notesService: NotesService,
  ) {
    this._notesService = notesService;
    this._eventController.subscribe(this.buildEventHandler())
  }


  buildEventHandler = () => {
    return {
      next: async (event: NotesEvent) => {
        switch (event.type) {
          case NotesEventTypesEnum.GET_ALL_NOTES: {
            const notes = await this._notesService.getAllNotes((event: CreateNoteEvent).request);
            this._outNotesStream.next(new FetchNotesForProjectOutEvent(notes));
            break;
          }
          case NotesEventTypesEnum.CREATE_NOTE: {
            const noteDetails = await this._notesService.createNote((event: CreateNoteEvent).request);
            this._outNotesStream.next(new CreateNotesOutEvent(noteDetails));
            break;
          }
          case NotesEventTypesEnum.ADD_ATTACHMENTS: {
            const attachmentDetails = await this._notesService.addAttachments((event: CreateNoteEvent).attachments, (event: CreateNoteEvent).attachmentStore);
      this._outNotesStream.next(new AddAttachmentOutEvent(attachmentDetails));
      break;
    }
          case NotesEventTypesEnum.UPDATE_NOTE: {
      const noteDetails = await this._notesService.updateNote(event?.request, event?.nodeId);
      this._outNotesStream.next(new CreateNotesOutEvent(noteDetails));
      break;
    }
    case NotesEventTypesEnum.EXPORT_NOTES: {
      await this._notesService.exportNotes(event?.exportNotes);
      break;
    }
          case NotesEventTypesEnum.DELETE_NOTE: {
      const noteDetails = await this._notesService.deleteNote(event?.nodeId);
      this._outNotesStream.next(new AddAttachmentOutEvent(noteDetails));
      break;
    }
          default: {
            throw new Error("Unknown note event: " + event.constructor.name);
          }
        }
      },
error(err) {
  throw err;
}
    };
  };

  dispose() {
    this._eventController.complete();
  }
}
