//@flow

import styles from "./ProjectFilesGrid.module.css";
import React, { useState, useEffect } from "react";
import Flexbox from "flexbox-react";
import { Responsive, WidthProvider } from "react-grid-layout";
import {
  Button,
  Card,
  InputGroup,
  Intent,
  Overlay,
  Popover,
  Spinner,
  TextArea,
  Tag,
  Checkbox,
  MenuItem,
  Menu,
  Classes
} from "@blueprintjs/core";
import {
  AttachmentTypeEnum,
  CreateAttachmentRequest,
  GetAttachmentResponse
} from "../../models/Attachment/Attachment";
import {
  faPlus,
  faCamera,
  faFile,
  faFileExcel,
  faFileWord,
  faFilePdf,
  faLink
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";
import Dropzone from "react-dropzone";
import axios from "axios";
import mime from "mime-types";
import { Base64 } from "js-base64";
import { LinkPreview } from "@dhaiwat10/react-link-preview";
import {
  DeleteAttachmentForProjectEvent,
  FetchAttachmentsForProjectEvent,
  DownloadAllAttachmentsEvents
} from "../../blocs/projectAttachmentBloc";
import Project from "../../models/Project/Project";
import FullscreenCamera from "../FullscreenCamera/FullscreenCamera";
import { SelectionModes, Table } from "@blueprintjs/table";
import { TextSortableColumn } from "../Table/TextSortableColumn/TextSortableColumn";
import { apiAxios } from "../../config/AxiosConfig";
import { AuthContext, AuthRoles, AuthStates } from "../../blocs/authBloc";
import { useContext } from "react";
import BlocRegistry from "../../blocs/registry";

const ResponsiveGridLayout = WidthProvider(Responsive);

type Props = {
  project: Project,
  files: ?Array<GetAttachmentResponse>,
  onFileTileClick: () => void,
  onAddFiles: (requests: Array<CreateAttachmentRequest>) => void,
  onAttachmentDelete: (request: DeleteAttachmentForProjectEvent) => void,
  onAllAttachmentDownload: (request: DownloadAllAttachments) => void
};

const generateLayout = (buttons: Array<any>) => {
  return {
    lg: buttons.map((button, index) => {
      return {
        x: index % 5,
        y: Math.floor(index / 5),
        w: 1,
        h: 1,
        i: index.toString(),
        static: true
      };
    }),
    md: buttons.map((button, index) => {
      return {
        x: index % 4,
        y: Math.floor(index / 4),
        w: 1,
        h: 1,
        i: index.toString(),
        static: true
      };
    }),
    sm: buttons.map((button, index) => {
      return {
        x: index % 2,
        y: Math.floor(index / 2),
        w: 1,
        h: 1,
        i: index.toString(),
        static: true
      };
    }),
    xs: buttons.map((button, index) => {
      return {
        x: index % 1,
        y: Math.floor(index / 1),
        w: 1,
        h: 1,
        i: index.toString(),
        static: true
      };
    }),
    xss: buttons.map((button, index) => {
      return {
        x: 0,
        y: index,
        w: 1,
        h: 1,
        i: index.toString(),
        static: true
      };
    })
  };
};

export default function ProjectFilesGrid(props: Props) {
  const {
    files,
    onAddFiles,
    onFileTileClick,
    project,
    onAttachmentDelete,
    onAddPhoto,
    currentUserRole,
    onAllAttachmentDownload
  } = props;
  const { attachmentBloc } = useContext(BlocRegistry);
  const [filePickerOpen, setFilePickerOpen] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [description, setDescription] = useState("");

  const handleAcceptFile = acceptedFiles => {
    setFilePickerOpen(false);
    setAcceptedFiles(acceptedFiles);
  };

  const [cameraOpen, setCameraOpen] = useState(false);
  const [dataUri, setDataUri] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [linkError, setLinkError] = useState(false);
  const [erCheck, setErCheck] = useState(false);
  const [erTitle, setErTitle] = useState("");
  const [columns, setColumns] = useState([
    new TextSortableColumn("Images/Files", 0, "getUrl",undefined,{
      isSorting:false
      //return 0;
    }), 
    new TextSortableColumn("Title", 1, "title", undefined,{
      isSorting:false
    }),
    new TextSortableColumn("Description", 2, "description",undefined,{
      isSorting:false}),
    new TextSortableColumn("", 3, "controls", undefined,{
      isSorting:false
    })
  ]);
  const downloadAllPhotos = () => {
    attachmentBloc.sendEvent(new DownloadAllAttachmentsEvents(props.project?.id, false));
  }
  const handleAcceptImage = dataUri => {
    setCameraOpen(false);
    setDataUri(dataUri);
  };
  const createAddImageTile = (
    index: number,
    onAddPhoto: (request: CreateAttachmentRequest) => void
  ) => {
    return (
      
AuthRoles.COMPLIANCE_AUDITOR !== props.currentUserRole &&
<Flexbox
        key={index}
        height={"100%"}
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Button onClick={downloadAllPhotos} disabled={files&&files.length?false:true} style={{ marginTop: "1%", marginRight: "1%" }} intent={Intent.PRIMARY}>Download All</Button>
        <Button
          onClick={() => setCameraOpen(true)}
          style={{ marginTop: "1%" }}
          text={"Capture Image"}
          icon={"camera"}
          intent={Intent.PRIMARY}
        />
        <FullscreenCamera
          onClose={() => {
            setCameraOpen(false);
          }}
          open={cameraOpen}
          onAcceptImage={handleAcceptImage}
        />
        <Flexbox
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"coloum"}
          marginTop={"1%"}
          marginLeft={"10px"}
        >
           
          <Flexbox>
            <InputGroup
              style={{ width: "350px" }}
              value={link}
              placeholder={"Link Attachment"}
              onChange={event => {
                setLink(event.target.value);
                setLinkError(false);
              }}
            />
          </Flexbox>
          <Flexbox>
            <Button
              onClick={() => {
                var expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
                var regex = new RegExp(expression);
                if (link.match(regex)) {
                  setDataUri(link);
                } else {
                  setDataUri("");
                  //setLink('')
                  setLinkError(true);
                }
              }}
              style={{ marginTop: "1%", marginLeft: "10px" }}
              icon={"add"}
              // intent = {Intent.PRIMARY}
            />
          </Flexbox>
           
        </Flexbox>

        <Overlay
          isOpen={dataUri}
          onClose={() => {
            setDataUri("");
          }}
        >
          <Flexbox
            height={"90%"}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            className={styles.uploadImagePreviewContainer}
          >
            <Flexbox alignItems={"center"} justifyContent={"center"}>
              {link ? (
                <Card>
                  <LinkPreview url={dataUri} width="300px" />
                </Card>
              ) : (
                <img className={styles.uploadImagePreview} src={dataUri} />
              )}
            </Flexbox>
            <Flexbox
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              marginTop={"1%"}
            >
              <Tag intent={Intent.WARNING}>Don't add address in title</Tag>
            </Flexbox>
            <Flexbox padding={"10px"}>
              {link ? (
                <InputGroup value={link} />
              ) : (
                <InputGroup
                  value={title}
                  placeholder={"Title"}
                  onChange={event => {
                    setTitle(event.target.value);
                    setTitleError(false);
                  }}
                />
              )}
            </Flexbox>

            {titleError && (
              <Flexbox
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
                marginTop={"1%"}
              >
                <Tag intent={Intent.DANGER}>Title Required!</Tag>
              </Flexbox>
            )}

            <Flexbox padding={"10px"}>
              <TextArea
                value={description}
                placeholder={"Description"}
                onChange={event => {
                  setDescription(event.target.value);
                }}
              />
            </Flexbox>
            <Flexbox padding={"10px"}>
              <Button
                text={"Upload"}
                rightIcon={"upload"}
                large
                onClick={() => {
                  if (link) {
                    const request = new CreateAttachmentRequest(
                      project.id,
                      link,
                      description,
                      "link",
                      "link"
                    );
                    onAddPhoto(request);
                    setDataUri("");
                    setLink("");
                    setTitle("");
                    setDescription("");
                  } else if (title.trim()) {
                    const newTitle = `C_${title}.jpg`;
                    const request = new CreateAttachmentRequest(
                      project.id,
                      newTitle,
                      description,
                      dataUri.substring(
                        dataUri.indexOf(":") + 1,
                        dataUri.indexOf(";")
                      ),
                      dataUri.substring(
                        dataUri.indexOf(";") + 1,
                        dataUri.indexOf(",")
                      ),
                      dataUri
                    );
                    onAddPhoto(request);
                    setDataUri("");
                    setTitle("");
                    setDescription("");
                  } else {
                    setTitleError(true);
                  }
                }}
              />
            </Flexbox>
          </Flexbox>
        </Overlay>
      </Flexbox>
    );
  };

  const createAddFileTile = (
    index: number,
    onAddFiles: (requests: Array<CreateAttachmentRequest>) => void
  ) => {
    const files = acceptedFiles.map(file => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));

    return (
      <Flexbox key={index} height={"100%"} width={"100%"}>
        <Flexbox
          key={index}
          height={"100%"}
          width={"100%"}
          margin={"0 auto"}
          flexDirection={"column"}
        >
           {
            AuthRoles.COMPLIANCE_AUDITOR !== props.currentUserRole &&

          <Dropzone onDrop={acceptedFiles => setAcceptedFiles(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <Card
                {...getRootProps()}
                className={styles.attachmentTile}
                interactive
              >
                <input {...getInputProps()} />
                {files.length > 0 ? (
                  <Flexbox
                    height={"100%"}
                    minHeight={"300px"}
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    className={styles.thumbnailContainer}
                  >
                    <h4>Files</h4>
                    <div className={styles.fileList}>{files}</div>
                  </Flexbox>
                ) : (
                  <Flexbox
                    height={"100%"}
                    minHeight={"300px"}
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    className={styles.thumbnailContainer}
                  >
                    <Flexbox flex={3} />
                    <Flexbox
                      flex={2}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        color="#ffffff"
                        transform="grow-30"
                      />
                    </Flexbox>
                    <Flexbox flexDirection={"column"} flex={3} height={"100%"}>
                      <h6>
                        Drag and drop a file here, or click to select a file.
                      </h6>
                    </Flexbox>
                  </Flexbox>
                )}
              </Card>
            )}
          </Dropzone>
           }
          {files.length > 0 ? (
            <Flexbox
              height={"100%"}
              width={"100%"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              className={styles.uploadImagePreviewContainer}
              justifyContent={"flex-end"}
            >
              <Button
                text={"Cancel"}
                rightIcon={"cross"}
                onClick={async () => {
                  setAcceptedFiles([]);
                }}
              />
              <Flexbox
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
                marginTop={"1%"}
              >
                <Tag intent={Intent.WARNING}>
                  Note : Leave Title blank to have same name (Don't add address
                  in title)
                </Tag>
              </Flexbox>

              <Flexbox padding={"10px"}>
                <Checkbox
                  labelElement={
                    <strong style={{ color: "black", fontSize: "small" }}>
                      Include in ER
                    </strong>
                  }
                  checked={erCheck}
                  onChange={() => {
                    setErCheck(!erCheck);
                  }}
                />
              </Flexbox>

              {false && (
                <>
                  <Tag intent={Intent.SUCCESS}>
                    Optional: If no title is selected, included in Activity Area
                    Pictures
                  </Tag>
                  <Flexbox padding={"10px"}>
                    <Flexbox
                      flex={1}
                      flexDirection={"column"}
                      width={"100%"}
                      marginTop={"10px"}
                    >
                      <Flexbox flex={1}>
                        <label
                          htmlFor={"erSelect"}
                          style={{ color: "black", fontSize: "17px" }}
                        >
                          ER Title
                        </label>
                      </Flexbox>
                      <Flexbox flex={1}>
                        <div class="bp3-select .modifier">
                          <select
                            value={erTitle}
                            onChange={event => {
                              setErTitle(event.target.value);
                              setTitle(event.target.value);
                            }}
                          >
                            <option selected>Select</option>
                            <option value="Front of House">
                              Front of House
                            </option>
                            <option value="Side B (Left) of House">
                              Side B (Left) of House
                            </option>
                            <option value="Side C (Back) of House">
                              Side C (Back) of House
                            </option>
                            <option value="Side D (Right) of House">
                              Side D (Right) of House
                            </option>
                            <option value="House Across Street">
                              House Across Street
                            </option>
                            <option value="Street View of Homes #1">
                              Street View of Homes #1
                            </option>
                            <option value="Street View of Homes #2">
                              Street View of Homes #2
                            </option>
                          </select>
                        </div>
                      </Flexbox>
                    </Flexbox>
                  </Flexbox>
                </>
              )}

              <Flexbox padding={"10px"}>
                <InputGroup
                  value={title}
                  placeholder={"Title"}
                  onChange={event => {
                    setTitle(event.target.value);
                  }}
                />
              </Flexbox>
              <Flexbox padding={"10px"}>
                <TextArea
                  value={description}
                  placeholder={"Description"}
                  onChange={event => {
                    setDescription(event.target.value);
                  }}
                />
              </Flexbox>

              <Button
                text={"Upload"}
                rightIcon={"upload"}
                onClick={async () => {
                  const requests = [];

                  for (let index = 0; index < acceptedFiles.length; index++) {
                    const file = acceptedFiles[index];

                    const fileString = await new Promise(resolve => {
                      const reader = new FileReader();

                      reader.onload = () => {
                        resolve(reader.result);
                      };

                      reader.onerror = err => {
                        throw err;
                      };

                      reader.readAsDataURL(file);
                    });

                    const fileName =
                      title === ""
                        ? file.name
                        : `C_${title}.${file.name.slice(
                            ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
                          )}`;

                    requests.push(
                      new CreateAttachmentRequest(
                        project.id,
                        fileName,
                        description,
                        fileString.split(":")[1].split(";")[0],
                        fileString.split(";")[1].split(",")[0],
                        fileString
                      )
                    );
                  }
                  onAddFiles(requests);
                  setAcceptedFiles([]);
                  setTitle("");
                  setDescription("");
                }}
              />
            </Flexbox>
          ) : null}
        </Flexbox>
      </Flexbox>
    );
  };

  let fileTiles = [];
  let list = <Spinner />;

  if (files) {
    fileTiles = files.map((fileAttachment, index) => {
      return (
        <Flexbox key={index} height={"100%"} width={"100%"}>
          <FileTile
            index={index + 1}
            project={project}
            currentUserRole={currentUserRole}
            attachmentResponse={fileAttachment}
            onAttachmentDelete={onAttachmentDelete}
            onClick={onFileTileClick}
          />
        </Flexbox>
      );
    });

    const layouts = generateLayout(fileTiles);
    list = (
      <ResponsiveGridLayout
        layouts={layouts}
        rowHeight={50}
        margin={[10, 10]}
        breakpoints={{ lg: 1500, md: 1280, sm: 738, xs: 480, xxs: 0 }}
        cols={{ lg: 5, md: 4, sm: 2, xs: 1, xxs: 1 }}
      >
        {fileTiles}
      </ResponsiveGridLayout>
    );
  }
  const titleRenderer = (rowIndex: number) => {
    if (props.files[rowIndex]) {
      if (props.files[rowIndex].fileType == "link") {
        return (
          // <Link to={props.files[rowIndex].title} target="_blank" >
          //   {props.files[rowIndex].title}
          // </Link>
          <a href={
            props.files[rowIndex].title.includes("http")?
            props.files[rowIndex].title
            :
            `https://${props.files[rowIndex].title}`
          }  
          target="_blank">
            {props.files[rowIndex].title}
          </a>
        );
      } else {
        return `${props.files[rowIndex].title}`;
      }
    } else {
      return "Not Found";
    }
  };

  const imageRenderer = (rowIndex: number) => {
    if (props.files[rowIndex]) {
      return (
        <ImageAttachment
          fileType={props.files[rowIndex].fileType}
          getUrl={props.files[rowIndex].getUrl}
          isLocked={props.files[rowIndex].isLocked}
          keyRowIndex={rowIndex}
        />
      );
    } else {
      return "Not Found";
    }
  };

  const descriptionRenderer = (rowIndex: number) => {
    if (props.files[rowIndex]) {
      return props.files[rowIndex].description;
    } else {
      return "Not Found";
    }
  };
  const controlsRenderer = (rowIndex: number) => {
    return (
      <FileTile
        index={rowIndex}
        currentUserRole={currentUserRole}
        project={project}
        attachmentResponse={props.files[rowIndex]}
        onAttachmentDelete={onAttachmentDelete}
        onClick={onFileTileClick}
      />
    );
  };
  const cellRenderers = {
    title: titleRenderer,
    description: descriptionRenderer,
    controls: controlsRenderer,
    getUrl: imageRenderer
  };
  const getCellData = (rowIndex: number, columnIndex: number) => {
    return cellRenderers[columns[columnIndex].modelField](rowIndex);
  };
  const renderedColumns = columns.map(column => {
    return column.getColumn(getCellData, null);
  });

  return (
    <Flexbox
      flexGrow={1}
      flexDirection={"column"}
      paddingLeft={"10px"}
      paddingRight={"10px"}
      width={"100%"}
    >
      <Flexbox
        justifyContent={"center"}
        paddingTop={"10px"}
        paddingLeft={"10px"}
        width={"100%"}
      >
        <h3 className={`bp3-heading ${styles.myAttachmentsTitle}`}>Files</h3>
      </Flexbox>
      <Flexbox>{createAddFileTile(0, onAddFiles)}</Flexbox>
      <Flexbox>{createAddImageTile(0, onAddPhoto)}</Flexbox>
      {linkError && (
        <Flexbox
          justifyContent={"center"}
          alignContent={"center"}
          className="bp3-text-small mt-2"
          style={{ color: "red" }}
        >
          please enter valid link
        </Flexbox>
      )}
      <Flexbox justifyContent={"center"} alignContent={"center"} width={"100%"}>
        <div className={`bp3-text-large ${styles.listContainer}`}>
          <div style={{ margin: 16 }}>
            <Table
              defaultRowHeight={130}
              selectionModes={SelectionModes.COLUMNS_AND_CELLS}
              numRows={files ? files.length : 0}
            >
              {renderedColumns}
            </Table>
          </div>
        </div>
      </Flexbox>
    </Flexbox>
  );
}

const ImageAttachment = props => {
  const [image, setImage] = useState("");
  const [encodedFileData, setWncodedFileData] = useState("");
  useEffect(() => {
    const getImageData = async () => {
      if (props.getUrl) {
        const encodedString = (await axios.get(props.getUrl)).data;
        setWncodedFileData(encodedString);
        if (props.fileType == "image/jpeg") {
          const newImage = new Image();

          newImage.onload = () => {
            setImage(newImage);
          };
          newImage.onerror = () => {
            newImage.src = "/errorMessage.png";
          };
          newImage.src = encodedString;
        }
      }
    };
    getImageData();
    
  },[props.getUrl,props.keyRowIndex]);
 
  if (props.fileType == "image/jpeg") {
    return !props.isLocked?
        <img src={image.src} />
    :
        <FontAwesomeIcon
        style={{ height: 50, marginTop: 2 }}
        icon={faFile}
        color={"black"}
        transform="grow-50"
      />;
  } 
  else if (props.fileType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
    return (
      <FontAwesomeIcon
        style={{ height: 50, marginTop: 2 }}
        icon={faFileExcel}
        color={"black"}
        transform="grow-50"
      />
    );
  } else if (props.fileType == "application/msword") {
    return (
      <FontAwesomeIcon
        icon={faFileWord}
        style={{ height: 50, marginTop: 2 }}
        color={"black"}
        transform="grow-50"
      />
    );
  } else if (props.fileType == "application/pdf") {
    return (
      <FontAwesomeIcon
        style={{ height: 50, marginTop: 2 }}
        icon={faFilePdf}
        color={"black"}
        transform="grow-50"
      />
    );
  } else if (props.fileType == "link") {
    return (
      <FontAwesomeIcon
        style={{ height: 50, marginTop: 2 }}
        icon={faLink}
        color={"black"}
        transform="grow-50"
      />
    );
  } else {
    return (
      <FontAwesomeIcon
        style={{ height: 50, marginTop: 2 }}
        icon={faFile}
        color={"black"}
        transform="grow-50"
      />
    );
  }
};

type FileTileProps = {
  project: Project,
  attachmentResponse: GetAttachmentResponse,
  currentUserRole: any,
  index: number,
  onAttachmentDelete: (request: DeleteAttachmentForProjectEvent) => void
};

function FileTile(props: FileTileProps) {
  const { authBloc } = useContext(BlocRegistry);
  const { attachmentResponse, index, project, currentUserRole } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [image, setImage] = useState(null);
  const [loading, setLoading ] = useState(false);

  const [showPreview, setPreview] = useState(false);
  useEffect(() => {
    const getImageData = async () => {
      if (attachmentResponse.getUrl) {
        const encodedString = (await axios.get(attachmentResponse.getUrl)).data;
        if (encodedString.slice(0, 10) === "data:image") {
          setPreview(true);
        } else {
          setPreview(false);
        }
        const newImage = new Image();
        newImage.onload = () => {
          setImage(newImage);
        };
        newImage.onerror = () => {
          newImage.src = "/errorMessage.png";
        };
        newImage.src = encodedString;
      }
    };
    if (isOpen) {
      getImageData();
    }
  }, [attachmentResponse, isOpen]);

  const close = () => {
    setIsOpen(false);
    setImage(null);
    setPreview(false);
  };

  const open = () => {
    setIsOpen(true);
  };

  const buildHeaders = () => {
    const accessToken = authBloc.getCurrentToken();
    const auth = "Bearer " + accessToken;
    return { headers: { Authorization: auth } };
  };
  const updateTheAttachmentLink = async () => {
    const isLocked = attachmentResponse.isLocked;
    const response = await apiAxios.patch(
      `/tenants/${attachmentResponse.tenantId}/projects/${attachmentResponse.projectId}/${attachmentResponse.attachmentType}/${attachmentResponse.id}`,
      { isLocked: isLocked ? false : true, id: attachmentResponse.id },
      buildHeaders()
    );
    props.onAttachmentDelete(
      new FetchAttachmentsForProjectEvent(
        attachmentResponse.projectId,
        AttachmentTypeEnum.file
      )
    );
    setLoading(false)
  };

  const displayLockIcon = () => {
    if (
      currentUserRole == AuthRoles.PROJECT_ADMIN ||
      currentUserRole == AuthRoles.ADMIN ||
      currentUserRole == AuthRoles.COMPLIANCE_AUDITOR
    ) {
      return true;
    }
    return false;
  };

  const hideView = () => {
    if (
      currentUserRole == AuthRoles.PROJECT_MANAGER ||
      currentUserRole == AuthRoles.ACCOUNT_MANAGER
    ) {
      return true;
    }
    return false;
  };
  return (
    <Flexbox key={index} height={"100%"} width={"100%"}>
      <Flexbox margin={"0 auto"} width={"100%"}>
        <Menu className={Classes.ELEVATION_2}>
          {(!attachmentResponse.isLocked && hideView()) || displayLockIcon() ? (
            <MenuItem
              onClick={() => {
                if (attachmentResponse.title) {
                  open();
                }
              }}
              text="View"
              icon={"eye-open"}
              intent={Intent.PRIMARY}
            />
          ) : (
            <MenuItem
              disabled
              text="Locked"
              icon="lock"
              intent={Intent.PRIMARY}
            />
          )}
          {displayLockIcon() && (
            <MenuItem
              onClick={() => {
                setLoading(true)
                updateTheAttachmentLink();
              }}
              text={attachmentResponse.isLocked ? "Locked" : "Not Locked"}
              icon={attachmentResponse.isLocked ? "lock" : "unlock"}
              intent={Intent.PRIMARY}
              loading={loading}
            />
              
          )}
          {
            loading ? 
            <MenuItem
           
            text={attachmentResponse.isLocked ? "UNLOCKING..." : "LOCKING..."}
            intent={Intent.SUCCESS}
          /> : <></>
          }
        </Menu>
        <Overlay
          isOpen={isOpen && attachmentResponse.title}
          onClose={close}
          canOutsideClickClose={true}
        >
          <Flexbox
            height={"100vh"}
            width={"100vw"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            className={styles.fullscreenFile}
          >
            <div className={styles.closeButton}>
              <Button icon={"cross"} onClick={close} />
            </div>

            {attachmentResponse.fileType == "link" ? (
              <Flexbox
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
                height={"-webkit-fill-available"}
                marginTop={"10%"}
                width={"100%"}
              >
                <Card>
                  <LinkPreview url= {attachmentResponse.title.includes("http")?
                        attachmentResponse.title
                        :
                        `https://${attachmentResponse.title}`} width="350px" />
                </Card>
              </Flexbox>
            ) : (
              showPreview && (
                <Flexbox
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={"100%"}
                  height={"130%"}
                  flex={3}
                >
                  {image ? (
                    <Flexbox
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                      height={"-webkit-fill-available"}
                      marginTop={"10%"}
                      width={"100%"}
                    >
                      <img src={image.src} />
                    </Flexbox>
                  ) : (
                    <Spinner />
                  )}
                </Flexbox>
              )
            )}

            <Flexbox flexDirection={"column"}>
              <Flexbox padding={"10px"} flexDirection={"column"}>
                {attachmentResponse.fileType !== "link" ? (
                  <Card>
                    <div>
                      {attachmentResponse.title.replace("C_", " ").trim()}
                    </div>
                  </Card>
                ):(
                  <Card>
                  <a href={
                        attachmentResponse.title.includes("http")?
                        attachmentResponse.title
                        :
                        `https://${attachmentResponse.title}`
                      }   
                      target="_blank">
                    {attachmentResponse.title.replace("C_", " ").trim()}
                  </a>
                  </Card>
                )
              
              }
              </Flexbox>
              <Flexbox padding={"10px"} flexDirection={"column"}>
                {attachmentResponse.description && (
                  <Card>
                    <div>{attachmentResponse.description}</div>
                  </Card>
                )}
              </Flexbox>
            </Flexbox>
            <Flexbox>
              <Flexbox padding={"10px"}>
              {  AuthRoles.COMPLIANCE_AUDITOR !== props.currentUserRole && 
                  <Flexbox>
                <Popover
                  isOpen={isDeleteOpen}
                  onClose={() => {
                    setIsDeleteOpen(false);
                  }}
                >
              
                  <Button
                    icon={"delete"}
                    text={"Delete"}
                    onClick={() => {
                      setIsDeleteOpen(true);
                    }}
                  />
                  <Flexbox justifyContent={"center"}>
                    <Flexbox margin={"5px"}>
                      <Button
                        icon={"cross"}
                        text={"Cancel"}
                        minimal
                        onClick={() => {
                          setIsDeleteOpen(false);
                        }}
                      />
                    </Flexbox>
                    <Flexbox margin={"5px"}>
                      <Button
                        icon={"confirm"}
                        text={"Confirm"}
                        intent={Intent.WARNING}
                        onClick={() => {
                          if (!attachmentResponse) return;
                          props.onAttachmentDelete(
                            new DeleteAttachmentForProjectEvent(
                              attachmentResponse.projectId,
                              AttachmentTypeEnum.file,
                              attachmentResponse.id
                            )
                          );
                          setIsDeleteOpen(false);
                          close();
                        }}
                      />
                      
                    </Flexbox>
                  </Flexbox>
                
                </Popover>
                </Flexbox>
                  }
              </Flexbox>
              {
                attachmentResponse.fileType !== "link" &&
                <Flexbox padding={"10px"}>
                <Button
                  icon={"download"}
                  text={"Download"}
                  onClick={async () => {
                    if (!attachmentResponse.title) return;

                    const encodedString = (
                      await axios.get(attachmentResponse.getUrl)
                    ).data;
                    const base = encodedString.split(",")[1];
                    const type = encodedString.split(";")[0].split("/")[1];
                    const fileType = attachmentResponse.fileType;
                    const extension = mime.extension(fileType);
                    const byteString = atob(base);
                    const ab = new ArrayBuffer(byteString.length);
                    const ia = new Uint8Array(ab);

                    for (let i = 0; i < byteString.length; i++) {
                      ia[i] = byteString.charCodeAt(i);
                    }

                    let filename;

                    let downloadExtension = attachmentResponse.title.slice(
                      ((attachmentResponse.title.lastIndexOf(".") - 1) >>> 0) +
                        2
                    );

                    let projectName = `_${project.home.address.streetAddress}.${downloadExtension}`;

                    let downloadName = attachmentResponse.title
                      .replace("C_", " ")
                      .trim()
                      .replace(`.${downloadExtension}`, projectName);

                    if (attachmentResponse.title.includes(`C_`)) {
                      filename = attachmentResponse.title
                        ? downloadName
                        : `${attachmentResponse.id}.${extension}`;
                    } else {
                      filename = attachmentResponse.title
                        ? attachmentResponse.title
                        : `${attachmentResponse.id}.${extension}`;
                    }

                    const file = new File([ab], filename, { type: type });

                    FileSaver.saveAs(file);
                  }}
                />
              </Flexbox>
              }

            </Flexbox>
          </Flexbox>
        </Overlay>
      </Flexbox>
    </Flexbox>
  );
}