// @flow

import React from 'react';
import type {Node} from 'react';
import {Responsive, WidthProvider} from 'react-grid-layout';
import styles from "./DashboardView.module.css"
import Flexbox from "flexbox-react";
import {Divider} from "@blueprintjs/core";


const ResponsiveGridLayout = WidthProvider(Responsive);

type Props = {
    buttons: Array<Node>,
    children?: Node,
}

const generateLayout = (buttons: Array<Node>) => {
    return {
        lg: buttons.map((button, index) => {
            return {
                x: index % 4,
                y: Math.floor(index / 4),
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        }),
        xs: buttons.map((button, index) => {
            return {
                x: index % 2,
                y: Math.floor(index / 2),
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        }),
        xss: buttons.map((button, index) => {
            return {
                x: 0,
                y: index,
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        })
    };
};

export default function DashboardView(props: Props) {
    const {buttons, children} = props;

    return (
        <div className={styles.dashboardView}>
            <Flexbox flexGrow={1} flexDirection={'column'} margin={'0px'}>
                <ResponsiveGridLayout layouts={generateLayout(buttons)}
                                      rowHeight={125}
                                      margin={[0, 0]}
                                      breakpoints={{lg: 1280, xs: 300, xxs: 0}}
                                      cols={{lg: 4, xs: 2, xxs: 1}}>
                    {buttons}
                </ResponsiveGridLayout>
            </Flexbox>
            <Divider className={`${styles.border}`}/>
            {children}
        </div>
    )
}
