//@Flow
import {PartialObserver, Subject} from 'rxjs'
// import HomeownerApplication, {CreateHomeownerApplicationRequest, UpdateHomeownerApplicationRequest} from "../models/HomeownerApplication/HomeownerApplication";
import HomeownerApplicationService from "../service/HomeownerApplicationService/HomeownerApplicationService";
import React from "react";

export const HomeownerApplicationEventTypesEnum = Object.freeze({
    UPDATE_HOMEOWNER_APPLICATION: Symbol("UPDATE HOMEOWNER_APPLICATION"),
    FETCH_HOMEOWNER_APPLICATIONS_FOR_PROJECT: Symbol("FETCH HOMEOWNER_APPLICATION FOR PROJECT"),
});

export type HomeownerApplicationEventTypes = $Keys<typeof HomeownerApplicationEventTypesEnum>;

export class HomeownerApplicationEvent {
    type: HomeownerApplicationEventTypes;

    constructor(type: HomeownerApplicationEventTypes) {
        this.type = type;
    }
}

export class FetchHomeownerApplicationForProjectEvent extends HomeownerApplicationEvent {

    projectId: string;

    constructor(projectId: string) {
        super(HomeownerApplicationEventTypesEnum.FETCH_HOMEOWNER_APPLICATIONS_FOR_PROJECT);
        this.projectId = projectId;
    }
}

export class UpdateHomeownerApplicationForProjectEvent extends HomeownerApplicationEvent {

    homeownerApplication: HomeownerApplication;
    logRefFile:any;
    projectId: string;

    constructor(projectId: string, logRefFile:any, homeownerApplication: HomeownerApplication) {
        super(HomeownerApplicationEventTypesEnum.UPDATE_HOMEOWNER_APPLICATION);
        this.projectId = projectId;
        this.homeownerApplication = homeownerApplication;
        this.logRefFile = logRefFile;
    }
}

export class HomeownerApplicationBloc {
    _outHomeownerApplicationStream: Subject = new Subject();

    subscribeToHomeownerApplicationContext(observer?: PartialObserver<HomeownerApplicationOutEvent>) {
        return this._outHomeownerApplicationStream.subscribe(observer);
    }

    _eventController: Subject = new Subject();

    sendEvent(event: HomeownerApplicationEvent) {
        return this._eventController.next(event)
    }

    _homeownerApplicationService: HomeownerApplicationService;

    constructor(homeownerApplicationService: HomeownerApplicationService) {
        this._homeownerApplicationService = homeownerApplicationService;
        this._eventController.subscribe(this.buildEventHandler())
    }

    buildEventHandler = () => {
        return {
            next: async (event: HomeownerApplicationEvent) => {
                switch (event.type) {
                    case HomeownerApplicationEventTypesEnum.FETCH_HOMEOWNER_APPLICATIONS_FOR_PROJECT: {
                        const projectId = (event: FetchHomeownerApplicationForProjectEvent).projectId;
                        const homeownerApplication = await this._homeownerApplicationService.getHomeownerApplicationForProject(projectId);
                        this._outHomeownerApplicationStream.next(new FetchHomeownerApplicationForProjectOutEvent(projectId, homeownerApplication));
                        break;
                    }
                    case HomeownerApplicationEventTypesEnum.UPDATE_HOMEOWNER_APPLICATION: {
                        const {projectId, logRefFile ,homeownerApplication} = (event: UpdateHomeownerApplicationForProjectEvent);
                        const updatedHomeownerApplication = await this._homeownerApplicationService.updateHomeownerApplication(projectId,logRefFile, homeownerApplication);
                        this._outHomeownerApplicationStream.next(new UpdateHomeownerApplicationForProjectOutEvent(projectId,updatedHomeownerApplication));
                        break;
                    }
                    default: {
                        throw new Error("Unknown homeownerApplication event: " + event.type);
                    }
                }
            },
            error(err) {
                throw err
            },
        };
    };

    dispose() {
        this._outHomeownerApplicationStream.complete();
        this._eventController.complete();
    }
}


export const HomeownerApplicationOutEventTypesEnum = Object.freeze({
    FETCHED: Symbol("FETCH"),
    UPDATED: Symbol("UPDATED"),
});

export type HomeownerApplicationOutEventTypes = $Keys<typeof HomeownerApplicationOutEventTypesEnum>;

export class HomeownerApplicationOutEvent {
    type: HomeownerApplicationOutEventTypes;

    constructor(type: HomeownerApplicationOutEventTypes) {
        this.type = type;
    }
}

export class FetchHomeownerApplicationForProjectOutEvent extends HomeownerApplicationOutEvent {

    projectId: string;
    homeownerApplication: ?HomeownerApplication;

    constructor(projectId: string, homeownerApplication: ?HomeownerApplication) {
        super(HomeownerApplicationOutEventTypesEnum.FETCHED);
        this.homeownerApplication = homeownerApplication;
        this.projectId = projectId;
    }
}


export class UpdateHomeownerApplicationForProjectOutEvent extends HomeownerApplicationOutEvent {

    projectId: string;
    updatedHomeownerApplication: HomeownerApplication;

    constructor(projectId: string, updatedHomeownerApplication: Array<HomeownerApplication>) {
        super(HomeownerApplicationOutEventTypesEnum.UPDATED);
        this.updatedHomeownerApplication = updatedHomeownerApplication;
        this.projectId = projectId;
    }
}
