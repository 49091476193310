//@flow

import React from "react";
import CustomSuggest from "../CustomSuggest/CustomSuggest";
import TaskGroup from "../../models/TaskGroup/TaskGroup";

type Props = {
  taskGroups: ?Array<TaskGroup>,
  inputName?: string
};

export default function TaskGroupSuggest(props: Props) {
  const selectedItem = findUnitById(props.selectedItem, props.taskGroups);

  return (
    <CustomSuggest
      inputName={props.inputName}
      items={props.taskGroups}
      filterItems={filterTaskGroups}
      inputValueRenderer={inputValueRenderer}
      inputSelectValueMapper={inputSelectValueMapper}
      itemToKeyMapper={itemToKeyMapper}
      defaultSelectedItem={selectedItem}
    />
  );
}

function itemToKeyMapper(taskGroup: TaskGroup) {
  return taskGroup.id;
}

function inputSelectValueMapper(taskGroup: TaskGroup) {
  return taskGroup.id;
}

function inputValueRenderer(taskGroup: TaskGroup) {
  return taskGroup.title;
}

function filterTaskGroups(query, taskGroup, _index, exactMatch) {
  const normalizedTaskGroup = taskGroup.title.toLowerCase();
  const normalizedQuery = query.toLowerCase();

  if (exactMatch) {
    return normalizedTaskGroup === normalizedQuery;
  } else {
    return `${normalizedTaskGroup}`.indexOf(normalizedQuery) >= 0;
  }
}

const findUnitById = (unitId: ?string, units) => {
  if (!units || !unitId) return null;
  return units.find(unit => {
    return unit.id === unitId;
  });
};
