export const customStyles = {
    option: (provided, state) => ({
      ...provided,
    //   borderBottom: '1px solid black',
    //   color: state.isSelected ? 'red' : 'blue',
    //   padding: 20,
    fontSize:15
    }),
    control: base => ({
        ...base,
       // height:  0,
        minHeight: 20,
        width:200,
        minWidth:150,
        fontSize:15
      }),
      dropdownIndicator: (styles) => ({
        ...styles,
        paddingTop: 7,
        paddingBottom: 7,
    }),
    clearIndicator: (styles) => ({
        ...styles,
        paddingTop: 7,
        paddingBottom: 7,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
  
      return { ...provided, opacity, transition };
    }
  }