// @flow

import type AssignedTask, {CreateAssignedTaskRequest} from "../../models/AssignedTask/AssignedTask";
import type AssignedTaskApi from "../../api/AssignedTaskApi/AssignedTaskApi";
import {applyAssignedTaskUpdate, UpdateAssignedTaskRequest} from "../../models/AssignedTask/AssignedTask";

class AssignedTaskService {

    assignedTaskApi: AssignedTaskApi;

    constructor(assignedTaskApi: AssignedTaskApi) {
        this.assignedTaskApi = assignedTaskApi;
    }

    async getAssignedTasksForProject(projectId: string): Promise<Array<AssignedTask>> {
        return await this.assignedTaskApi.getAssignedTasksForProject(projectId);
    }

    async createAssignedTask(projectId: string, createTaskRequest: CreateAssignedTaskRequest): Promise<AssignedTask> {
        return await this.assignedTaskApi.createTask(projectId, createTaskRequest);
    }

    async updateAssignedTask(projectId: string, assignedTask: AssignedTask, updateAssignedTaskReqeust : UpdateAssignedTaskRequest): Promise<AssignedTask> {
        await this.assignedTaskApi.updateTask(projectId, assignedTask.id, updateAssignedTaskReqeust);

        return applyAssignedTaskUpdate(assignedTask, updateAssignedTaskReqeust);
    }

    async deleteAssignedTask(projectId: string, assignedTaskId: string): Promise<void> {
        await this.assignedTaskApi.deleteTask(projectId, assignedTaskId);
    }
}

export default AssignedTaskService;
