// @flow

import React, { useState,useEffect } from "react";
import Flexbox from "flexbox-react";
import { Button, Intent, Spinner, Tag, Popover , Position} from "@blueprintjs/core";
import styles from "./InvoiceStatus.module.css";
import type Project, {ProjectStatusesTypes} from "../../models/Project/Project";
import moment from "moment";
import { ProjectStatuses } from "../../models/Project/Project";

type Props = {
  project: ?Project,
  onProjectMilestoneUpdate: (
    project: Project,
    newProjectStatus: ProjectStatusesTypes
  ) => any
};

const milestones = {
  milestoneInvoicesSubmitted: {
    text: "All Invoices Submitted",
    status: ProjectStatuses.INVOICES_SUBMITTED
  },
  milestoneInvoicesPaid: {
    text: " All Invoices Paid",
    status: ProjectStatuses.INVOICES_PAID
  }, 
  milestoneReimbursementSubmitted: {
    text: "Reimbursement Submitted",
    status: ProjectStatuses.REIMBURSEMENT_SUBMITTED
  },
  milestoneReimbursementReceived: {
    text: "Reimbursement Recieved",
    status: ProjectStatuses.REIMBURSEMENT_RECEIVED
  }
};

export default function ProjectMilestoneManager(props: Props) {
  const { project, onProjectMilestoneUpdate,onCloseProjectButtonClick } = props;

  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const [currentMileStone, setCurrentMilestone] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0)
    }, [])

  let milestoneList = [<Spinner />];

  if (project) {
    milestoneList = [];
    for (let milestone of Object.keys(milestones)) {
      let m;
      if (milestone === "milestoneProjectCreated")
        m = new moment(project.created_at);
      else {
        let timestamp = project[milestone];
        if (timestamp) m = new moment(timestamp);
      }

      milestoneList.push(
        <Flexbox padding={"10px"} width={"100%"}>
          <Flexbox flex={2}>
            <h4>{milestones[milestone].text}:</h4>
          </Flexbox>
          <Flexbox flex={3}>
            {m && m.isValid() ? (
              <Tag minimal intent={Intent.PRIMARY}>
                {m.format("LLLL")}
              </Tag>
            ) : (
              <Tag minimal={true}>-</Tag>
            )}
          </Flexbox>
          <Flexbox alignItems={"center"} justifyContent={"center"} flex={1}>
            {!m && (milestones[milestone].button !== false) && (
              <Flexbox>
                <Button
                  large
                  icon={"endorsed"}
                  onClick={() => {
                    setCurrentMilestone(milestone);
                    setConfirmOpen(true);
                  }}
                />
              </Flexbox>
            )}
            <Popover
              position={Position.TOP_RIGHT}
              isOpen={isConfirmOpen && currentMileStone === milestone}
              onClose={() => {
                setConfirmOpen(false);
              }}
              content={
                <Flexbox>
                  <Button
                    icon={"cross"}
                    text={"Cancel"}
                    minimal
                    onClick={() => {
                      setConfirmOpen(false);
                    }}
                  />
                  <Button
                    icon={"confirm"}
                    text={"Confirm"}
                    intent={Intent.WARNING}
                    onClick={() => {
                      onProjectMilestoneUpdate(
                        project,
                        milestones[currentMileStone].status
                      );
                      setConfirmOpen(false);
                    }}
                  />
                </Flexbox>
              }
            >
              <></>
            </Popover>
          </Flexbox>
        </Flexbox>
      );
    }
  }

  return (
    <Flexbox
      width={"100%"}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      padding={"20px"}
      flexDirection={"column"}
      className={styles.teamViewContainer}
    >
      <Flexbox
        maxWidth={"1000px"}
        justifyContent={"flex-start"}
        flexDirection={"column"}
        width={"100%"}
        paddingBottom={"25px"}
      >
        {milestoneList}
        <Flexbox>
          <Flexbox padding={"2px"}></Flexbox>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
}
