//@Flow

import React, {useContext, useEffect, useState} from 'react';
import LoadingMessage from "../LoadingMessage/LoadingMessage";
import { AuthContext} from "../../blocs/authBloc";
import type {Subscription} from 'rxjs'
import {AuthStates} from "../../blocs/authBloc";
import history from '../../history';
import Flexbox from "flexbox-react";
import BlocRegistry from "../../blocs/registry";


function SecureContainer<C: React$ComponentType<any>>(WrappedComponent: C): React$ComponentType<any> {
    return function Component(props) {
        const [loading, setLoading] = useState(true);
        const {authBloc} = useContext(BlocRegistry);

        const authEventHandler = {
            next(authContext: AuthContext) {
                switch (authContext.State) {
                    case AuthStates.UNINITIALIZED: {
                        setLoading(true);
                        break;
                    }
                    case AuthStates.UNAUTHENTICATED: {
                        setLoading(true);
                        history.replace(`/login`);
                        break;
                    }
                    case AuthStates.AUTHENTICATING: {
                        setLoading(true);
                        break;
                    }
                    case AuthStates.AUTHENTICATED: {
                        setLoading(false);
                        break;
                    }
                    default: {
                        throw new Error("Unknown auth state: " + authContext.state);
                    }
                }
            },
            error(err: Error) {
                throw err;
            }
        };

        useEffect(() => {
            const subscription: Subscription = authBloc.subscribeToAuthContext(authEventHandler);
            return () => {
                subscription.unsubscribe();
            }
        }, [authBloc]);

        if (loading) return (
            <Flexbox justifyContent={'center'} alignContent={'center'} alignItems={'center'} width={'100vw'} height={'100vh'}>
                <LoadingMessage message={"LOADING"}/>
            </Flexbox>
        )
        else return <WrappedComponent {...props} />;
    };
}

export default SecureContainer;
