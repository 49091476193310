// @flow

import React, { useContext, useEffect, useState } from "react";
import styles from "./ProjectDetailsView.module.css";
import Flexbox from "flexbox-react";
import {
  Button,
  Callout,
  Card,
  Divider,
  Intent,
  Overlay,
  Spinner
} from "@blueprintjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faTools,
  faUser,
  faClipboardList,
  faImages,
  faPaperclip,
  faFlagCheckered,
  faFileDownload,
  faLockOpen,
  faLock,
  faFileInvoiceDollar,
  faDownload,
  faGlobeAmericas
} from "@fortawesome/free-solid-svg-icons";
import ProjectAddressDisplay from "../../components/ProjectAddressDisplay/ProjectAddressDisplay";
import Project, { ProjectStatuses, ProjectStates } from "../../models/Project/Project";
import ProjectStatusBadge from "../../components/ProjectStatusBadge/ProjectStatusBadge";
import ProjectTaskPicker from "../../components/ProjectTaskPicker/ProjectTaskPicker";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Route, Switch } from "react-router-dom";
import type { match } from "react-router-dom";
import history from "../../history";
import TaskGroup from "../../models/TaskGroup/TaskGroup";
import Task from "../../models/Task/Task";
import BidSheetExporter from "../../components/BidSheetExporter/BidSheetExporter";
import BlocRegistry from "../../blocs/registry";
import type { Subscription } from "rxjs";
import type { AssignedTaskOutEventTypes } from "../../blocs/assignedTaskBloc";
import {
  AssignedTaskOutEventTypesEnum,
  CreateAssignedTaskForProjectEvent,
  DeleteAssignedTaskForProjectEvent,
  FetchTasksForProjectEvent,
  UpdateAssignedTaskForProjectEvent
} from "../../blocs/assignedTaskBloc";
import AssignedTask, {
  CreateAssignedTaskRequest,
  UpdateAssignedTaskRequest
} from "../../models/AssignedTask/AssignedTask";
import HomeownerApplicationManager from "../../components/HomeownerApplicationManager/HomeownerApplicationManager";
import type { ProjectStatusesTypes, ProjectStatesTypes } from "../../models/Project/Project";
import ProjectPhotosManager from "../../components/ProjectPhotosManager/ProjectPhotosManager";
import ProjectFilesManager from "../../components/ProjectFilesManager/ProjectFilesManager";
import { GetAttachmentResponse } from "../../models/Attachment/Attachment";
import ProjectMilestoneManager from "../../components/ProjectMilestonesManager/ProjectMilestoneManager";
import Vendor from "../../models/Vendor/Vendor";
import ProjectVendorsManager from "../../components/ProjectVendorsManager/ProjectVendorsManger";
import DeleteConfirmationPopup from "../../components/ConfirmationAlert/ConfirmationAlert";
import ManageInvoicesView from "../ManageInvoicesView/ManageInvoicesView";
import ManageNotes from "../ManageNotes/ManageNotesView";
import ExportErManager from "../../components/ExportErManager/ExportErManager";
import { AuthRoles } from "../../blocs/authBloc";
import BudgetSheetExporter from "../../components/BudgetSheetExporter/BudgetSheetExporter";
import { apiAxios } from "../../config/AxiosConfig";

const ResponsiveGridLayout = WidthProvider(Responsive);

type Props = {
  project: ?Project,
  match: match,
  taskGroups: ?Array<TaskGroup>,
  tasks: ?Array<Task>,
  onProjectStatusUpdate: (
    project: Project,
    newStatus: ProjectStatusesTypes
  ) => any,
  onProjectMilestoneUpdate: (
    project: Project,
    newStatus: ProjectStatusesTypes
  ) => any,
  onCloseProjectButtonClick: (
    project: Project,
    newState: ProjectStatesTypes
  ) => any,
  onSetProjectCoverPhoto: (request: GetAttachmentResponse) => any,
  coverPhotos: Map<string, string>,
  vendors: ?Array<Vendor>,
  onVendorRemove: (projectId: string, vendorId: string) => Promise<any>,
  onVendorAdd: (projectId: string, vendorId: string) => Promise<any>,
  onAddVendorButtonClick: () => any,
  currentUserRole: String,
  brand: string
};

const navigateToAddTasks = (project: Project) => {
  history.push(`/app/view-project/${project.id}/add-tasks`);
};

const navigateToHomeownerApplication = (project: Project) => {
  history.push(`/app/view-project/${project.id}/homeowner-application`);
};

const navigateToPhotos = (project: Project) => {
  history.push(`/app/view-project/${project.id}/photos`);
};

const navigateToFiles = (project: Project) => {
  history.push(`/app/view-project/${project.id}/files`);
};

const navigateToMilestones = (project: Project) => {
  history.push(`/app/view-project/${project.id}/milestones`);
};

const navigateToVendors = (project: Project) => {
  history.push(`/app/view-project/${project.id}/vendors`);
};

const navigateToInvoices = (project: Project) => {
  history.push(`/app/view-project/${project.id}/invoices`);
};

const navigateToNotes = (project: Project) => {
  history.push(`/app/view-project/${project.id}/notes`);
};

const navigateToExportEr = (project: Project) => {
  history.push(`/app/view-project/${project.id}/environment-review`);
};


const createButton = (
  index: number,
  icons: Array<FontAwesomeIcon>,
  label: string,
  onClick?: () => void,
  disabled?: boolean
) => {
  let isInteractive = true;
  if (onClick === undefined || disabled) isInteractive = false;

  return (
    <Card
      key={`${index}`}
      className={styles.buttonCard}
      interactive={isInteractive}
      onClick={onClick}
    >
      <Flexbox width={"100%"} height={"100%"} alignItems={"center"}>
        <Flexbox>
          <span className={`fa-layers fa-fw ${styles.faIcon}`}>{icons}</span>
        </Flexbox>
        <Flexbox>
          <div className={`bp3-ui-text`}>
            <h3 className={styles.buttonLabel}>{label}</h3>
          </div>
        </Flexbox>
      </Flexbox>
    </Card>
  );
};

const generateLayout = (buttons: Array<any>) => {
  return {
    lg: buttons.map((button, index) => {
      return {
        x: 0,
        y: index,
        w: 1,
        h: 1,
        i: index.toString(),
        static: true
      };
    }),
    md: buttons.map((button, index) => {
      return {
        x: 0,
        y: index,
        w: 1,
        h: 1,
        i: index.toString(),
        static: true
      };
    }),
    sm: buttons.map((button, index) => {
      return {
        x: 0,
        y: index,
        w: 1,
        h: 1,
        i: index.toString(),
        static: true
      };
    }),
    xs: buttons.map((button, index) => {
      return {
        x: 0,
        y: index,
        w: 1,
        h: 1,
        i: index.toString(),
        static: true
      };
    }),
    xss: buttons.map((button, index) => {
      return {
        x: 0,
        y: index,
        w: 1,
        h: 1,
        i: index.toString(),
        static: true
      };
    })
  };
};

export default function ProjectDetailsView(props: Props) {
  const { assignedTaskBloc, authBloc } = useContext(BlocRegistry);
  const [assignedTasks, setAssignedTasks] = useState(null);
  const [lastBlocEvent, setLastBlocEvent] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [exportingBudgetsSheet, setExportingBudgetsSheet] = useState(false);
  const [newTasks, setNewTasks] = useState(null);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [newTaskGroups, setNewTaskGroups] = useState(null);

  const {
    project,
    taskGroups,
    tasks,
    onProjectStatusUpdate,
    onSetProjectCoverPhoto,
    // coverPhotos,
    onProjectMilestoneUpdate,
    onCloseProjectButtonClick,
    vendors,
    onVendorAdd,
    onVendorRemove,
    onAddVendorButtonClick,
    currentUserRole, brand,
  } = props;

  const [image, setImage] = useState(null);
  const [coverPhotos, setCoverPhotos] = useState(null);

  useEffect(() => {
    (async () => {
      const tenantId = authBloc.getCurrentTenant();

      const buildHeaders = () => {
        const accessToken = authBloc.getCurrentToken();
        const auth = "Bearer " + accessToken;
        return { headers: { Authorization: auth } };
      };

      if (project) {
        try {
          const coverPhotoResponse = await apiAxios.get(
            `tenants/${tenantId}/projects/${project.id}/cover-photo`,
            buildHeaders()
          );
          setCoverPhotos(coverPhotoResponse.data.getUrl);
          //coverPhotoUrlMap.set(project.id, coverPhotoResponse.data.getUrl);
        } catch (err) {
          if (err.statusCode === 404) {
            console.debug(
              "There was not cover photo found for a project. This probably means no cover photo is yet set, but it could indicate a problem with the endpoint"
            );
          } else {
            console.error(err);
          }
        }


      }
    })();
  }, [project]);
  useEffect(() => {
    if (project && coverPhotos) {
      const photoUrl = coverPhotos
      if (!photoUrl) return;
      const newImage = new Image();
      newImage.onload = () => {
        setImage(newImage);
      };

      newImage.src = photoUrl;
    }
  }, [project, coverPhotos]);

  useEffect(() => {
    const subscription: Subscription = assignedTaskBloc.subscribeToAssignedTaskContext(
      {
        next(event: AssignedTaskOutEventTypes) {
          setLastBlocEvent(event);
        },
        error(err: Error) {
          throw err;
        }
      }
    );
    return () => {
      subscription.unsubscribe();
    };
  }, [assignedTaskBloc]);

  useEffect(() => {
    (async () => {
      if (assignedTasks?.length) {
        const tenantId = authBloc.getCurrentTenant();

        const buildHeaders = () => {
          const accessToken = authBloc.getCurrentToken();
          const auth = "Bearer " + accessToken;
          return { headers: { Authorization: auth } };
        };

        const result = [...assignedTasks].filter(obj => obj.hasOwnProperty('taskList'));
        try {
          const assignedTaskss = await apiAxios.get(`/tenants/${tenantId}/list/${result[0].taskList?.id}/task-groups`, buildHeaders());
          setNewTaskGroups(assignedTaskss?.data);
        } catch (err) {
          if (err.statusCode === 404) {
            console.debug(
              "There was not cover photo found for a project. This probably means no cover photo is yet set, but it could indicate a problem with the endpoint"
            );
          } else {
            console.error(err);
          }
        }
        try {
          const newTasks = await apiAxios.get(`/tenants/${tenantId}/list/${result[0]?.taskList?.id}/tasks`, buildHeaders());
          setNewTasks(newTasks?.data);
        } catch (err) {
          if (err.statusCode === 404) {
            console.debug(
              "There was not cover photo found for a project. This probably means no cover photo is yet set, but it could indicate a problem with the endpoint"
            );
          } else {
            console.error(err);
          }
        }
      }
    })();
  }, [assignedTasks]);

  useEffect(() => {
    if (!lastBlocEvent) return;
    switch (lastBlocEvent.type) {
      case AssignedTaskOutEventTypesEnum.FETCHED: {
        setAssignedTasks(lastBlocEvent.assignedTasks);
        break;
      }
      case AssignedTaskOutEventTypesEnum.ADDED: {
        let tasks = Array.from(assignedTasks);
        tasks = tasks.concat(lastBlocEvent.newTasks);
        setAssignedTasks(tasks);
        break;
      }
      case AssignedTaskOutEventTypesEnum.UPDATED: {
        let tasks = assignedTasks;
        const updates = lastBlocEvent.updatedTasks.length;
        let appliedUpdates = 0;
        for (
          let existingTaskIndex = 0;
          existingTaskIndex < tasks.length;
          existingTaskIndex++
        ) {
          const existingTask = tasks[existingTaskIndex];
          const foundTaskIndex = lastBlocEvent.updatedTasks.findIndex(
            updatedTask => updatedTask.id === existingTask.id
          );
          if (foundTaskIndex >= 0) {
            tasks[existingTaskIndex] =
              lastBlocEvent.updatedTasks[foundTaskIndex];
            if (++appliedUpdates >= updates) break;
          }
        }
        setAssignedTasks(Array.from(tasks));
        break;
      }
      case AssignedTaskOutEventTypesEnum.DELETED: {
        let tasks = assignedTasks;
        const deletions = lastBlocEvent.deletedTasksIds.length;
        let appliedDeletions = 0;
        for (
          let existingTaskIndex = 0;
          existingTaskIndex < tasks.length;
          existingTaskIndex++
        ) {
          const existingTask = tasks[existingTaskIndex];
          const foundTaskIndex = lastBlocEvent.deletedTasksIds.findIndex(
            deletedTaskId => deletedTaskId === existingTask.id
          );
          if (foundTaskIndex >= 0) {
            tasks.splice(existingTaskIndex, 1);
            if (++appliedDeletions >= deletions) break;
          }
        }
        setAssignedTasks(Array.from(tasks));
        break;
      }
      default: {
        console.error(
          "Unknown assigned task out event type: " + lastBlocEvent.type
        );
      }
    }
  }, [lastBlocEvent]);

  useEffect(() => {
    if (!project) return;
    assignedTaskBloc.sendEvent(new FetchTasksForProjectEvent(project.id));
  }, [project]);

  const handleNewAssignedTaskCreate = async (
    createAssignedTaskRequest: CreateAssignedTaskRequest
  ) => {
    assignedTaskBloc.sendEvent(
      new CreateAssignedTaskForProjectEvent(createAssignedTaskRequest)
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleNewAssignedTaskCreated = async () => { };

  const handleAssignedTaskUpdate = async (
    assignedTask: AssignedTask,
    updateAssignedTaskRequest: UpdateAssignedTaskRequest
  ) => {
    if (!project) return;
    await assignedTaskBloc.sendEvent(
      new UpdateAssignedTaskForProjectEvent(
        project.id,
        assignedTask,
        updateAssignedTaskRequest
      )
    );
  };

  const handleAssignedTaskUpdated = async () => { };

  const handleAssignedTaskRemoved = async (assignedTaskId: string) => {
    if (!project) return;
    assignedTaskBloc.sendEvent(
      new DeleteAssignedTaskForProjectEvent(project.id, assignedTaskId)
    );
    setTimeout(() => {
      assignedTaskBloc.sendEvent(
        new FetchTasksForProjectEvent(project.id)
      );
    }, 1000)
  };

  if (!project) return <Spinner />;

  const handleHomeownerApplicationUpdated = () => {
    onProjectStatusUpdate(project, ProjectStatuses.APP_STARTED);
  };

  const handleHomeownerApplicationReadyForReview = (isGoBack: Boolean) => {
    onProjectStatusUpdate(project, ProjectStatuses.APP_SUBMITTED);
    if (isGoBack) {
      history.goBack();
    }
  };

  const handleHomeownerApplicationApproved = (isGoBack: Boolean) => {
    onProjectStatusUpdate(project, ProjectStatuses.APP_APPROVED);
    if (isGoBack) {
      history.goBack();
    }
  };

  const handleHomeownerApplicationRejected = (isGoBack: Boolean) => {
    onProjectStatusUpdate(project, ProjectStatuses.APP_DENIED);
    if (isGoBack) {
      history.goBack();
    }
  };

  const handleErSubmittedButtonClick = () => {
    onProjectStatusUpdate(project, ProjectStatuses.ER_SUBMITTED);
    navigateToMilestones(project);
  }

  const handleErReleasedButtonClick = () => {
    onProjectStatusUpdate(project, ProjectStatuses.ER_RELEASED);
    navigateToMilestones(project);
  }

  let buttonTitle,
    selectIcon,
    newState,
    message,
    intent

  if (project.projectState === "CLOSED") {
    buttonTitle = "Reopen Project";
    selectIcon = faLockOpen;
    newState = ProjectStates.OPEN;
    message = "Are you sure you want to Re-open this project?"
    intent = Intent.SUCCESS


  }
  else {
    buttonTitle = "Close Project"
    selectIcon = faLock;
    newState = ProjectStates.CLOSED;
    message = "Are you sure you want to Close this project?"
    intent = Intent.DANGER
  }

  const marketingUserButtons = [
    createButton(
      0,
      [<FontAwesomeIcon icon={faImages} color="#ffffff" />],
      "Photos",
      () => {
        if (project) navigateToPhotos(project);
      }
    ),
    createButton(
      1,
      [<FontAwesomeIcon icon={faFileInvoiceDollar} color="#ffffff" />],
      "Notes",
      () => {
        navigateToNotes(project);
      }
    ),

  ];

  const otherUserButtons = [
    createButton(
      0,
      [<FontAwesomeIcon icon={faClipboardList} color="#ffffff" />],
      "Tasks & Pricing",
      () => {
        navigateToAddTasks(project);
      }
    ),
    createButton(
      1,
      [<FontAwesomeIcon icon={faUser} color="#ffffff" />],
      "Homeowner",
      () => {
        if (project) navigateToHomeownerApplication(project);
      }
    ),
    createButton(
      2,
      [<FontAwesomeIcon icon={faTools} color="#ffffff" />],
      "Contacts",
      () => {
        if (project) navigateToVendors(project);
      }
    ),
    createButton(
      3,
      [<FontAwesomeIcon icon={faImages} color="#ffffff" />],
      "Photos",
      () => {
        if (project) navigateToPhotos(project);
      }
    ),
    createButton(
      4,
      [<FontAwesomeIcon icon={faPaperclip} color="#ffffff" />],
      "Attachments",
      () => {
        if (project) navigateToFiles(project);
      }
    ),
    createButton(
      5,
      [<FontAwesomeIcon icon={faFileInvoiceDollar} color="#ffffff" />],
      "Notes",
      () => {
        navigateToNotes(project);
      }
    ),
    createButton(
      6,
      [<FontAwesomeIcon icon={faFileInvoiceDollar} color="#ffffff" />],
      "Invoices",
      () => {
        navigateToInvoices(project);
      }
    ),
    createButton(
      7,
      [<FontAwesomeIcon icon={faGlobeAmericas} color="#ffffff" />],
      "Environment Review",
      () => {
        if (project) navigateToExportEr(project);
      }
    ),
    createButton(
      8,
      [<FontAwesomeIcon icon={faFlagCheckered} color="#ffffff" />],
      "Milestones",
      () => {
        navigateToMilestones(project);
      }
    ),
  ];

  const accountManagerButtons = [
    createButton(
      0,
      [<FontAwesomeIcon icon={faClipboardList} color="#ffffff" />],
      "Tasks & Pricing",
      () => {
        navigateToAddTasks(project);
      }
    ),
    createButton(
      1,
      [<FontAwesomeIcon icon={faTools} color="#ffffff" />],
      "Contacts",
      () => {
        if (project) navigateToVendors(project);
      }
    ),
    createButton(
      2,
      [<FontAwesomeIcon icon={faPaperclip} color="#ffffff" />],
      "Attachments",
      () => {
        if (project) navigateToFiles(project);
      }
    ),
    createButton(
      3,
      [<FontAwesomeIcon icon={faFileInvoiceDollar} color="#ffffff" />],
      "Notes",
      () => {
        navigateToNotes(project);
      }
    ),
    createButton(
      4,
      [<FontAwesomeIcon icon={faFileInvoiceDollar} color="#ffffff" />],
      "Invoices",
      () => {
        navigateToInvoices(project);
      }
    ),

    // createButton(
    //   4,
    //   [<FontAwesomeIcon icon={faGlobeAmericas} color="#ffffff" />],
    //   "Environment Review",
    //   () => {
    //     if (project) navigateToExportEr(project);
    //   }
    // ),
    createButton(
      5,
      [<FontAwesomeIcon icon={faFlagCheckered} color="#ffffff" />],
      "Milestones",
      () => {
        navigateToMilestones(project);
      }
    ),
  ];

  const getAccountManagerButtons = () => {
    if (AuthRoles.ACCOUNT_MANAGER === currentUserRole) {
      return accountManagerButtons
    } else if (AuthRoles.MARKETING_MANAGER === currentUserRole) {
      return marketingUserButtons;
    } else {
      return otherUserButtons;
    }
  }

  const buttons = getAccountManagerButtons();


  const confirmPopup = (
    <DeleteConfirmationPopup
      isOpen={showConfirmationPopup}
      cancelButtonText="Cancel"
      confirmButtonText="Ok"
      icon="confirm"
      type={intent}
      onCancel={() => setShowConfirmationPopup(false)}
      onConfirm={() => {
        onCloseProjectButtonClick(project, newState);
        setShowConfirmationPopup(false);
      }}
      message={message}
    />
  );



  if (assignedTasks && assignedTasks.length > 0 && props.currentUserRole !== AuthRoles.MARKETING_MANAGER)
    buttons.push(
      createButton(
        9,
        [<FontAwesomeIcon icon={faFileDownload} color="#ffffff" />],
        "Export Bid Sheet",
        () => {
          setExporting(true);
        }
      )
    );

  if (assignedTasks && assignedTasks.length > 0 && props.currentUserRole !== AuthRoles.MARKETING_MANAGER)
    buttons.push(
      createButton(
        10,
        [<FontAwesomeIcon icon={faFileDownload} color="#ffffff" />],
        "Export Budget Sheet",
        () => {
          setExportingBudgetsSheet(true);
        }
      )
    );

  if (project && project.projectState !== "REJECTED")
    AuthRoles.COMPLIANCE_AUDITOR !== props.currentUserRole && AuthRoles.MARKETING_MANAGER !==  props.currentUserRole && buttons.push(
      createButton(
        11,
        [<FontAwesomeIcon icon={selectIcon} color="#ffffff" />],
        `${buttonTitle}`,
        () => {
          setShowConfirmationPopup(true);
        }
      )
    );


  const primary = (
    <div className={styles.projectDetailsView}>
      <Flexbox
        height={"28vh"}
        maxHeight={"300px"}
        alignItems={"center"}
        justifyContent={"center"}
        className={styles.thumbnailContainer}
      >
        {image ? (
          <img className={styles.thumbnail} src={image.src} />
        ) : (
          <FontAwesomeIcon icon={faHome} color={"white"} transform="grow-50" />
        )}
      </Flexbox>
      <Divider className={`${styles.border}`} />
      <Flexbox justifyContent={"flex-end"} flex={0} height={"0"}>
        <Flexbox justifyContent={"flex-end"} flex={8} height={"0"}></Flexbox>
        <Flexbox justifyContent={"flex-end"} flex={1} height={"0"}>
          <ProjectStatusBadge status={project.status} dynamicSize />
        </Flexbox>
        <Flexbox justifyContent={"flex-end"} flex={1} height={"0"}></Flexbox>
      </Flexbox>
      <Flexbox
        alignItems={"center"}
        justifyContent={"flex-start"}
        padding={"20px"}
      >
        <ProjectAddressDisplay project={project} />
      </Flexbox>
      <Flexbox
        flexGrow={1}
        flexDirection={"column"}
        margin={"0px"}
        alignItems={"center"}
      >
        {confirmPopup}
        <div className={styles.listContainer}>
          <ResponsiveGridLayout
            isDraggable={false}
            rowHeight={60}
            layouts={generateLayout(buttons)}
            margin={[0, 10]}
            breakpoints={{ lg: 1500, md: 1280, sm: 768, xs: 480, xxs: 0 }}
            cols={{ lg: 1, md: 1, sm: 1, xs: 1, xxs: 1 }}
          >
            {buttons}
          </ResponsiveGridLayout>
        </div>
      </Flexbox>
      <Overlay
        isOpen={exporting}
        canOutsideClickClose={false}
        canEscapeKeyClose={false}
        enforceFocus
        usePortal
        autoFocus
      >
        <Flexbox marginTop={"40vh"} width={"100%"}>
          <Flexbox flex={1} />
          <Flexbox minWidth={"500px"} flex={1} justifyContent={"center"}>
            {exporting ? (
              <BidSheetExporter
                onExportComplete={() => {
                  setExporting(false);
                }}
                project={project}
                taskGroups={newTaskGroups}
                assignedTasks={assignedTasks}
              />
            ) : null}
          </Flexbox>
          <Flexbox flex={1} />
        </Flexbox>
      </Overlay>
      <Overlay
        isOpen={exportingBudgetsSheet}
        canOutsideClickClose={false}
        canEscapeKeyClose={false}
        enforceFocus
        usePortal
        autoFocus
      >
        <Flexbox marginTop={"40vh"} width={"100%"}>
          <Flexbox flex={1} />
          <Flexbox minWidth={"500px"} flex={1} justifyContent={"center"}>
            {exportingBudgetsSheet ? (
              <BudgetSheetExporter
                onExportComplete={() => {
                  setExportingBudgetsSheet(false);
                }}
                project={project}
                taskGroups={newTaskGroups}
                assignedTasks={assignedTasks}
              />
            ) : null}
          </Flexbox>
          <Flexbox flex={1} />
        </Flexbox>
      </Overlay>
    </div>
  );


  return (
    <Switch>
      <Route exact path={`${props.match.path}`} render={props => primary} />
      <Route
        path={`${props.match.path}/add-tasks`}
        render={props => (
          <ProjectTaskPicker
            assignedTasks={assignedTasks}
            newTaskGroups={newTaskGroups}
            projectId={project.id}
            totalEstimation=
            {assignedTasks && assignedTasks.map((task, index) => {
              if (index === 0) {
                return task.estimation;
              }
            })}
            taskGroups={newTaskGroups}
            tasks={newTasks}
            onAssignedTaskRemove={handleAssignedTaskRemoved}
            onNewAssignedTaskCreate={handleNewAssignedTaskCreate}
            onNewAssignedTaskCreated={handleNewAssignedTaskCreated}
            onAssignedTaskUpdate={handleAssignedTaskUpdate}
            onAssignedTaskUpdated={handleAssignedTaskUpdated}
            currentUserRole={currentUserRole}
            project={project}
          />
        )}
      />
      <Route
        path={`${props.match.path}/homeowner-application`}
        render={props => (
          <HomeownerApplicationManager
            project={project}
            onHomeownerApplicationUpdated={handleHomeownerApplicationUpdated}
            onHomeownerApplicationReadyForReview={
              handleHomeownerApplicationReadyForReview
            }
            onHomeownerApplicationApproved={handleHomeownerApplicationApproved}
            onHomeownerApplicationRejected={handleHomeownerApplicationRejected}
            projectId={project.id}
            currentUserRole={currentUserRole}
            brand={brand}
          />
        )}
      />
      <Route
        path={`${props.match.path}/photos`}
        render={props => (
          <ProjectPhotosManager
            onSetProjectCoverPhoto={onSetProjectCoverPhoto}
            projectId={project.id}
            project={project}
            currentUserRole={currentUserRole}
          />
        )}
      />
      <Route
        path={`${props.match.path}/files`}
        render={props => <ProjectFilesManager currentUserRole={currentUserRole} project={project} />}
      />
      <Route
        path={`${props.match.path}/milestones`}
        render={props => (
          <ProjectMilestoneManager
            assignedTasks={assignedTasks}
            onProjectMilestoneUpdate={onProjectMilestoneUpdate}
            onCloseProjectButtonClick={onCloseProjectButtonClick}
            project={project}
            currentUserRole={currentUserRole}
          />
        )}
      />
      <Route
        path={`${props.match.path}/vendors`}
        render={props => (
          <ProjectVendorsManager
            onVendorAdd={onVendorAdd}
            onVendorRemove={onVendorRemove}
            project={project}
            vendors={vendors}
            currentUserRole={currentUserRole}
          />
        )}
      />
      <Route
        path={`${props.match.path}/invoices`}
        render={props => (
          <ManageInvoicesView
            project={project}
            vendors={vendors}
            onAddVendorButtonClick={onAddVendorButtonClick}
            onProjectMilestoneUpdate={onProjectMilestoneUpdate}
            project={project}
            currentUserRole={currentUserRole}
          />
        )}
      />

      <Route
        path={`${props.match.path}/notes`}
        render={props => (
          <ManageNotes
            project={project}
            vendors={vendors}
            onAddVendorButtonClick={onAddVendorButtonClick}
            onProjectMilestoneUpdate={onProjectMilestoneUpdate}
            currentUserRole={currentUserRole}
          />
        )}
      />

      <Route
        path={`${props.match.path}/environment-review`}
        render={props => (
          <ExportErManager
            project={project}
            projectId={project.id}
            navigateToFiles={navigateToFiles}
            onErSubmitted={handleErSubmittedButtonClick}
            onErReleased={handleErReleasedButtonClick}
            currentUserRole={currentUserRole}
          />
        )}
      />
    </Switch>
  );
}
