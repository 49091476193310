//@flow

import styles from './AssignedProjectsList.module.css';
import React,{useState, useEffect,useContext} from "react";
import Flexbox from "flexbox-react";
import Project from "../../models/Project/Project";
import {Responsive, WidthProvider} from "react-grid-layout";
import {Card, Spinner} from "@blueprintjs/core";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome} from '@fortawesome/free-solid-svg-icons'
import ProjectStatusBadge from "../ProjectStatusBadge/ProjectStatusBadge";
import ProjectAddressDisplay from "../ProjectAddressDisplay/ProjectAddressDisplay";
import { apiAxios } from '../../config/AxiosConfig';
import BlocRegistry from "../../blocs/registry";
import history from '../../history';

const ResponsiveGridLayout = WidthProvider(Responsive);

type Props = {
  //  coverPhotos: ?Map<string, string>,
    projects: ?Array<Project>,
    onProjectTileClick: (projectId: string)=>void,
    hideTitle: ?Boolean
}

const createProjectTile = (index: number, project: Project, coverPhotoUrl : ?string, onProjectTileClick: (projectId: string)=>void) => {

    return (
        <Flexbox key={index} height={'100%'} width={'100%'}>
            <Card className={styles.projectTile} interactive onClick={() => {
              //  onProjectTileClick(project.id)
                history.push(`/app/view-project/${project.id}`);
            }}>
                <Flexbox height={'100%'} width={'100%'} alignItems={'center'}>
                    <Flexbox flex={2} height={'100%'} alignItems={'center'} justifyContent={'center'}
                             className={styles.thumbnailContainer}>
                        {coverPhotoUrl ? <img className={styles.thumbnail} src={coverPhotoUrl}/> : <FontAwesomeIcon icon={faHome} color={'white'} transform="grow-50"/>}
                    </Flexbox>
                    <Flexbox flex={4}>
                        <ProjectAddressDisplay project={project} truncated={true}/>
                    </Flexbox>
                    <Flexbox flex={1} height={'100%'} width={'100%'} alignItems={'center'} justifyContent={'center'}>
                    </Flexbox>
                </Flexbox>
            </Card>
            <Flexbox className={`fa-layers fa-fw ${styles.badgeContainer}`} height={'100%'} alignItems={'center'}
                     justifyContent={'center'}>
            <ProjectStatusBadge status={project.status}/>
            </Flexbox>
        </Flexbox>
    )
};

const generateLayout = (buttons: Array<any>) => {
    return {
        lg: buttons.map((button, index) => {
            return {
                x: index % 4,
                y: Math.floor(index / 4),
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        }),
        md: buttons.map((button, index) => {
            return {
                x: index % 2,
                y: Math.floor(index / 2),
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        }),
        sm: buttons.map((button, index) => {
            return {
                x: index % 2,
                y: Math.floor(index / 2),
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        }),
        xs: buttons.map((button, index) => {
            return {
                x: 0,
                y: index,
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        }),
        xss: buttons.map((button, index) => {
            return {
                x: 0,
                y: index,
                w: 1,
                h: 1,
                i: index.toString(),
                static: true,
            };
        })
    };
};

export default function AssignedProjectsList(props: Props) {
 const [coverPhotos, setCoverPhotos] = useState(null);
 const { authBloc } = useContext(BlocRegistry);

    let projectTiles;
    
  useEffect(() => {
    (async () => {
      const tenantId = authBloc.getCurrentTenant();

      const buildHeaders = () => {
        const accessToken = authBloc.getCurrentToken();
        const auth = "Bearer " + accessToken;
        return { headers: { Authorization: auth } };
      };

      if (props.projects && props.projects.length > 0) {
        const coverPhotoUrlMap = new Map();
        for (let project of props.projects) {
          if (!project.coverPhoto) continue;
          try {
            const coverPhotoResponse = await apiAxios.get(
              `tenants/${tenantId}/projects/${project.id}/cover-photo`,
              buildHeaders()
            );
            coverPhotoUrlMap.set(project.id, coverPhotoResponse.data.getUrl);
          } catch (err) {
            if (err.statusCode === 404) {
              console.debug(
                "There was not cover photo found for a project. This probably means no cover photo is yet set, but it could indicate a problem with the endpoint"
              );
            } else {
              console.error(err);
            }
          }
        }
        setCoverPhotos(coverPhotoUrlMap);
      }
    })();
  }, [props.projects])

    if (props.projects) projectTiles = props.projects.map((project, index) => {
        let coverPhoto;
        if(coverPhotos) coverPhoto = coverPhotos.get(project.id);

        return createProjectTile(index, project,coverPhoto, props.onProjectTileClick)
    });

    let list = <Spinner/>;
    if (props.projects) {
        if (props.projects.length > 0) list = (
            <ResponsiveGridLayout layouts={generateLayout(props.projects)}
                                  margin={[40, 10]}
                                  breakpoints={{lg: 1500, md: 1280, sm: 768, xs: 480, xxs: 0}}
                                  cols={{lg: 4, md: 2, sm: 1, xs: 1, xxs: 1}}>
                {projectTiles}
            </ResponsiveGridLayout>);
        else {
            list = <div className={`bp3-ui-text bp3-text-disabled ${styles.noProjectsText}`}>no projects</div>;
        }
    }

    return (
        <Flexbox flexGrow={1} flexDirection={'column'} paddingLeft={'10px'} paddingRight={'10px'}>
            {!props.hideTitle && <Flexbox flexGrow={1} alignContent={'flex-start'} paddingTop={'10px'} paddingLeft={'10px'}>
                <h3 className={`bp3-heading ${styles.myProjectsTitle}`}>
                    My Projects
                </h3>
            </Flexbox>}
            <Flexbox justifyContent={'center'} alignContent={'center'}>
                <div className={`bp3-text-large ${styles.listContainer}`}>
                    {list}
                </div>
            </Flexbox>
        </Flexbox>

    )
}
