//@flow

import styles from './ProjectAddressDisplay.module.css';
import React from "react";
import Flexbox from "flexbox-react";
import type Project from "../../models/Project/Project";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type Props = {
    project: Project,
    truncated?: boolean,
}

export default function ProjectAddressDisplay(props: Props) {

    const {project, truncated} = props;

    const address = project.home.address;
    const homeowner = project.home.homeowner;

    let addressText = `${address?.streetAddress}`;
    if (address.streetAddressTwo) addressText = `${addressText}, ${address.streetAddressTwo}`;

    let addressKey = `${address?.streetAddress}`;
    addressKey = addressText?.replace(/ /g,"_");
    addressKey = `${addressKey || 'project'}.pdf`;
    localStorage.setItem("projectAddress", addressKey);

    let addressKey1 = `${address?.streetAddress}`;
    addressKey1 = addressKey1?.replace(/ /g,"_");
    addressKey1 = `${addressKey1 || 'project'}.zip`;
    localStorage.setItem("projectAddressZip", addressKey1);

    let addressTextLineTwo;
    if (!truncated) {
        addressTextLineTwo = `${address.city}, ${address.state} ${address.zip}`
    }

    return (
        <Flexbox flexDirection={'column'} className={styles.addressTextContainer}>
            {truncated && <Flexbox flexDirection={'column'}>
                <h3 className={styles.addressText}>{addressText}</h3>
                {addressTextLineTwo && <h3 className={styles.addressText}>{addressTextLineTwo}</h3>}
            </Flexbox>}
            {!truncated && <Flexbox flexDirection={'column'}>
                <a className={styles.addressLink} onClick={()=>{window.open(`https://www.google.com/maps/search/?api=1&query=${addressText+', '+addressTextLineTwo}`)}}>
                    <h3 className={styles.addressText}>{addressText}</h3>
                    {addressTextLineTwo && <h3 className={styles.addressText}>{addressTextLineTwo}</h3>}
                </a>
            </Flexbox>}
            <Flexbox className={'bp3-text-muted'}>
                <h3 className={styles.addressText}>{`${homeowner.firstName} ${homeowner.lastName}`}</h3>
            </Flexbox>
            {!truncated && <Flexbox className={'bp3-text-muted'} alignItems={'center'}>
                <FontAwesomeIcon icon={faPhone} color="#385988" className={styles.icon}/>
                <a className={styles.addressText} href={`tel:${homeowner.phoneNumber}`}>{homeowner.phoneNumber}</a>
            </Flexbox>}
            {!truncated && homeowner.email &&
            <Flexbox className={'bp3-text-muted'} alignItems={'center'}>
                <FontAwesomeIcon icon={faEnvelope} color="#385988" className={styles.icon}/>
                <a className={styles.addressText} href={`mailto:${homeowner.email}`}>{homeowner.email}</a>
            </Flexbox>}
            <Flexbox flexDirection={'column'}>
                {project.projectGroups && project.projectGroups.map(group=>{
                    return <Flexbox><h6>{group.name}</h6></Flexbox>
                })}
            </Flexbox>
            <Flexbox flexDirection={'column'}>
                <h6>Project ID : {project?.projectNumber}</h6>
                <h6>HUD Number : {project?.hudNumber}</h6>
            </Flexbox>
        </Flexbox>
    )
}
