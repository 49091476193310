// @flow

import React,{useState,useCallback,useRef} from 'react';
import {Button} from "@blueprintjs/core";
import Flexbox from 'flexbox-react'
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import styles from './PhotoPreview.module.css'



type Props = {
    onAcceptImage: (dataUri: string)=>{},
    onRejectImage: ()=>void,
    dataUri:any
}

export function PhotoPreview(props: Props) {

    const {dataUri, onRejectImage, onAcceptImage} = props;
     const [crop, setCrop] = useState({ aspect: null});
    const [image, setImage] = useState( null);
    const [result, setResult] = useState( null);
    const [cropImage, setcCropImage] = useState( true);
    const [flag, setFlag] = useState(false)

        function getCroppedImg() {
            const canvas = document.createElement("canvas");
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d");
          
            // New lines to be added
            const pixelRatio = window.devicePixelRatio;
            canvas.width = crop.width * pixelRatio;
            canvas.height = crop.height * pixelRatio;
            ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
            ctx.imageSmoothingQuality = "high";
          
            ctx.drawImage(
              image,
              crop.x * scaleX,
              crop.y * scaleY,
              crop.width * scaleX,
              crop.height * scaleY,
              0,
              0,
              crop.width,
              crop.height
            );
          
            // As Base64 string
            const base64Image = canvas.toDataURL("image/jpeg");
            setResult(base64Image) ;
            setcCropImage(false)
        }

         const onCropChange = (crop, percentCrop) => {
            setCrop( crop );
          };

          const onComplete=(crop)=>{
           crop.height !== 0 && crop.width !==0 ?setFlag(true):setFlag(false)

          }
        const imageData = result || dataUri
    return (
        <Flexbox width={'100vw'} height={'100vh'} justifyContent={'center'} alignItems={'center'}
                 flexDirection={'column'} className={styles.previewContainer}>
            <Flexbox >
                {
                    cropImage &&
                        <ReactCrop 
                        minHeight={100} 
                        ruleOfThirds={true} minWidth={100}
                        keepSelection={false}  
                        src={dataUri} 
                        onComplete={onComplete}
                        onImageLoaded={setImage} crop={crop} onChange={onCropChange} />
          
                }
                             
                {
                
                 result&&
                    <img alt="cropped_Image"  src={result}/>
                }

            </Flexbox>
            <Flexbox flexDirection={'row'} className={styles.previewControls} width={'100%'} padding={'20px'}
                     justifyContent={'center'}>
                <Flexbox flex={1} justifyContent={'center'}>
                    <Button minimal text={'Cancel'} rightIcon={'reset'} large onClick={onRejectImage}/>
                </Flexbox>
                {
                    result &&
                    <Flexbox flex={1} justifyContent={'center'}>
                <Button minimal text={'undo'} rightIcon={'undo'} large 
                        onClick={()=>
                            { 
                            setcCropImage(true)
                            setResult(null)
                            }
                        }/>

                </Flexbox>
                }
                
                <Flexbox flex={1} justifyContent={'center'}>
                    {
                        flag&&
                        <Button minimal text={'Crop'} rightIcon={cropImage?'tick':'widget'} large onClick={
                            getCroppedImg
                            }/>
        
                    }
                
                </Flexbox>
                <Flexbox flex={1} justifyContent={'center'}>
                    <Button minimal text={'Accept'} rightIcon={'tick'} large onClick={()=>{onAcceptImage(imageData)}}/>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    );
};