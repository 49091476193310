//@flow

import styles from "./CreateTaskRequestBuilder.module.css";
import React, { useState,memo  } from "react";
import * as Yup from "yup";
import { Formik, ErrorMessage, Field, Form, useField } from "formik";
import { Button, Intent, Popover, TextArea } from "@blueprintjs/core";
import Flexbox from "flexbox-react";
import { InputGroup, Divider, Card, NumericInput } from "@blueprintjs/core";
import {
  CreateTaskRequest,
  UpdateTaskRequest,
  PriceTypeEnum,
  QuantityTypeEnum
} from "../../models/Task/Task";
import PriceTypeSuggest from "../PriceTypeSuggest/PriceTypeSuggest";
import type {
  PriceTypeEnumTypes,
  QuantityTypeEnumTypes
} from "../../models/Task/Task";
import UnitSuggest from "../UnitSuggest/UnitSuggest";
import type TaskGroup from "../../models/TaskGroup/TaskGroup";
import TaskGroupSuggest from "../TaskGroupSuggest/TaskGroupSuggest";
import CreateTaskGroupPopoverButton from "../CreateTaskGroupPopoverButton/CreateTaskGroupPopoverButton";
import { CreateTaskGroupRequest } from "../../models/TaskGroup/TaskGroup";
import { NumericFormat } from "react-number-format";

type Props = {
  latestTaskListId:string,
  onNewTaskCreate: (taskToCreate: CreateTaskRequest) => Promise<any>,
  onNewTaskCreated: () => any,
  onUpdateTask: (taskToUpdate: UpdateTaskRequest) => Promise<any>,
  onNewTaskGroupCreate: (createTaskGroupRequest: CreateTaskGroupRequest) => any,
  taskGroups: ?Array<TaskGroup>
};

const uuidV4 = new RegExp(
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
);

const formStates = {
  INPUT: "INPUT",
  SUBMITTING: "SUBMITTING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE"
};

export default function CreateTaskRequestBuilder(props: Props) {
  const [state, setState] = useState(formStates.INPUT);

  const { taskData, taskGroups } = props;
  const createInput = (inputName: string, inputLabel: string) => {
    return (
      <Flexbox flex={1} flexDirection={"column"} width={"100%"}>
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>
          <Field
            className={`bp3-large`}
            as={InputGroup}
            name={inputName}
            type="text"
            fill
            disabled={state !== formStates.INPUT}
          />
        </Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  let initialValues;
  if (taskData) {
    initialValues = {
      title: taskData.title,
      description: taskData.description,
      unit: taskData.unit,
      quantityType: taskData.quantityType,
      priceType: taskData.priceType,
      price: taskData.price,
      unitType: taskData.unitType,
      taskGroupId: taskData.taskGroupId,
      taskListId:props.latestTaskListId
    };
  } else {
    initialValues = {
      title: "",
      description: "",
      unit: "",
      quantityType: "",
      priceType: "",
      price: "",
      unitType: "",
      taskGroupId: "",
      taskListId:props.latestTaskListId
    };
  }

  const createAreaInput = (inputName: string, inputLabel: string) => {
    return (
      <Flexbox flex={1} flexDirection={"column"} width={"100%"}>
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>
          <Field
            className={`bp3-large`}
            as={TextArea}
            name={inputName}
            type="text"
            fill
            disabled={state !== formStates.INPUT}
          />
        </Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const createConditionalInput = (
    inputName: string,
    inputLabel: string,
    disabled: boolean,
    icon?: string,
    input?: Node,
    setValue?: (fieldName: string, value: any) => any,
    formik
  ) => {
    const valueHandler = setValue
      ? value => {
          setValue(inputName, value.toString());
        }
      : null;

    return (
      <Flexbox flex={1} flexDirection={"column"} width={"100%"}>
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>
          <NumericFormat
            className={`bp3-large`}
            as={input ? input : InputGroup}
            name={inputName}
            allowEmptyFormatting
            // type={"number"}
            thousandsGroupStyle={"thousand"}
            thousandSeparator
            prefix="$"
            fill
            disabled={disabled || state !== formStates.INPUT}
            value={formik && formik.values[inputName]}
            onBlur={formik && formik.handleBlur}
            onValueChange={(values) => {
              formik && formik.setFieldValue(inputName, values.value);
            }}
            // style={{ width: 1050, marginRight: "10px" }}
          />
        </Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const createSuggestionInput = (
    inputName: string,
    inputLabel: string,
    input
  ) => {
    return (
      <Flexbox flex={1} flexDirection={"column"} width={"100%"}>
        <Flexbox flex={1}>
          <label htmlFor={inputName}>{inputLabel}</label>
        </Flexbox>
        <Flexbox flex={1}>{input}</Flexbox>
        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  const getPriceInputForPriceType = (
    priceType: PriceTypeEnumTypes,
    value,
    setValue,
    formik
  ) => {
    if (!priceType || priceType.length === 0) return null;
    else {
      switch (priceType) {
        case PriceTypeEnum.USD: {
          return createConditionalInput(
            "price",
            "Price",
            false,
            "dollar",
            NumericInput,
            setValue,
            formik
          );
        }
        case PriceTypeEnum.PER_BID: {
          if (value !== "0") setValue("price", "0");
          break;
        }
        default: {
          return null;
        }
      }
    }
  };

  return (
    <div className={`bp3-running-text bp3-text-large ${styles.formContainer}`}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={Yup.object({
          taskGroupId: Yup.string()
            .matches(uuidV4, { message: "Must be a valid UUID V4" })
            .required("Required"),
          title: Yup.string()
            .max(128)
            .required("Required"),
          description: Yup.string()
            .max(1000)
            .required("Required"),
          unit: Yup.string().required("Required"),
          quantityType: Yup.string().required("Required"),
          price: Yup.string().required("Required"),
          priceType: Yup.string().required("Required")
        })}
        onSubmit={async values => {
          const createTaskRequest = new CreateTaskRequest(
            values.taskGroupId,
            values.title,
            values.description,
            values.unit,
            values.quantityType,
            values.price,
            values.priceType,
            values.taskListId
          );

          setState(formStates.SUBMITTING);

          if (taskData != undefined && taskData != null) {
            const updateTaskData = new UpdateTaskRequest(
              values.taskGroupId,
              values.title,
              values.description,
              values.unit,
              values.quantityType,
              values.price,
              values.priceType,
              taskData.id
            );

            updateTaskData.id = taskData.id;

            await props.onUpdateTask(updateTaskData);

            setState(formStates.SUCCESS);

            setTimeout(() => {
              props.onNewTaskCreated();
            }, 500);
          } else {
            await props.onNewTaskCreate(createTaskRequest);
            //props.refeshTaskList(true)
            setState(formStates.SUCCESS);
            setTimeout(() => {
              props.onNewTaskCreated();
            }, 500);
          }
        }}
      >
        {(formik) => {
          return (
            <Form>
              <Flexbox
                alignItems={"center"}
                flexDirection={"column"}
                className={styles.inputSection}
              >
                <Card elevation={1} className={styles.card}>
                  <Flexbox flex={1} alignItems={"flex-start"} width={"100%"}>
                    <h3>{!props.taskData ? "Add Task" : "Edit Task"}</h3>
                  </Flexbox>
                  <Flexbox flex={1} width={"100%"}>
                    <Divider className={styles.fullWidthInput} />
                  </Flexbox>

                  {createInput("title", "Title")}
                  {createAreaInput("description", "Description")}

                  {createSuggestionInput(
                    "quantityType",
                    "Unit Type",
                    <UnitSuggest
                      units={Object.values(QuantityTypeEnum)}
                      inputName={"quantityType"}
                      selectedItem={taskData && taskData.quantityType}
                    />
                  )}
                  {createInput("unit", "Unit")}

                  {createSuggestionInput(
                    "priceType",
                    "Price Type",
                    <PriceTypeSuggest
                      priceTypes={Object.values(PriceTypeEnum)}
                      inputName={"priceType"}
                      selectedItem={taskData && taskData.priceType}
                    />
                  )}
                  {getPriceInputForPriceType(
                    formik.values.priceType,
                    formik.values.price,
                    formik.setFieldValue,
                    formik
                  )}

                  {createSuggestionInput(
                    "taskGroupId",
                    "Group",
                    <TaskGroupSuggest
                      taskGroups={taskGroups}
                      inputName={"taskGroupId"}
                      selectedItem={taskData && taskData.taskGroupId}
                    />
                  )}
                  <Flexbox justifyContent={"flex-start"}>
                    <CreateTaskGroupPopoverButton
                    latestTaskListId={props.latestTaskListId}
                      taskGroups={taskGroups}
                      onNewTaskGroupCreate={props.onNewTaskGroupCreate}
                    />
                  </Flexbox>
                </Card>

                <Flexbox flex={1} marginTop={"15px"}>
                  <Button
                    type="submit"
                    className={"bp3-large"}
                    rightIcon={
                      state === formStates.INPUT ? "arrow-right" : null
                    }
                    disabled={state !== formStates.INPUT}
                    loading={state === formStates.SUBMITTING}
                    icon={state === formStates.SUCCESS ? "saved" : null}
                  >
                    {/* {state === formStates.INPUT ? "Create" : null} */}
                    {!props.taskData ? "Create" : "Update"}
                  </Button>
                </Flexbox>
              </Flexbox>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
