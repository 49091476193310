// @flow

import type { AuthBloc } from "../../blocs/authBloc";
import { apiAxios } from "../../config/AxiosConfig";
import HomeownerApplication from "../../models/HomeownerApplication/HomeownerApplication";

class HomeownerApplicationApi {
  authBloc: AuthBloc;

  constructor(authBloc: AuthBloc) {
    this.authBloc = authBloc;
  }

  async getHomeownerApplicationForProject(
    projectId: string
  ): Promise<HomeownerApplication> {
    const tenantId = this.authBloc.getCurrentTenant();

    try {
      const response = await apiAxios.get(
        `/tenants/${tenantId}/projects/${projectId}/application`,
        this.buildHeaders()
      );

      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (error.status === 404) {
        return null;
      } else {
        console.error(error);
      }
    }
  }
  
  async uploadLogRefFile  (logRefInformation,URLresponse) {
    const file = logRefInformation
      // const tenantId = this.authBloc.getCurrentTenant();
      // let logRef = { fileType: logRefInformation.fileType, contentType: "base64" };
      const type = file.split(";")[1].split(",")[0];
      let mimeType =file.split(",") 
      const buffer = Buffer.from(
        file.replace(/^data:[a-zA-Z0\-\/]*\/\w+;base64,/, ""),
        "base64"
      );
 
      try {
        let result = await apiAxios.put(URLresponse, file, {
          headers: {
            "Content-Type": mimeType[0].match(/[^:\s*]\w+\/[\w-+\d.]+(?=[;| ])/)[0],
            "Content-Encoding": type,
          },
        });
      } catch (error) {}
    }
  

  async updateHomeownerApplicationForProject(
    projectId: string,
    logFileObject:any,
    homeownerApplication: HomeownerApplication
  ): Promise<HomeownerApplication> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.put(
        `/tenants/${tenantId}/projects/${projectId}/application`,
        homeownerApplication,
        this.buildHeaders()
      );
        
      if (response.status === 201 || response.status === 204) {
          if(logFileObject != null){
          await  this.uploadLogRefFile(logFileObject,response.data.getPutUrl)
          }
        return response.data;
      } else {
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  buildHeaders = () => {
    const accessToken = this.authBloc.getCurrentToken();
    const auth = "Bearer " + accessToken;
    return { headers: { Authorization: auth } };
  };
}

export default HomeownerApplicationApi;
