// @flow

import Keycloak, {KeycloakInstance} from "keycloak-js"
import queryString from "query-string";
import axios from 'axios';


class AuthService {

    keycloak: KeycloakInstance;

    appUrl: string = (() => {
        const appUrl = process.env.REACT_APP_URL;
        if (!appUrl) throw new Error("Missing app url");
        return appUrl;
    })();
    baseUrl: string = (() => {
        const baseUrl = process.env.REACT_APP_KEYCLOAK_SERVICE_URL;
        if (!baseUrl) throw new Error("Missing keycloak service url");
        return baseUrl;
    })();
    clientId: string = (() => {
        const clientId = process.env.REACT_APP_KEYCLOAK_CLIENT_ID;
        if (!clientId) throw new Error("Missing keycloak client id");
        return clientId;
    })();
    realm: string = (() => {
        const realm = process.env.REACT_APP_KEYCLOAK_REALM;
        if (!realm) throw new Error("Missing keycloak realm");
        return realm;
    })();
    service_id: string = (() => {
        const service_id = process.env.REACT_APP_KEYCLOAK_SERVICE_ID;
        if (!service_id) throw new Error("Missing keycloak service id");
        return service_id;
    })();
    resource_server_id: string = (() => {
        const service_id = process.env.REACT_APP_KEYCLOAK_RESOURCE_SERVER_ID;
        if (!service_id) throw new Error("Missing keycloak resource server id");
        return service_id;
    })();

    constructor() {
        this.keycloak = this.createKeycloak();
    }

    createKeycloak(){
        const keycloak = Keycloak({
            url: `${this.baseUrl}/auth`,
            realm: this.realm,
            clientId: this.clientId,
        });
        keycloak.onTokenExpired = async () => {
            await this.keycloak.updateToken();
        };
        return keycloak;
    }

    initialize() : Promise<boolean>{
        return this.keycloak.init({promiseType: 'native', onLoad: 'check-sso',})
    }

    login = (): Promise<void> => {
            return this.keycloak.login({redirectUri: `${this.appUrl}/auth/callback`})
    };

    logout = (): Promise<void> => {
        return this.keycloak.logout()
    };

    fetchPermissions = async (): Promise<void> => {
        try {
            const permissionsResponse = await axios.post(`${this.baseUrl}/auth/realms/${this.realm}/protocol/openid-connect/token`,
                queryString.stringify({
                    grant_type: "urn:ietf:params:oauth:grant-type:uma-ticket",
                    audience: `${this.resource_server_id}`
                }), {
                    headers: {
                        'Authorization': `Bearer ${this.keycloak.token}`,
                        'Content-Type': 'application/x-www-form-urlencoded',
                    }
                });
            const auth = await axios.post(`${this.baseUrl}/auth/realms/${this.realm}/protocol/openid-connect/token`,
                queryString.stringify({
                    grant_type: "refresh_token",
                    client_id: `${this.clientId}`,
                    refresh_token: `${permissionsResponse.data.refresh_token}`
                }));
            this.keycloak = this.createKeycloak();
            console.log("x",this.keycloak.init({promiseType: 'native', onLoad: 'check-sso', token: auth.data.access_token, refreshToken: auth.data.refresh_token}));
            return this.keycloak.init({promiseType: 'native', onLoad: 'check-sso', token: auth.data.access_token, refreshToken: auth.data.refresh_token})
        }
        catch(err){
            throw err;
        }
    }
}

export default new AuthService();
