// @flow

import type {AuthBloc} from "../../blocs/authBloc";
import {apiAxios} from '../../config/AxiosConfig'
import type AssignedTask from "../../models/AssignedTask/AssignedTask";
import {CreateAssignedTaskRequest, UpdateAssignedTaskRequest} from "../../models/AssignedTask/AssignedTask";


class AssignedTaskApi {

    authBloc : AuthBloc;

    constructor(authBloc: AuthBloc) {
        this.authBloc = authBloc;
    }

    async getAssignedTasksForProject(projectId: string) : Promise<Array<AssignedTask>>{
        const tenantId = this.authBloc.getCurrentTenant();

        const response = await apiAxios.get(`/tenants/${tenantId}/projects/${projectId}/assigned-tasks`, this.buildHeaders());

        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.status);
        }
    }

    async createTask (projectId: string, createAssignedTaskRequest: CreateAssignedTaskRequest) : Promise<AssignedTask> {
        const tenantId = this.authBloc.getCurrentTenant();

        const response = await apiAxios.post(`/tenants/${tenantId}/projects/${projectId}/assigned-tasks`, createAssignedTaskRequest, this.buildHeaders());

        if (response.status === 201) {
            return response.data;
        } else {
            throw new Error(response.status);
        }
    };

    async updateTask (projectId: string, assignedTaskId: string, updateAssignedTask: UpdateAssignedTaskRequest) : Promise<void> {
        const tenantId = this.authBloc.getCurrentTenant();

        const response = await apiAxios.patch(`/tenants/${tenantId}/projects/${projectId}/assigned-tasks/${assignedTaskId}`, updateAssignedTask, this.buildHeaders());

        if (response.status === 204) {
            return response.data;
        } else {
            throw new Error(response.status);
        }
    };

    async deleteTask (projectId: string, assignedTaskId: string) : Promise<void> {
        const tenantId = this.authBloc.getCurrentTenant();

        const response = await apiAxios.delete(`/tenants/${tenantId}/projects/${projectId}/assigned-tasks/${assignedTaskId}`, this.buildHeaders());

        if (response.status === 204) {
            return response.data;
        } else {
            throw new Error(response.status);
        }
    };

    buildHeaders = ()=> {
        const accessToken = this.authBloc.getCurrentToken();
        const auth = "Bearer " + accessToken;
        return {headers:{Authorization:auth}};
    }
}

export default AssignedTaskApi;
