import NotesApi from "../../api/NotesApi/NotesApi";
import ProjectGroupService from "../ProjectGroupService/ProjectGroupService";
import AttachmentStorageApi from "../../api/AttachmentApi/AttachmentStorageApi";
import type AttachmentApi from "../../api/AttachmentApi/AttachmentApi";
import { apiAxios } from '../../config/AxiosConfig'
class NotesService {
  notesApi: NotesApi;
  projectGroupService: ProjectGroupService;
  attachmentStorageApi: AttachmentStorageApi;
  attachmentApi: AttachmentApi;

  constructor(
    notesApi: NotesApi,
    projectGroupService: ProjectGroupService,
    attachmentStorageApi: AttachmentStorageApi,
    attachmentApi: AttachmentApi,
  ) {
    this.notesApi = notesApi;
    this.projectGroupService = projectGroupService;
    this.attachmentStorageApi = attachmentStorageApi;
    this.attachmentApi = attachmentApi;
  }

  async getAllNotes(metaData: any): Promise<Array> {
    return await this.notesApi.getAll(metaData);
  }

  async createNote(createNoteRequest): Promise<any> {
    const note = await this.notesApi.createNote(createNoteRequest);
    return note;
  }

  async addAttachments(createNoteRequest, attachmentStore): Promise<any> {
    const attachments = await this.notesApi.addAttachments(createNoteRequest);

    for (const [index, img] of attachmentStore.entries()) {
      const file = img?.payload;
      const type = file.split(';')[1].split(',')[0];
      const buffer = Buffer.from(file.replace(/^data:[a-zA-Z0\-\/]*\/\w+;base64,/, ""), 'base64');
      const result = attachments?.find(obj => {
        return obj.title === img.title
      })
      try {
        await apiAxios.put(result?.putUrl, buffer, {
          headers: {
            'Content-Type': img?.fileType,
            'Content-Encoding': type
          },
        });
      } catch (error) { }
    }
    return attachments;
  }

  async updateNote(updateNoteRequest, noteId): Promise<any> {
    const note = await this.notesApi.updateNote(updateNoteRequest, noteId);
    return note;
  }

  async exportNotes(exportNotesRequest): Promise<any> {
    const notes = await this.notesApi.exportNotes(exportNotesRequest);
    return notes;
  }
  
  async deleteNote(noteId): Promise<any> {
    const note = await this.notesApi.deleteNote(noteId);
    return note;
  }
}

export default NotesService;
