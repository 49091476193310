// @flow

import React, { useEffect, useContext, useState } from "react";
import Flexbox from "flexbox-react";
import {
  Button,
  Intent,
  Spinner,
  Tag,
  Popover,
  Position,
  InputGroup,
  Classes,
  Card,
} from "@blueprintjs/core";
import styles from "./ProjectMilestoneManager.module.css";
import moment from "moment";
import { ProjectStatuses } from "../../models/Project/Project";
import { AuthRoles } from "../../blocs/authBloc";
import DeleteConfirmationPopup from "../ConfirmationAlert/ConfirmationAlert";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { DatePicker3 } from "@blueprintjs/datetime2";
import { DatePicker } from "@blueprintjs/datetime";
import BlocRegistry from "../../blocs/registry";
import type { Subscription } from "rxjs";
import {
  ProjectContext,
  ProjectStates,
  UpdateCustomMilestoneEvent,
  CustomMilestoneUpdateDateEvent,
} from "../../blocs/projectBloc";
import type {
  Project,
  ProjectStatusesTypes,
  ProjectStatesTypes,
  ProjectStates as ProjectStatesString,
} from "../../models/Project/Project";

type Props = {
  project: ?Project,
  onProjectMilestoneUpdate: (
    project: Project,
    newProjectStatus: ProjectStatusesTypes
  ) => any,
};

let milestones = {
  milestoneProjectCreated: {
    text: "Created",
    status: ProjectStatuses.CREATED,
    button: false,
    input: false,
  },
  milestoneHomeownerAppStarted: {
    text: "Homeowner App Started",
    status: ProjectStatuses.APP_STARTED,
    button: false,
    input: false,
  },
  milestoneHomeownerAppSubmitted: {
    text: "Homeowner App Submitted",
    status: ProjectStatuses.APP_SUBMITTED,
    button: false,
    input: false,
  },

  milestoneHomeownerAppApproved: {
    text: "Homeowner App Approved",
    status: ProjectStatuses.APP_APPROVED,
    button: false,
    input: false,
  },
  milestoneSelectionCommitteeApproved: {
    text: "Selection Committee Approval Date",
    status: ProjectStatuses.SELECTION_COMMITTEE_APPROVED,
    input: false,
    button: false,
  },
  milestoneHomeownerAppDenied: {
    text: "Homeowner App Denied",
    status: ProjectStatuses.APP_DENIED,
    button: false,
    input: false,
  },
  milestoneInitialAssessment: {
    text: "Initial Assessment Date",
    status: ProjectStatuses.INITIAL_ASSESSMENT,
    input: false,
  },

  milestoneSurveyCompleted: {
    text: "Survey Completed",
    status: ProjectStatuses.SURVEY_COMPLETED,
    input: false,
  },
  milestoneErSubmitted: {
    text: "ER Submitted",
    status: ProjectStatuses.ER_SUBMITTED,
    input: false,
  },
  milestoneErReleased: {
    text: "ER Released",
    status: ProjectStatuses.ER_RELEASED,
    input: false,
  },
  milestoneRiskAssessmentSubmitted: {
    text: "Lead Risk Assessment Submitted",
    status: ProjectStatuses.RISK_ASSESSMENT_SUBMITTED,
    input: false,
  },
  milestoneVendorsSelected: {
    text: "Vendor(s) Selected",
    status: ProjectStatuses.VENDORS_SELECTED,
    input: false,
  },
  milestoneNewMilestone1: {
    text: "",
    status: ProjectStatuses.NEW_MILESTONE1,
    input: true,
    index: 1,
  },
  milestoneWorkStarted: {
    text: "Work Started",
    status: ProjectStatuses.WORK_STARTED,
    input: false,
  },
  milestoneNewMilestone2: {
    text: "",
    status: ProjectStatuses.NEW_MILESTONE2,
    input: true,
    index: 2,
  },
  milestoneWorkCompleted: {
    text: "Work Completed",
    status: ProjectStatuses.WORK_COMPLETED,
    input: false,
  },
  milestoneInvoicesSubmitted: {
    text: "Invoice(s) Submitted",
    status: ProjectStatuses.INVOICES_SUBMITTED,
    input: false,
  },
  milestoneInvoicesPaid: {
    text: "Invoice(s) Paid",
    status: ProjectStatuses.INVOICES_PAID,
    input: false,
  },
  milestoneReimbursementSubmitted: {
    text: "Reimbursement Submitted",
    status: ProjectStatuses.REIMBURSEMENT_SUBMITTED,
    input: false,
  },
  milestoneReimbursementReceived: {
    text: "Reimbursement Recieved",
    status: ProjectStatuses.REIMBURSEMENT_RECEIVED,
    input: false,
  },
  milestoneInvoicesSubmitted: {
    text: "Invoice(s) Submitted",
    status: ProjectStatuses.INVOICES_SUBMITTED,
    input: false,
  },
  milestoneInvoicesPaid: {
    text: "Invoice(s) Paid",
    status: ProjectStatuses.INVOICES_PAID,
    input: false,
  },
  milestoneReimbursementSubmitted: {
    text: "Reimbursement Submitted",
    status: ProjectStatuses.REIMBURSEMENT_SUBMITTED,
    input: false,
  },
  milestoneReimbursementReceived: {
    text: "Reimbursement Received",
    status: ProjectStatuses.REIMBURSEMENT_RECEIVED,
    input: false,
  },
  milestoneProjectCompleted: {
    text: "Project Completed",
    status: ProjectStatuses.COMPLETED,
    input: false,
  },
  milestoneHfhiReported: {
    text: "Report to Habitat for Humanity International",
    status: ProjectStatuses.HFHI_REPORTED,
    input: false,
  },
};
const formStates = {
  INPUT: "INPUT",
  SUBMITTING: "SUBMITTING",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
};

export default function ProjectMilestoneManager(props: Props) {
  const { projectBloc } = useContext(BlocRegistry);
  const { project, onProjectMilestoneUpdate, onCloseProjectButtonClick } =
    props;

  const [isConfirmOpen, setConfirmOpen] = useState(false);

  const [currentMileStone, setCurrentMilestone] = useState(null);
  const [milestoneInput, setMilestoneInput] = useState([]);
  const [showConfirmationPopup, setConfirmationPopup] = useState(false);
  const [shouldReload, setShouldReload] = useState(true);

  const projectEventHandler = {
    next(projectContext: ProjectContext) {
      switch (projectContext.state) {
        case ProjectStates.UNINITIALIZED:
        case ProjectStates.INITIALIZING: {
          break;
        }
      }
    },
    error(err: Error) {
      throw err;
    },
  };

  const handleCustomMilestoneUpdate = (
    newStatus: ProjectStatusesTypes,
    dateStamp: any,
    milestoneLabel: string
  ) => {
    let projObj;
    projObj = {
      status: newStatus,
      dateTimeStamp: dateStamp,
      milestoneName: milestoneLabel
    }
    projectBloc.sendEvent(
      new CustomMilestoneUpdateDateEvent(projObj, project.id)
    );
  };

  useEffect(() => {
    const subscription: Subscription =
      projectBloc.subscribeToProjectContext(projectEventHandler);
    return () => {
      subscription.unsubscribe();
    };
  }, [projectBloc]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const hasReloaded = localStorage.getItem("hasReloaded");

    if (!shouldReload) {
      // Reload the page only once when the component mounts
      setShouldReload(false);
      window.location.reload();
    }
  }, [shouldReload]);

  const getCustomMilestoneLabel = (milestones, indexId) => {
    let currentLabel = milestones?.find((item) => item?.index == indexId)
    return currentLabel?.milestoneName ? currentLabel?.milestoneName : "";
  }

  const getCustomMilestoneDate = (milestones, indexId) => {
    let currentDate = milestones?.find((item) => item?.index == indexId)
    return currentDate?.milestoneDate ? moment(currentDate?.milestoneDate).toDate() : null;
  }

  // const [value, onChange] = useState<Value>(new Date());
  const [selectedDate, setSelectedDate] = useState(() => {
    let initialDates = {};

    for (let milestone of Object.keys(milestones)) {
      let initialDate = null;

      if (milestone === "milestoneProjectCreated") {
        if (project?.created_at) {
          initialDate = moment(project.created_at).toDate();
        }
      } else if (milestone === 'milestoneNewMilestone1') {
        initialDate = project?.customMilestone?.length ? getCustomMilestoneDate(project?.customMilestone, '1') : null;
      } else if (milestone === 'milestoneNewMilestone2') {
        initialDate = project?.customMilestone?.length ? getCustomMilestoneDate(project?.customMilestone, '2') : null;
      } else {
        let timestamp = project?.[milestone];
        if (timestamp) {
          initialDate = moment(timestamp).toDate();
        }
      }

      initialDates[milestone] = initialDate;
    }

    return initialDates;
  });

  const confirmPopup = (
    <DeleteConfirmationPopup
      isOpen={showConfirmationPopup}
      cancelButtonText="Cancel"
      confirmButtonText="Save"
      icon="saved"
      type={Intent.PRIMARY}
      onCancel={() => setConfirmationPopup(false)}
      onConfirm={async () => {
        if (
          milestoneInput.hasOwnProperty("milestoneNewMilestone1") &&
          milestoneInput["milestoneNewMilestone1"] != ""
        ) {
          let customMilestones = {
            "customMilestones": [
              {
                "milestoneName": milestoneInput["milestoneNewMilestone1"],
                "index": 1
              }
            ]
          };
          projectBloc.sendEvent(
            new UpdateCustomMilestoneEvent(customMilestones, project.id)
          );
        }
        if (
          milestoneInput.hasOwnProperty("milestoneNewMilestone2") &&
          milestoneInput["milestoneNewMilestone2"] != ""
        ) {
          let customMilestones = {
            "customMilestones": [
              {
                "milestoneName": milestoneInput["milestoneNewMilestone2"],
                "index": 2
              }
            ]
          };
          projectBloc.sendEvent(
            new UpdateCustomMilestoneEvent(customMilestones, project.id)
          );
        }
        setConfirmationPopup(false);
      }}
      message="This action cannot be undone, Are you sure you want to save the milestone label?"
    />
  );
  let milestoneList = [<Spinner />];

  if (project) {
    milestoneList = [];
    for (let milestone of Object.keys(milestones)) {
      let m;
      if (milestone === "milestoneProjectCreated")
        m = new moment(project.created_at);
      else {
        let timestamp = project[milestone];
        if (timestamp) m = new moment(timestamp);
      }

      milestones["milestoneNewMilestone1"].text = "";
      milestones["milestoneNewMilestone1"].input = true;
      milestones["milestoneNewMilestone2"].text = "";
      milestones["milestoneNewMilestone2"].input = true;
      if (project?.customMilestone?.length) {
        milestones["milestoneNewMilestone1"].text = getCustomMilestoneLabel(project?.customMilestone, '1');
        milestones["milestoneNewMilestone1"].text && (milestones["milestoneNewMilestone1"].input = false);
        milestones["milestoneNewMilestone2"].text = getCustomMilestoneLabel(project?.customMilestone, '2');
        milestones["milestoneNewMilestone2"].text && (milestones["milestoneNewMilestone2"].input = false);

      }

      milestoneList.push(
        <Flexbox padding={"10px"} width={"100%"}>
          <Flexbox flex={2}>
            <h6>
              {milestones[milestone].input == false ? (
                `${milestones[milestone].text}:`
              ) : (
                <div>
                  <Formik
                    initialValues={{
                      milestoneNewMilestone1: "",
                      milestoneNewMilestone2: "",
                    }}
                    validationSchema={Yup.object({
                      milestoneNewMilestone1: Yup.string(),
                      milestoneNewMilestone2: Yup.string(),
                    })}
                    onSubmit={(values, { resetForm }) => {
                      console.log("formik values", values);
                      // resetForm({
                      //   milestoneNewMilestone1: "",
                      //   milestoneNewMilestone2: "",
                      // });
                      setMilestoneInput(values);
                    }}
                  >
                    {(formik) => {
                      return (
                        <Form>
                          <Flexbox
                            alignItems={"center"}
                            flexDirection={"row"}
                            className={styles.inputSection}
                          >
                            {/* <Flexbox flex={1} > */}
                            <Field
                              className={`bp3-large`}
                              as={InputGroup}
                              name={milestone}
                              type="text"
                              fill
                              disabled= {
                                AuthRoles.COMPLIANCE_AUDITOR === props.currentUserRole
                              }
                            />
                            {/* </Flexbox> */}
                            <Button
                              large
                              //text="Save"
                              type="submit"
                              icon="saved"
                              disabled= {
                                AuthRoles.COMPLIANCE_AUDITOR === props.currentUserRole
                              }
                              onClick={() => {
                                console.log(
                                  "Save clicked",
                                  milestones[milestone],
                                  milestoneInput
                                );
                                setConfirmationPopup(true);
                              }}
                            />
                          </Flexbox>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              )}
            </h6>
          </Flexbox>
          <Flexbox flex={3}>
            {selectedDate[milestone] ? (
              <Tag multiline intent={Intent.PRIMARY}>
                {/* {m.format("LLLL")} */}
                {moment(selectedDate[milestone]).format("LLLL")}
              </Tag>
            ) : (
              <Tag minimal={true}>-</Tag>
            )}
          </Flexbox>
          <Flexbox alignItems={"center"} justifyContent={"center"} flex={1}>
            {/* {milestones[milestone].button !== false && ( */}
            {milestones[milestone].status !== "CREATED" && (
              <Flexbox>
                <Button
                  large
                  icon={!selectedDate[milestone] ? "endorsed" : "edit"}
                  onClick={() => {
                    setCurrentMilestone(milestone);
                    setConfirmOpen(true);
                  }}
                  disabled={
                    AuthRoles.COMPLIANCE_AUDITOR === props.currentUserRole
                  }
                />
              </Flexbox>
            )}
            {/* )} */}
            <Popover
              position={Position.RIGHT}
              isOpen={isConfirmOpen && currentMileStone === milestone}
              onClose={() => {
                setConfirmOpen(false);
              }}
              content={
                <Flexbox>
                  <Card className="example-card">
                    <DatePicker
                      timePrecision="minute"
                      timePickerProps={{ useAmPm: true }}
                      // footerElement={this.state.showFooterElement ? exampleFooterElement : undefined}
                      onChange={(date) => {
                        // onProjectMilestoneUpdate(
                        //   project,
                        //   milestones[currentMileStone].status,
                        //   props.assignedTasks[props.assignedTasks?.length - 1]
                        //     ?.taskList?.id
                        // );
                        // setConfirmOpen(false);
                        setSelectedDate((prevDates) => ({
                          ...prevDates,
                          [milestone]: date,
                        }));
                      }}
                      value={selectedDate[milestone]}
                    />
                    <Button
                      onClick={() => {
                        console.log("Project Object", project);
                        if (milestones[currentMileStone]?.status == ProjectStatuses.NEW_MILESTONE1 || milestones[currentMileStone]?.status == ProjectStatuses.NEW_MILESTONE2) {
                          handleCustomMilestoneUpdate(
                            milestones[currentMileStone].status,
                            selectedDate[milestone],
                            milestones[currentMileStone].text
                          );
                        } else {
                          onProjectMilestoneUpdate(
                            project,
                            milestones[currentMileStone].status,
                            selectedDate[milestone],
                            props.assignedTasks[props.assignedTasks?.length - 1]
                              ?.taskList?.id
                          );
                        }

                        setConfirmOpen(false);
                      }}
                      className={styles.save_btn}
                      text="Save"
                    />
                  </Card>
                  {/* <Button
                    icon={"cross"}
                    text={"Cancel"}
                    minimal
                    icon={"cross"}
                    text={"Cancel"}
                    minimal
                    onClick={() => {
                      setConfirmOpen(false);
                    }}
                  />
                  <Button
                    icon={"confirm"}
                    text={"Confirm"}
                    intent={Intent.WARNING}
                    onClick={() => {
                      onProjectMilestoneUpdate(
                        project,
                        milestones[currentMileStone].status,
                        props.assignedTasks[props.assignedTasks?.length - 1]
                          ?.taskList?.id
                      );
                      setConfirmOpen(false);
                    }}
                  /> */}
                </Flexbox>
              }
            >
              <></>
            </Popover>
          </Flexbox>
        </Flexbox>
      );
    }
  }

  return (
    <Flexbox
      width={"100%"}
      height={"100%"}
      justifyContent={"center"}
      alignItems={"center"}
      padding={"20px"}
      flexDirection={"column"}
      className={styles.teamViewContainer}
    >
      <h5>Milestones</h5>
      <Flexbox
        maxWidth={"1000px"}
        justifyContent={"flex-start"}
        flexDirection={"column"}
        width={"100%"}
        paddingBottom={"25px"}
      >
        {milestoneList}
        {confirmPopup}

        <Flexbox>
          <Flexbox padding={"2px"}></Flexbox>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  );
}
