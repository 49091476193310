// @flow

import type {AuthBloc} from "../../blocs/authBloc";
import type TaskGroup from "../../models/TaskGroup/TaskGroup";
import {apiAxios} from '../../config/AxiosConfig'
import {CreateTaskGroupRequest} from "../../models/TaskGroup/TaskGroup";


class TaskGroupApi {

    authBloc : AuthBloc;

    constructor(authBloc: AuthBloc) {
        this.authBloc = authBloc;
    }

    async getAll(taskListId) : Promise<Array<TaskGroup>>{
        const tenantId = this.authBloc.getCurrentTenant();

        const response = await apiAxios.get(`/tenants/${tenantId}/list/${taskListId}/task-groups`, this.buildHeaders());
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error(response.status);
        }
    }

    async createTaskGroup (createTaskGroupRequest: CreateTaskGroupRequest) : Promise<TaskGroup> {
        const tenantId = this.authBloc.getCurrentTenant();

        const response = await apiAxios.post(`/tenants/${tenantId}/task-groups`, createTaskGroupRequest, this.buildHeaders());

        if (response.status === 201) {
            return response.data;
        } else {
            throw new Error(response.status);
        }
    };

    buildHeaders = ()=> {
        const accessToken = this.authBloc.getCurrentToken();
        const auth = "Bearer " + accessToken;
        return {headers:{Authorization:auth}};
    }
}

export default TaskGroupApi;
