//@flow

import React, { useState, useEffect, useContext } from "react";
import { Column, Table, TableLoadingOption } from "@blueprintjs/table";
import Invoice, { UpdateInvoiceRequest } from "../../models/Invoice/Invoice";
import Flexbox from "flexbox-react";
import {
  Button,
  Dialog,
  Card,
  Classes,
  Position,
  Text,
  Intent,
  Tag,
} from "@blueprintjs/core";
import styles from "./ManageInvoicesView.module.css";
import CreateInvoiceRequestBuilder from "../../components/CreateInvoiceRequestBuilder/CreateInvoiceRequestBuilder";
import Vendor from "../../models/Vendor/Vendor";
import Project, { ProjectStatuses } from "../../models/Project/Project";
import InvoiceTable from "../../components/InvoiceTable/InvoiceTable";
import BlocRegistry from "../../blocs/registry";
import {
  InvoiceOutEventTypesEnum as InvoiceOutEvent,
  FetchInvoicesForProjectEvent,
  InvoiceOutEventTypes,
  CreateInvoiceForProjectEvent,
  DeleteInvoiceForProjectEvent,
  UpdateInvoiceForProjectEvent,
} from "../../blocs/projectInvoiceBloc";
import { AttachmentTypeEnum } from "../../models/Attachment/Attachment";
import DeleteConfirmationPopup from "../../components/ConfirmationAlert/ConfirmationAlert";
import InvoiceStatus from "../../components/InvoiceStatus/InvoiceStatus";
import type { ProjectStatesTypes } from "../../blocs/projectBloc";
import axios from "axios";
import mime from "mime-types";
import FileSaver from "file-saver";
import moment from "moment";
import { AppToaster } from "../../components/Toaster/Toaster";
import { AuthRoles } from "../../blocs/authBloc";

type Props = {
  project: ?Project,
  invoices: Array<Invoice>,
  vendors: Array<Vendor>,
  onAddVendorButtonClick: () => any,
  onProjectMilestoneUpdate: (
    project: Project,
    newStatus: ProjectStatesTypes
  ) => any,
};

function ManageInvoicesView(props: Props) {
  const { invoiceBloc } = useContext(BlocRegistry);

  const [isOpen, setOpen] = useState(false);
  const [showConfirmationPopup, setConfirmationPopup] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);
  const [statusOpen, setStatusOpen] = useState(false);
  const [updateInvoice, setUpdateInvoice] = useState(null);

  const [invoices, setInvoices] = useState([]);
  const [lastBlocEvent, setLastBlocEvent] = useState(null);

  const {
    project,
    vendors,
    onAddVendorButtonClick,
    onProjectMilestoneUpdate,
  } = props;

  useEffect(() => {
    const subscription: Subscription = invoiceBloc.subscribeToInvoiceContext({
      next(event: InvoiceOutEventTypes) {
        setLastBlocEvent(event);
      },
      error(err: Error) {
        throw err;
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [invoiceBloc]);

  useEffect(() => {
    if (!lastBlocEvent) return;
   
    switch (lastBlocEvent.type) {
      case InvoiceOutEvent.FETCHED: {
        setInvoices(lastBlocEvent.invoices);
        break;
      }
      case InvoiceOutEvent.DELETED:
      case InvoiceOutEvent.CREATED: {
        invoiceBloc.sendEvent(
          new FetchInvoicesForProjectEvent(
            project.id,
            AttachmentTypeEnum.invoice
          )
        );
        break;
      }
      case InvoiceOutEvent.UPDATED: {
        invoiceBloc.sendEvent(
          new FetchInvoicesForProjectEvent(
            project.id,
            AttachmentTypeEnum.invoice
          )
        );
        break;
      }
      default: {
        console.error(
          "Unknown homeowner application out event type: " + lastBlocEvent.type
        );
      }
    }
  }, [lastBlocEvent]);

  useEffect(() => {
    if (!project) return;
    invoiceBloc.sendEvent(
      new FetchInvoicesForProjectEvent(project.id, AttachmentTypeEnum.invoice)
    );
  }, [project]);

  const handleCreateInvoice = (request) => {
    invoiceBloc.sendEvent(
      new CreateInvoiceForProjectEvent(AttachmentTypeEnum.invoice, request)
    );
    setOpen(false);
  };

  const handleDeleteInvoice = (invoiceId) => {
    setConfirmationPopup(true);
    setInvoiceId(invoiceId);
  };

  const handleEditInvoiceMenuClick = (invoice: Invoice) => {
   
    setUpdateInvoice(invoice);
    setOpen(true);
  };

  const handleUpdateInvoice = (request: UpdateInvoiceRequest) => {
   
    invoiceBloc.sendEvent(
      new UpdateInvoiceForProjectEvent(AttachmentTypeEnum.invoice, request)
    );
    setOpen(false);
  };

  const handleInvoiceDownload = async (request: Invoice) => {
    if (!request.attachment) {
      AppToaster.show({
        message: "File Not uploaded in this Invoice",
        intent: Intent.DANGER,
      });
      return;
    } else {
      const encodedString = (await axios.get(request.attachment.getUrl)).data;
      const base = encodedString.split(",")[1];
      const type = encodedString.split(";")[0].split("/")[1];
      const fileType = request.attachment.fileType;
      const extension = mime.extension(fileType);

      const byteString = atob(base);

      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      let filename = `invoice_${project.home.address.streetAddress}_${
        request.vendor.name
      }_${moment(request.invoiceDate).format("ll")}.${extension}`;

      const file = new File([ab], filename, { type: type });

      FileSaver.saveAs(file);
    }
  };

 const handleViewInvoice = async (request: Invoice) => {
  
  if (!request.attachment) {
    AppToaster.show({
      message: "File Not uploaded in this Invoice",
      intent: Intent.DANGER,
    });
    return;
  } else {
    const encodedString = (await axios.get(request.attachment.getUrl)).data;

    const base = encodedString.split(",")[1];
    const type = encodedString.split(";")[0].split("/")[1];
    const fileType = request.attachment.fileType;
    const extension = mime.extension(fileType);

    const byteString = atob(base);

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    let filename = `invoice_${project.home.address.streetAddress}_${
      request.vendor.name
    }_${moment(request.invoiceDate).format("ll")}.${extension}`;

    const file = new File([ab], filename, { type: type });

   

    const imageFiles = ["jpg","jpeg","png","gif","webp","tiff","psd","svg"]

    if(extension === "pdf"){

      let w = window.open("","_blank");
      var x = document.createElement("IFRAME");
      x.setAttribute("src", encodedString);

      x.width = "100%";
      x.height = "100%";

        w.document.write(x.outerHTML);
      
    }
    else if(imageFiles.includes(extension)){
      let image = new Image();
        image.src = encodedString;

        let w = window.open("","_blank");
        w.document.write(image.outerHTML);
    }
    else{
      AppToaster.show({
        message: "Only images and pdf files can be viewed",
        intent: Intent.DANGER,
      });
    
    }
  }

  }

  const findInvoiceStatusByMilestone = (project: Project) => {
    if (!project) return "";

    switch (project.status) {
      case ProjectStatuses.INVOICES_SUBMITTED:
        return "Invoices Submitted";

      case ProjectStatuses.INVOICES_PAID:
        return "Invoices Paid";

      case ProjectStatuses.REIMBURSEMENT_SUBMITTED:
        return "Reimbursement Submitted";

      case ProjectStatuses.REIMBURSEMENT_RECEIVED:
        return "Reimbursement Received";

      default:
        return "Collecting Invoices";
    }
  };

  let list = (
    <Table
      numRows={10}
      loadingOptions={[
        TableLoadingOption.CELLS,
        TableLoadingOption.ROW_HEADERS,
      ]}
    >
      <Column name="Status" />
      <Column name="Vendor" />
      <Column name="Amount" />
      <Column name="Invoice" />
      <Column name="Due" />
      <Column name="Paid" />
      <Column name="Reimb. Submitted" />
      <Column name="Reimb. Received" />
      <Column name="Notes" />
      <Column name="Budget Vs Actual" />
      <Column name="Explanation" />
    </Table>
  );

  if (project && invoices) {
    if (invoices.length === 0)
      list = (
        <div
          className={`bp3-ui-text bp3-text-disabled ${styles.noProjectGroupsText}`}
        >
          no invoices
        </div>
      );
    else {
      list = (
        <InvoiceTable
          invoices={invoices}
          onInvoiceDelete={handleDeleteInvoice}
          onEditInvoiceMenuClick={handleEditInvoiceMenuClick}
          onInvoiceDownload={handleInvoiceDownload}
          onViewInvoice = {handleViewInvoice}
          currentUserRole={props.currentUserRole}
        />
      );
    }
  }

  const createInvoicePopup = (
    <Dialog
      isOpen={isOpen}
      canOutsideClickClose={false}
      icon="application"
      onClose={() => setOpen(false)}
      title={updateInvoice ? "Update Invoice" : "Add Invoice"}
    >
      <div className={Classes.DIALOG_BODY}>
        <Card>
          <CreateInvoiceRequestBuilder
            vendors={vendors}
            invoiceData={updateInvoice}
            onAddVendorButtonClick={onAddVendorButtonClick}
            project={project}
            onCreateInvoice={handleCreateInvoice}
            onUpdateInvoice={handleUpdateInvoice}
          />
        </Card>
      </div>
    </Dialog>
  );

  const invoiceStatusPopup = (
    <Dialog
      isOpen={statusOpen}
      canOutsideClickClose={false}
      icon="endorsed"
      onClose={() => setStatusOpen(false)}
      title="Update Status"
    >
      <div className={Classes.DIALOG_BODY}>
        <Card>
          <InvoiceStatus
            onProjectMilestoneUpdate={onProjectMilestoneUpdate}
            project={project}
          />
        </Card>
      </div>
    </Dialog>
  );

  const deleteConfirmPopup = (
    <DeleteConfirmationPopup
      isOpen={showConfirmationPopup}
      cancelButtonText="Cancel"
      confirmButtonText="Delete"
      icon="trash"
      type={Intent.DANGER}
      onCancel={() => setConfirmationPopup(false)}
      onConfirm={async () => {
        await invoiceBloc.sendEvent(
          new DeleteInvoiceForProjectEvent(
            project.id,
            AttachmentTypeEnum.invoice,
            invoiceId
          )
        );
        setConfirmationPopup(false);
      }}
      message="Are you sure you want to delete this Invoice?"
    />
  );

  return (
    <div className={styles.manageInvoicesView} id="mainHeader">
      <h5>Invoices</h5>
      <Flexbox>
        <Flexbox justifyContent={"flex-start"} flexGrow={2} style={{ borderBottom: 2 }}>
         {AuthRoles.COMPLIANCE_AUDITOR !== props.currentUserRole && <Button
            text={"Add Invoice"}
            icon={"plus"}
            loading={!invoices}
            minimal
            onClick={() => {
              setOpen(true);
              setUpdateInvoice(null);
            }}
          />}
        </Flexbox>
        <Flexbox flexGrow={2}>
          <label
            style={{
              fontSize: 14,
              marginTop: 3,
              alignSelf: "center",
            }}
          >
            Total Amount:{" "}
            $ {Number((invoices?.reduce((sum, invoice) => sum + invoice?.amount, 0)).toFixed(1)).toLocaleString()}
          </label>
        </Flexbox>
        <Flexbox flexGrow={2} x></Flexbox>
        {invoices.length && (
          <Flexbox alignItems={"flex-end"} flexGrow={2}>
            <Button minimal intent={Intent.PRIMARY} icon={"info-sign"}>
              Status: {findInvoiceStatusByMilestone(project)}
            </Button>
            <Button
              // intent={Intent.PRIMARY}
              text={"Update Status"}
              icon={"endorsed"}
              disabled={AuthRoles.COMPLIANCE_AUDITOR === props.currentUserRole}
              onClick={() => {
                setStatusOpen(true);
              }}
            />
          </Flexbox>
        )}
      </Flexbox>
      <br />
      {invoiceStatusPopup}
      {list}
      {createInvoicePopup}
      {deleteConfirmPopup}
    </div>
  );
}

export default ManageInvoicesView;
