//@flow

import styles from "./ProjectPhotosGrid.module.css";
import React, { useState, useEffect } from "react";
import Flexbox from "flexbox-react";
import { Responsive, WidthProvider } from "react-grid-layout";
import {
  Button,
  Card,
  InputGroup,
  Intent,
  Overlay,
  Popover,
  Spinner,
  Tag,
  TextArea,
  FileInput,
  H5,
  H6,
  Checkbox,
  Menu,
  MenuItem,
  Classes,
  AnchorButton,
  ButtonGroup,
  Icon,
} from "@blueprintjs/core";
import { Utils } from "@blueprintjs/table/lib/esnext/common/utils";
import {
  AttachmentTypeEnum,
  CreateAttachmentRequest,
  GetAttachmentResponse,
} from "../../models/Attachment/Attachment";
import FullscreenCamera from "../FullscreenCamera/FullscreenCamera";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";
import { Base64 } from "js-base64";
import axios from "axios";
import mime from "mime-types";
import { DeleteAttachmentForProjectEvent } from "../../blocs/projectAttachmentBloc";
import PhotoOptionOverlay from "../PhotoOptionOverlay/PhotoOptionOverlay";
import { PhotoPreview } from "../PhotoPreview/PhotoPreview";
import { AppToaster } from "../Toaster/Toaster";
import Project from "../../models/Project/Project";
import Compressor from "compressorjs";
import { GlobalConstanst } from "../../config/Constant";
import Dropzone from "react-dropzone";
import {
  Column,
  Table,
  TableLoadingOption,
  SelectionModes,
} from "@blueprintjs/table";
import { TextSortableColumn } from "../../components/Table/TextSortableColumn/TextSortableColumn";
import { handlePhotosSearch } from "../../config/Utils";
import { AuthRoles } from "../../blocs/authBloc";
import {
  DownloadAllAttachmentsEvents
} from "../../blocs/projectAttachmentBloc";
import { useContext } from "react";
import BlocRegistry from "../../blocs/registry";

const ResponsiveGridLayout = WidthProvider(Responsive);

type Props = {
  project: ?Project,
  projectId: string,
  photos: ?Array<GetAttachmentResponse>,
  onPhotoTileClick: () => void,
  onAddPhoto: (request: CreateAttachmentRequest) => void,
  onDeletePhoto: (request: DeleteAttachmentForProjectEvent) => void,
  onSetProjectCoverPhoto: (request: GetAttachmentResponse) => any,
};

export default function ProjectPhotosGrid(props: Props) {
  const {
    onAddPhoto,
    onPhotoTileClick,
    onDeletePhoto,
    onSetProjectCoverPhoto,
    project,
    currentUserRole
  } = props;
  const { attachmentBloc } = useContext(BlocRegistry);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [dataUri, setDataUri] = useState("");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [photoOption, setPhotoOption] = useState(false);
  const [isPreview, setPreview] = useState(false);
  const [photo, setPhoto] = useState("");
  const [photoName, setPhotoName] = useState("");
  const [isUploaded, setUploaded] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [includeInEr, setIncludeInEr] = useState(true);
  const [erTitle, setErTitle] = useState("");
  const [searching, setSearching] = useState([]);
  const [photos, setPhotos] = useState(props.photos);
  const [sortedIndexMap, setSortedIndexMap] = useState([]);

  const downloadAllPhotos = () => {

    attachmentBloc.sendEvent(new DownloadAllAttachmentsEvents(props.project?.id, true));
  }
  useEffect(()=>{
    setPhotos(props.photos)
  },[props.photos])

  const [columns, setColumns] = useState([
    new TextSortableColumn("Image", 0, "getUrl", () => {
      return 0;
    }),
    new TextSortableColumn("Title", 1, "title"),
    new TextSortableColumn("Description", 2, "description"),
    new TextSortableColumn("Tag", 3, "tag", undefined, {
      truncate: false,
      wrapText: true,
    }),
    new TextSortableColumn("", 4, "controls", () => {
      return 0;
    }),
  ]);

  const handleAcceptImage = (dataUri) => {
    setCameraOpen(false);
    setPreview(false);
    setDataUri(dataUri);
  };

  const handleRejectImage = () => {
    setPreview(false);
    setPhotoOption(true);
  };

  const getCompressionRatio = (imageSize) => {
    let ratio = 0.4;
    if (imageSize <= GlobalConstanst.FILE_SIZE_LOW) {
      return false;
    } else if (
      imageSize > GlobalConstanst.FILE_SIZE_LOW &&
      imageSize < GlobalConstanst.FILE_SIZE_MEDIUM
    ) {
      ratio = 0.8;
    } else if (
      imageSize >= GlobalConstanst.FILE_SIZE_MEDIUM &&
      imageSize < GlobalConstanst.FILE_SIZE_MAXIMUM
    ) {
      ratio = 0.7;
    }
    return ratio;
  };

  const handlePhotoUpload = async (e, dragged = false) => {
    let image = {};
    if (dragged) {
      image = e[0];
    } else {
      image = e.target.files[0];
    }
    setPhotoOption(false);
    if (!image.name.match(/\.(jpg|jpeg|png|gif|webp|tiff|psd|svg)$/)) {
      AppToaster.show({
        message: "Please select valid image",
        intent: Intent.DANGER,
      });
      setUploaded(false);
      return false;
    } else {
      let imageBlob = new Blob();
      let imageCompressionRatio = getCompressionRatio(image.size);
      if (imageCompressionRatio) {
        imageBlob = await new Promise((resolve, reject) => {
          new Compressor(image, {
            quality: imageCompressionRatio,
            success: (compressedResult) => {
              resolve(compressedResult);
            },
          });
        });
      } else {
        imageBlob = image;
      }
      setPhotoName(image.name);
      setUploaded(true);
      const fileString = await new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = (err) => {
          throw err;
        };
        reader.readAsDataURL(imageBlob);
      });

      setPhoto(fileString);
      setPreview(true);
    }
  };

  const createAddImageTile = (
    index: number,
    onAddPhoto: (request: CreateAttachmentRequest) => void
  ) => {
    return (
      <div>
        <div
          key={index}
          height={"100%"}
          width={"100%"}
          margin={"0 auto"}
          alignItems={"center"}
          justifyContent={"center"}
        >
            {
            AuthRoles.COMPLIANCE_AUDITOR !== props.currentUserRole &&

          
          <Dropzone
            onDrop={(acceptedFiles) => handlePhotoUpload(acceptedFiles, true)}
          >
            {({ getRootProps, getInputProps }) => (
              <Card
                {...getRootProps()}
                className={styles.attachmentTile}
                onClick={() => {
                  // setCameraOpen(true)
                  setPhotoOption(true);
                }}
                interactive
              >
                <input {...getInputProps()}  
               // disabled={AuthRoles.COMPLIANCE_AUDITOR === props.currentUserRole}
                />

                <Flexbox
                  height={"100%"}
                  minHeight={"250px"}
                  width={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  className={styles.thumbnailContainer}
                >
                  <Flexbox flex={3} />
                  <Flexbox
                    flex={2}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      color="#ffffff"
                      transform="grow-30"
                    />
                  </Flexbox>
                  <Flexbox flexDirection={"column"} flex={3} height={"100%"}>
                    <h6>
                      Drag and drop a file here, or click to select a file.
                    </h6>
                  </Flexbox>
                </Flexbox>
              </Card>
            )}
          </Dropzone>
            }
          </div>
        
          <FullscreenCamera
            onClose={() => {
              setCameraOpen(false);
            }}
            open={cameraOpen}
            onAcceptImage={handleAcceptImage}
          />
          <PhotoOptionOverlay
            isPhotoOption={photoOption}
            onClose={setPhotoOption}
            handlePhotoUpload={handlePhotoUpload}
            setCameraOpen={setCameraOpen}
          />

        <Overlay
          isOpen={isPreview}
          onClose={() => setPreview(false)}
          onClose={handleRejectImage}
          canOutsideClickClose={false}
          canEscapeKeyClose={true}
        //  disabled={AuthRoles.COMPLIANCE_AUDITOR === props.currentUserRole}

        >
          <Flexbox
            width={"100vw"}
            height={"100vh"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className={styles.closeButton}>
              <Button icon={"cross"} onClick={handleRejectImage} />
            </div>
            <PhotoPreview
              dataUri={photo}
              onAcceptImage={handleAcceptImage}
              onRejectImage={handleRejectImage}
            />
          </Flexbox>
        </Overlay>

        <Overlay
          isOpen={dataUri}
          onClose={() => {
            setDataUri("");
          }}
       //   disabled={AuthRoles.COMPLIANCE_AUDITOR === props.currentUserRole}

        >
          <Flexbox
            height={"100%"}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            className={styles.uploadImagePreviewContainer}
          >
            <Flexbox alignItems={"center"} justifyContent={"center"}>
              <img className={styles.uploadImagePreview} src={dataUri} />
            </Flexbox>
            <Flexbox
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"column"}
              marginTop={"1%"}
            >
              {isUploaded ? (
                <Tag intent={Intent.WARNING}>
                  Note : Leave Title blank to have same name (Don't add address
                  in title)
                </Tag>
              ) : (
                <Tag intent={Intent.WARNING}>Don't add address in title</Tag>
              )}
            </Flexbox>

            <Flexbox padding={"10px"}>
              <Checkbox
                labelElement={<strong>Include in ER</strong>}
                checked={includeInEr}
                onChange={() => {
                  setIncludeInEr(!includeInEr);
                }}
              />
            </Flexbox>

            {includeInEr && (
              <>
                <Tag intent={Intent.SUCCESS}>
                  Optional: If no title is selected, included in Activity Area
                  Pictures
                </Tag>
                <Flexbox padding={"10px"}>
                  <Flexbox
                    flex={1}
                    flexDirection={"column"}
                    width={"100%"}
                    marginTop={"10px"}
                  >
                    <Flexbox flex={1}>
                      <label htmlFor={"erSelect"}>ER Title</label>
                    </Flexbox>
                    <Flexbox flex={1}>
                      <div class="bp3-select .modifier">
                        <select
                          value={erTitle}
                          onChange={(event) => {
                            setErTitle(event.target.value);
                            setTitle(event.target.value);
                          }}
                        >
                          <option selected>Select</option>
                          <optgroup label="AREA PICTURES">
                             <option value="House on the left">House on the left</option>
                             <option value="House on the right"> House on the right </option>
                             <option value="House Across Street">
                            House Across Street
                          </option>
                             <option value="Side B (Left) of House">
                              Side B (Left) of House
                            </option>
                          <option value="Side C (Back) of House">
                            Side C (Back) of House
                          </option>
                          <option value="Side D (Right) of House">
                            Side D (Right) of House
                          </option>
                          <option value="Street View of Homes #1">
                            Street View of Homes #1
                          </option>
                          <option value="Street View of Homes #2">
                            Street View of Homes #2
                          </option>
                          </optgroup>
                          <optgroup label="ACTIVITY AREA PICTURES">
                          <option value="Bedroom #1">Bedroom #1</option>
                            <option value="Bedroom #2">Bedroom #2</option>
                            <option value="Bedroom #3">Bedroom #3</option>
                            <option value="Bathroom #1">Bathroom #1</option>
                            <option value="Bathroom #2">Bathroom #2</option>
                            <option value="Electrical Repair">Electrical Repair</option>
                            <option value="Grab Bars">Grab Bars</option>
                            <option value="Garage">Garage</option>
                            <option value="HVAC Repair">HVAC Repair</option>
                            <option value="Kitchen">Kitchen</option>
                            <option value="Living Room #1">Living Room #1</option>
                            <option value="Living Room #2">Living Room #2</option>
                            <option value="Roof Repair">Roof Repair</option>
                            <option value="Side A">Side A</option>
                            <option value="Side B">Side B</option>
                            <option value="Side C">Side C</option>
                            <option value="Utility Room">Utility Room</option>
                          </optgroup>
                          <optgroup label="LEAD PROJECT PICTURE">
                            <option value="Front of House">Front of House</option>
                          </optgroup>
                          {/* <option value="Front of House">Front of House</option>
                            <option value="Side B (Left) of House">
                              Side B (Left) of House
                            </option>
                          <option value="Side C (Back) of House">
                            Side C (Back) of House
                          </option>
                          <option value="Side D (Right) of House">
                            Side D (Right) of House
                          </option>
                          <option value="House Across Street">
                            House Across Street
                          </option>
                          <option value="Street View of Homes #1">
                            Street View of Homes #1
                          </option>
                          <option value="Street View of Homes #2">
                            Street View of Homes #2
                          </option>
                          <option value="Street View of Homes #3">
                            Street View of Homes #3
                          </option> */}
                        </select>
                      </div>
                    </Flexbox>
                  </Flexbox>
                </Flexbox>
              </>
            )}

            <Flexbox padding={"10px"}>
              <Flexbox
                flex={1}
                flexDirection={"column"}
                width={"100%"}
                marginTop={"10px"}
              >
                <Flexbox flex={1}>
                  <label htmlFor={"title"}>Title</label>
                </Flexbox>
                <Flexbox flex={1}>
                  <InputGroup
                    value={title}
                    maxlength="255"
                    placeholder={"Title"}
                    onChange={(event) => {
                      setTitle(event.target.value);
                      setTitleError(false);
                    }}
                  />
                </Flexbox>
              </Flexbox>
            </Flexbox>
            {titleError && !isUploaded ? (
              <Flexbox
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
                marginTop={"1%"}
              >
                <Tag intent={Intent.DANGER}>Title Required!</Tag>
              </Flexbox>
            ) : null}
            <Flexbox padding={"10px"}>
              <Flexbox
                flex={1}
                flexDirection={"column"}
                width={"100%"}
                marginTop={"10px"}
              >
                <Flexbox flex={1}>
                  <label htmlFor={"description"}>Description</label>
                </Flexbox>
                <Flexbox flex={1}>
                  <TextArea
                    value={description}
                    maxlength="255"
                    placeholder={"Description"}
                    onChange={(event) => {
                      setDescription(event.target.value);
                    }}
                  />
                </Flexbox>
              </Flexbox>
            </Flexbox>
            <Flexbox padding={"10px"}>
              <Button
                text={"Upload"}
                rightIcon={"upload"}
                large
                onClick={() => {
                  if (isUploaded) {
                    let fileName;
                    if (isUploaded) {
                      fileName =
                        title === ""
                          ? photoName
                          : `C_${title}.${photoName.slice(
                              ((photoName.lastIndexOf(".") - 1) >>> 0) + 2
                            )}`;
                    } else {
                      fileName = `C_${title}.jpg`;
                    }

                    const request = new CreateAttachmentRequest(
                      props.projectId,
                      fileName,
                      description,
                      dataUri.substring(
                        dataUri.indexOf(":") + 1,
                        dataUri.indexOf(";")
                      ),
                      dataUri.substring(
                        dataUri.indexOf(";") + 1,
                        dataUri.indexOf(",")
                      ),
                      dataUri,
                      includeInEr
                    );
                    onAddPhoto(request);
                    setDataUri("");
                    setPhotoOption(false);
                    setTitle("");
                    setDescription("");
                    setPhotoName("");
                    setUploaded(false);
                  } else if (!isUploaded && title.trim()) {
                    let fileName;
                    if (isUploaded) {
                      fileName =
                        title === ""
                          ? photoName
                          : `C_${title}.${photoName.slice(
                              ((photoName.lastIndexOf(".") - 1) >>> 0) + 2
                            )}`;
                    } else {
                      fileName = `C_${title}.jpg`;
                    }

                    const request = new CreateAttachmentRequest(
                      props.projectId,
                      fileName,
                      description,
                      dataUri.substring(
                        dataUri.indexOf(":") + 1,
                        dataUri.indexOf(";")
                      ),
                      dataUri.substring(
                        dataUri.indexOf(";") + 1,
                        dataUri.indexOf(",")
                      ),
                      dataUri,
                      includeInEr
                    );
                    onAddPhoto(request);
                    setDataUri("");
                    setPhotoOption(false);
                    setTitle("");
                    setDescription("");
                    setPhotoName("");
                    setUploaded(false);
                  } else if (!title.trim()) {
                    setTitleError(true);
                  }
                }}
              />
            </Flexbox>
          </Flexbox>
        </Overlay>
      </div>
    );
  };

  const controlsRenderer = (rowIndex: number) => {
    return (
      <ImageTile 
        index={rowIndex}
        attachmentResponse={photos[rowIndex]}
        onDeletePhoto={onDeletePhoto}
        project={project}
        onSetProjectCoverPhoto={onSetProjectCoverPhoto}
        key={rowIndex}
        src={photos[rowIndex].getUrl}
        currentUserRole={props.currentUserRole}
      />
    );
  };

  const titleRenderer = (rowIndex: number) => {
    if (photos[rowIndex]) {
      return `${photos[rowIndex].title}`;
    } else {
      return "Not Found";
    }
  };

  const imageRenderer = (rowIndex: number) => {
    if (photos[rowIndex]) {
      return <img src={photos[rowIndex].getUrl} />;
    } else {
      return "Not Found";
    }
  };

  const descriptionRenderer = (rowIndex: number) => {
    if (photos[rowIndex]) {
      return photos[rowIndex].description;
    } else {
      return "Not Found";
    }
  };

  const tagNameRenderer = (rowIndex: number) => {
    if (photos[rowIndex]) {
      return photos[rowIndex].tag ? photos[rowIndex].tag : " ";
    } else {
      return "Not Found";
    }
  };
  const cellRenderers = {
    title: titleRenderer,
    description: descriptionRenderer,
    tag: tagNameRenderer,
    controls: controlsRenderer,
    getUrl: imageRenderer,
  };

  const getCellData = (rowIndex: number, columnIndex: number) => {
    const sortedRowIndex = sortedIndexMap[rowIndex];
    if (sortedRowIndex != null) {
      rowIndex = sortedRowIndex;
    }
    return cellRenderers[columns[columnIndex].modelField](rowIndex);
  };

  const sortColumn = (
    columnIndex: number,
    comparator: (a: any, b: any) => number
  ) => {
    const sortedIndexMap = Utils.times(photos.length, (i: number) => i);
    sortedIndexMap.sort((a: number, b: number) => {
      let fieldA;
      let fieldB;
      switch (columns[columnIndex].modelField) {
        case "title": {
          fieldA = photos[a].title;
          fieldB = photos[b].title;
          break;
        }
        case "description": {
          fieldA = photos[a].description;
          fieldB = photos[b].description;
          break;
        }
        default: {
          fieldA = photos[a][columns[columnIndex].modelField];
          fieldB = photos[b][columns[columnIndex].modelField];
          break;
        }
      }
      return comparator(fieldA, fieldB, a, b);
    });
    setSortedIndexMap(sortedIndexMap);
  };


  const renderedColumns = columns.map((column) => {
    return column.getColumn(getCellData, sortColumn);
  });

  let list = <Spinner />;

  return (
    <Flexbox
      flexGrow={1}
      flexDirection={"column"}
      paddingLeft={"10px"}
      paddingRight={"10px"}
      width={"100%"}
    >
      <Flexbox
        justifyContent={"center"}
        paddingTop={"10px"}
        paddingLeft={"10px"}
        width={"100%"}
      >
        <h3 className={`bp3-heading ${styles.myAttachmentsTitle}`}>Photos</h3>
      </Flexbox>
      {(props.currentUserRole !== AuthRoles.MARKETING_MANAGER) && 
        createAddImageTile(0, onAddPhoto)
      }
      
      <div style={{ margin: 16 }}>
      <Flexbox flex={1}>
          <InputGroup
            placeholder="Search..."
            leftIcon={<Icon icon="search" intent={Intent.PRIMARY} />}
            onChange={(e) => {
              setSearching(e.target.value === "" ? false : true);
              const searchedPhotos = handlePhotosSearch(
                e,
                props.photos
              );
              setPhotos(searchedPhotos);
            }}
          />
      </Flexbox>
        <div style={{marginBottom: 10}}>
      <ButtonGroup>
      <Button onClick={downloadAllPhotos} disabled={photos&& photos.length ? false : true} style={{ marginRight: "10%" }} intent={Intent.PRIMARY}>Download All</Button>
        <Button onClick={()=>{
          props.setGridView(true)
        }} icon="grid">Show Grid View</Button>
      </ButtonGroup>
      </div>
        <Table
          defaultRowHeight={130}
          selectionModes={SelectionModes.COLUMNS_AND_CELLS}
          numRows={photos ? photos.length : 0}
        >
          {renderedColumns}
        </Table>
      </div>
    </Flexbox>
  );
}

type ImageTileProps = {
  project: ?Project,
  attachmentResponse: GetAttachmentResponse,
  src: string,
  index: number,
  onDeletePhoto: (request: DeleteAttachmentForProjectEvent) => any,
  onSetProjectCoverPhoto: (request: GetAttachmentResponse) => any,
 
};

function ImageTile(props: ImageTileProps) {
  const { src, index, attachmentResponse, project } = props;
  const [image, setImage] = useState(null);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  useEffect(() => {
    if (src) {
      const newImage = new Image();
      newImage.onload = () => {
        setImage(newImage);
      };
      newImage.onerror = () => {
        newImage.src = "/errorMessage.png";
      };
      newImage.src = src;
    }
  }, [src]);

  const close = () => {
    setIsOpen(false);
  };

  const open = () => {
    setIsOpen(true);
  };

  return (
    <Flexbox
      key={index}
      height={"100%"}
      width={"100%"}
      justifyContent={"center"}
      flexDirection={"column"}
      className={styles.noOverflow}
    >
      <Flexbox margin={"0 auto"} className={styles.noOverflow}>
        <Menu className={Classes.ELEVATION_2}>
          <MenuItem
            onClick={() => {
              if (image) open();
            }}
            text="View"
            icon={"eye-open"}
            intent={Intent.PRIMARY}
          />
        </Menu>
        <Overlay
          isOpen={isOpen && image}
          onClose={close}
          canOutsideClickClose={true}
        >
          <Flexbox
            height={"100vh"}
            width={"100vw"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            className={styles.fullscreenImageContainer}
          >
            <div className={styles.closeButton}>
              <Button icon={"cross"} onClick={close} />
            </div>
            {/* <Flexbox alignItems={'center'} justifyContent={'center'} width={'100%'} height={'130%'} flex={3}> */}
            {/* {image ? <iframe className={styles.imagePreview}  src={image.src} /> : <Spinner />} */}

            {image ? (
              <Flexbox
                alignItems={"center"}
                justifyContent={"center"}
                flexDirection={"column"}
                height={"-webkit-fill-available"}
                marginTop={"10%"}
                width={"100%"}
              >
                <img src={image.src} />
              </Flexbox>
            ) : (
              <Spinner />
            )}

            {/* </Flexbox> */}
            <Flexbox flexDirection={"column"} flex={1}>
              <Flexbox padding={"10px"} flexDirection={"column"}>
                {attachmentResponse.title && (
                  <>
                    <H6>Title:</H6>{" "}
                    <Card>
                      <div>
                        {attachmentResponse.title.replace("C_", " ").trim()}
                      </div>
                    </Card>
                  </>
                )}
              </Flexbox>
              <Flexbox padding={"10px"} flexDirection={"column"}>
                {attachmentResponse.description && (
                  <>
                    <H6>Description:</H6>
                    <Card>
                      <div>{attachmentResponse.description}</div>
                    </Card>
                  </>
                )}
              </Flexbox>
            </Flexbox>
            <Flexbox>
            {  AuthRoles.COMPLIANCE_AUDITOR !== props.currentUserRole && props.currentUserRole !== AuthRoles.MARKETING_MANAGER &&
                  <Flexbox> 
              <Flexbox padding={"10px"}>
            
                <Popover
                  isOpen={isDeleteOpen}
                  onClose={() => {
                    setIsDeleteOpen(false);
                  }}
                >
                  <Button
                    icon={"delete"}
                    text={"Delete"}
                    onClick={() => {
                      setIsDeleteOpen(true);
                    }}
                  />
                  <Flexbox justifyContent={"center"}>
                    <Flexbox margin={"5px"}>
                      <Button
                        icon={"cross"}
                        text={"Cancel"}
                        minimal
                        onClick={() => {
                          setIsDeleteOpen(false);
                        }}
                      />
                    </Flexbox>
                    <Flexbox margin={"5px"}>
                      <Button
                        icon={"confirm"}
                        text={"Confirm"}
                        intent={Intent.WARNING}
                        onClick={() => {
                          if (!image) return;
                          props.onDeletePhoto(
                            new DeleteAttachmentForProjectEvent(
                              attachmentResponse.projectId,
                              AttachmentTypeEnum.image,
                              attachmentResponse.id
                            )
                          );
                          setIsDeleteOpen(false);
                          close();
                        }}
                      />
                    </Flexbox>
                  </Flexbox>
                </Popover>
               
              </Flexbox>
              <Flexbox padding={"10px"}>
                <Button
                  icon={"media"}
                  text={"Set as project cover photo"}
                  onClick={() => {
                    props.onSetProjectCoverPhoto(attachmentResponse);
                    close();
                  }}
                />
              </Flexbox>
              </Flexbox>
            }
              <Flexbox padding={"10px"}>
                <Button
                  icon={"download"}
                  text={"Download"}
                  onClick={async () => {
                    if (!image) return;

                    const arrayBuffer = (
                      await axios.get(attachmentResponse.getUrl, {
                        headers: {
                          "Accept-Encoding": "gzip, deflate, br",
                          "Accept-Language": "en-GB,en-US;q=0.9,en;q=0.8",
                          Connection: "keep-alive",
                          Host: "s3.ap-south-1.amazonaws.com",
                          "If-Modified-Since": "Wed, 28 Oct 2020 10:53:07 GMT",
                          "If-None-Match": "dbbb68b7217793496adb6be98ed459bb",
                          Origin: "https://uat-app.rehab-tracker.com",
                          "Sec-Fetch-Dest": "empty",
                          "Sec-Fetch-Mode": "cors",
                          "Sec-Fetch-Site": "cross-site",
                        },
                        responseType: "arraybuffer",
                      })
                    ).data;

                    function _imageEncode(arrayBuffer) {
                      let u8 = new Uint8Array(arrayBuffer);

                      let b64encoded = btoa(
                        [].reduce.call(
                          new Uint8Array(arrayBuffer),
                          function (p, c) {
                            return p + String.fromCharCode(c);
                          },
                          ""
                        )
                      );
                      let mimetype = "image/jpeg";
                      return "data:" + mimetype + ";base64," + b64encoded;
                    }

                    let encodedString = _imageEncode(arrayBuffer);

                    const base = encodedString.split(",")[1];

                    const type = encodedString.split(";")[0].split("/")[1];
                    const fileType = attachmentResponse.fileType;
                    const extension = mime.extension(fileType);

                    const byteString = atob(base);

                    const ab = new ArrayBuffer(byteString.length);
                    const ia = new Uint8Array(ab);

                    for (let i = 0; i < byteString.length; i++) {
                      ia[i] = byteString.charCodeAt(i);
                    }

                    let filename;

                    let downloadExtension = attachmentResponse.title.slice(
                      ((attachmentResponse.title.lastIndexOf(".") - 1) >>> 0) +
                        2
                    );

                    let projectName = `_${project.home.address.streetAddress}.${extension}`;

                    let downloadName = attachmentResponse.title
                      .replace("C_", " ")
                      .trim()
                      .replace(`.${downloadExtension}`, projectName);

                    if (attachmentResponse.title.includes(`C_`)) {
                      filename = attachmentResponse.title
                        ? downloadName
                        : `${attachmentResponse.id}.${extension}`;
                    } else {
                      if (downloadExtension) {
                        filename = attachmentResponse.title
                          ? attachmentResponse.title
                          : `${attachmentResponse.id}.${extension}`;
                      } else {
                        filename = attachmentResponse.title
                          ? `${attachmentResponse.title}.${extension}`
                          : `${attachmentResponse.id}.${extension}`;
                      }
                    }

                    const file = new File([ab], filename, { type: type });

                    FileSaver.saveAs(file);

                    // const url = image.src.replace(/^data:image\/[^;]+/,
                    //     'data:application/octet-stream');
                    // window.open(url);
                  }}
                />
              </Flexbox>
            </Flexbox>
          </Flexbox>
        </Overlay>
      </Flexbox>
    </Flexbox>
  );
}

/*
*  {image ? <a href={image.src}
                                        download={`${attachmentResponse.id}.${attachmentResponse.fileType.substring(attachmentResponse.fileType.indexOf('/'), attachmentResponse.fileType.length)}`}>Download</a> : null}*/
