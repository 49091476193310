//@Flow
import { BehaviorSubject, PartialObserver, Subject, Subscription } from "rxjs";
import Task, { CreateTaskRequest } from "../models/Task/Task";
import TaskService from "../service/TaskService/TaskService";
import { AuthBloc, AuthContext, AuthStates } from "./authBloc";
import TaskGroupService from "../service/TaskGroupService/TaskGroupService";
import TaskGroup, {
  CreateTaskGroupRequest
} from "../models/TaskGroup/TaskGroup";
import { Intent, Spinner } from "@blueprintjs/core";
import React from "react";
import { AppToaster } from "../components/Toaster/Toaster";

export const TaskStates = Object.freeze({
  UNINITIALIZED: Symbol("UNINITIALIZED"),
  INITIALIZING: Symbol("INITIALIZING"),
  INITIALIZED: Symbol("INITIALIZED")
});

export type TaskStatesTypes = $Keys<typeof TaskStates>;

export const TaskEventTypesEnum = Object.freeze({
  GET_TASKLIST_BY_ID: Symbol("GET_TASKLIST_BY_ID"),
  CREATE_TASK: Symbol("CREATE TASK"),
  UPDATE_TASK: Symbol("UPDATE TASK"),
  CREATE_TASK_GROUP: Symbol("CREATE TASK GROUP"),
  REFRESH: Symbol("REFRESH"),
  REMOVE_TASK_FROM_LIST: Symbol("REMOVE_TASK_FROM_LIST")
});

export type TaskEventTypes = $Keys<typeof TaskEventTypesEnum>;

export class TaskEvent {
  type: TaskEventTypes;

  constructor(type: TaskEventTypes) {
    this.type = type;
  }
}

export class CreateTaskEvent extends TaskEvent {
  request: CreateTaskRequest;

  constructor(request: CreateTaskRequest) {
    super(TaskEventTypesEnum.CREATE_TASK);
    this.request = request;
  }
}

export class UpdateTaskEvent extends TaskEvent {
  request: UpdateTaskRequest;

  constructor(request: UpdateTaskRequest) {
    super(TaskEventTypesEnum.UPDATE_TASK);
    this.request = request;
  }
}

export class GetTaskListEvent extends TaskEvent {
  taskListId: string;

  constructor(taskListId: string) {
    super(TaskEventTypesEnum.GET_TASKLIST_BY_ID);
    this.taskListId = taskListId;
  }
}
export class RemoveTaskFromListEvent extends TaskEvent {
  taskId: string;

  constructor(taskId: string) {
    super(TaskEventTypesEnum.REMOVE_TASK_FROM_LIST);
    this.taskId = taskId;
  }
}

export class CreateTaskGroupEvent extends TaskEvent {
  request: CreateTaskGroupRequest;

  constructor(request: CreateTaskGroupEvent) {
    super(TaskEventTypesEnum.CREATE_TASK_GROUP);
    this.request = request;
  }
}

export class RefreshTasksEvent extends TaskEvent {
  constructor() {
    super(TaskEventTypesEnum.REFRESH);
  }
}

export class TaskBloc {
  _outTaskContext: BehaviorSubject = new BehaviorSubject();

   _getTaskListEvent: GetTaskListEvent = new GetTaskListEvent();

  subscribeToTaskContext(observer?: PartialObserver<TaskContext>) {
    return this._outTaskContext.asObservable().subscribe(observer);
  }

  _eventController: Subject = new Subject();

  sendEvent(event: TaskEvent) {
    return this._eventController.next(event);
  }
  

  _taskService: TaskService;
  _taskGroupService: TaskGroupService;

  _authBloc: AuthBloc;
  _authBlocSubscription: ?Subscription;

  constructor(
    taskService: TaskService,
    taskGroupService: TaskGroupService,
    authBloc: AuthBloc,
     getTaskListEvent?: GetTaskListEvent
  ) {
    
    this._outTaskContext.next(new TaskContext(null, TaskStates.UNINITIALIZED));
    this._taskService = taskService;
    this._taskGroupService = taskGroupService;
    this._authBloc = authBloc;
    this._authBlocSubscription = this._authBloc.subscribeToAuthContext(
      this.buildAuthContextChangeHandler()
    );
    if (getTaskListEvent !== null) {
      this._getTaskListEvent = getTaskListEvent;
    }
  }
  getTaskListId = null;

  buildAuthContextChangeHandler = () => {
    return {
      next: async (authContext: AuthContext) => {
        switch (authContext.State) {
          case AuthStates.AUTHENTICATED: {
            this.initialize();
            break;
          }
          default: {
            break;
          }
        }
      },
      error(err) {
        throw err;
      }
    };
  };

  setTaskListEvent = event => {
    this._getTaskListEvent = event;
    if (this._getTaskListEvent != undefined || this._getTaskListEvent != null) {
      this.getTaskListId = this._getTaskListEvent.taskListId;
    this.initialize();

    } else {
      this.getTaskListId = null;
    }

  };
  async initialize() {
    if(this.getTaskListId!=null){
    this._outTaskContext.next(
      new TaskContext(null, null, TaskStates.INITIALIZING)
    );
    const taskGroups = await this._taskGroupService.getAllTaskGroups(
      this.getTaskListId
    );
    const tasks = await this._taskService.getAllTasks(this.getTaskListId);
    this._outTaskContext.next(
      new TaskContext(taskGroups, tasks, TaskStates.INITIALIZED)
    );
    this._eventController.subscribe(this.buildEventHandler());
    }
  }

  buildEventHandler = () => {
    return {
      next: async (event: TaskEvent) => {
        switch (event.type) {
          case TaskEventTypesEnum.GET_TASKLIST_BY_ID: {
            const taskGroups = await this._taskGroupService.getAllTaskGroups(
              (event: GetTaskListEvent).taskListId
            );
            const tasks = await this._taskService.getAllTasks(
              (event: GetTaskListEvent).taskListId
              );
            this._outTaskContext.next(
              new TaskContext(taskGroups, tasks, TaskStates.INITIALIZED)
            );
            break;
          }
          case TaskEventTypesEnum.REFRESH: {
            const taskGroups = await this._taskGroupService.getAllTaskGroups();
            const tasks = await this._taskService.getAllTasks();
            this._outTaskContext.next(
              new TaskContext(taskGroups, tasks, TaskStates.INITIALIZED)
            );
            break;
          }
          case TaskEventTypesEnum.REMOVE_TASK_FROM_LIST: {
            await this._taskService.removeTaskFromTaskList(
              (event: RemoveTaskFromListEvent).taskId
            );
            // const taskGroups = await this._taskGroupService.getAllTaskGroups();
            // const tasks = await this._taskService.getAllTasks(
            //   this.getTaskListId
            // );
            // this._outTaskContext.next(
            //   new TaskContext(taskGroups, tasks, TaskStates.INITIALIZED)
            // );
            break;
          }
          case TaskEventTypesEnum.UPDATE_TASK: {
            const newTask = await this._taskService.updateTask(
              (event: UpdateVendorEvent).request
            );
            // const taskGroups = await this._taskGroupService.getAllTaskGroups();
            // const tasks = await this._taskService.getAllTasks(
            //   this.getTaskListId
            // );
            // this._outTaskContext.next(
            //   new TaskContext(taskGroups, tasks, TaskStates.INITIALIZED)
            // );
            break;
          }
          case TaskEventTypesEnum.CREATE_TASK: {
            const newTask = await this._taskService.createTask(
              (event: UpdateVendorEvent).request
            );
            const currentTaskContext = this._outTaskContext.getValue();
            const newTasks = currentTaskContext.tasks;
            newTasks.push(newTask);
            this._outTaskContext.next(
              new TaskContext(
                currentTaskContext.taskGroups,
                newTasks,
                TaskStates.INITIALIZED
              )
            );
            break;
          }
          case TaskEventTypesEnum.CREATE_TASK_GROUP: {
            const newTaskGroup = await this._taskGroupService.createTaskGroup(
              (event: CreateTaskGroupEvent).request
            );
            const currentTaskContext = this._outTaskContext.getValue();
            const newTaskGroups = currentTaskContext.taskGroups;
            newTaskGroups.push(newTaskGroup);
            this._outTaskContext.next(
              new TaskContext(
                newTaskGroups,
                currentTaskContext.tasks,
                TaskStates.INITIALIZED
              )
            );
            break;
          }
          default: {
           
            throw new Error("Unknown task event: " + event.type);
          }
        }
      },
      error:  (err) => {
        throw err;
      }
     
     
    };
  };

  dispose() {
  
    this._outTaskContext.complete();
    this._eventController.complete();
    this._authBlocSubscription.unsubscribe();
  }
}

export class TaskContext {
  taskGroups: Array<TaskGroup>;
  tasks: Array<Task>;
  state: TaskStatesTypes;

  constructor(
    taskGroups: Array<TaskGroup>,
    tasks: Array<Task>,
    state: TaskStatesTypes
  ) {
    this.taskGroups = taskGroups;
    this.state = state;
    this.tasks = tasks;
  }
}

const sortTasks = (taskA, taskB) => {
  return taskA.title.toLowerCase().localeCompare(taskB.title.toLowerCase());
};

const sortTaskGroups = (taskGroupA, taskGroupB) => {
  return taskGroupA.title
    .toLowerCase()
    .localeCompare(taskGroupB.title.toLowerCase());
};
