//@Flow
import {BehaviorSubject, PartialObserver, Subject, Subscription} from 'rxjs'
import UserService from "../service/UserService/UserService";
import {AuthBloc, AuthContext, AuthStates} from "./authBloc";
import Team from "../models/Team/Team";

export const UserStates = Object.freeze({
    UNINITIALIZED: Symbol("UNINITIALIZED"),
    INITIALIZING: Symbol("INITIALIZING"),
    INITIALIZED: Symbol("INITIALIZED"),
});

export type UserStatesTypes = $Keys<typeof UserStates>;

export const UserEventTypesEnum = Object.freeze({
});

export type UserEventTypes = $Keys<typeof UserEventTypesEnum>;

export class UserEvent{
    type: UserEventTypes;

    constructor(type: UserEventTypes) {
        this.type = type;
    }
}

export class UserBloc {
    _outUserContext: BehaviorSubject = new BehaviorSubject();

    subscribeToUserContext(observer?: PartialObserver<UserContext>) {
        return this._outUserContext.asObservable().subscribe(observer);
    }

    _eventController: Subject = new Subject();

    sendEvent(event: UserEventsTypes) {
        return this._eventController.next(event)
    }

    _userService: UserService;

    _authBloc: AuthBloc;
    _authBlocSubscription: ?Subscription;

    constructor(userService : UserService, authBloc: AuthBloc) {
        this._outUserContext.next(new UserContext(null, UserStates.UNINITIALIZED));
        this._userService = userService;
        this._authBloc = authBloc;
        this._authBlocSubscription = this._authBloc.subscribeToAuthContext(this.buildAuthContextChangeHandler())
    }

    buildAuthContextChangeHandler = () => {
        return {
            next: async (authContext: AuthContext) => {
                switch (authContext.State) {
                    case AuthStates.AUTHENTICATED: {
                        this.initialize();
                        break;
                    }
                    default: {
                        break;
                    }
                }
            },
            error(err) {
                throw err
            },
        };
    };

    async initialize() {
        this._outUserContext.next(new UserContext(null, UserStates.INITIALIZING));
        const team = await this._userService.getTeam();
        this._outUserContext.next(new UserContext(team, UserStates.INITIALIZED));
        this._eventController.subscribe(this.buildEventHandler());
    }

    buildEventHandler = () => {
        return {
            next: async (event: UserEvent) => {
                switch (event.type) {
                    default: {
                        throw new Error("Unknown user event: " + event.constructor.name);
                    }
                }
            },
            error(err) {
                throw err
            },
        };
    };

    dispose() {
        this._outUserContext.complete();
        this._eventController.complete();
        this._authBlocSubscription.unsubscribe();
    }
}

export class UserContext {
    team: Team;
    state: UserStatesTypes;

    constructor(team: Team, state: UserStatesTypes) {
        this.team = team;
        this.state = state;
    }
}
