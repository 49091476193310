import React from "react";
import {AbstractSortableColumn} from "../AbstractSortableColumn/AbstractSortableColumn";
import type {ICellLookup, ISortCallback} from "../AbstractSortableColumn/AbstractSortableColumn";
import {Menu, MenuItem} from "@blueprintjs/core";
import {Cell, Column, ColumnHeaderCell} from "@blueprintjs/table";

export class TextSortableColumn extends AbstractSortableColumn {

    compareFunction: (a: any, b: any, indexA: number, indexB: number)=>number;
    options: { truncate: boolean, wrapText?: boolean, isSorting: boolean}={
        truncate: true,
        isSorting:true
    };
    constructor(name: string,
                index: number,
                modelField: string,
                compareFunction: ?function,
                options?: { truncate?: boolean, wrapText?: boolean ,isSorting?: boolean}) {
        super(name, index, modelField);

        const defaultOptions = {
            truncate: true,
            isSorting:true
        };

        if (options) {
            for (const key in defaultOptions) {
            if (!options.hasOwnProperty(key)) {
                options[key]=defaultOptions[key]
            };
            }
            this.options = options;
        }

       
        this.compareFunction = compareFunction ? compareFunction : this.compare;
    }

    getColumn(getCellData: ICellLookup, sortColumn: ISortCallback) {
        const cellRenderer = (rowIndex: number, columnIndex: number) => (
            <Cell truncated={this.options?.truncate} wrapText={this.options?.wrapText} style={{wordWrap: 'break-word', overflow: 'auto'}} >{getCellData(rowIndex,
                columnIndex)}</Cell>
        );
        const columnHeaderCellRenderer = () => <ColumnHeaderCell name={this.name} 
        menuRenderer={() => {
            //return this.options?.isSorting ? this.renderMenu(sortColumn) : <></>
            return this.options !== undefined ? (this.options.isSorting ? this.renderMenu(sortColumn) :<> </>) :<> </>
        }}/>;
        return (
            <Column
                cellRenderer={cellRenderer}
                columnHeaderCellRenderer={columnHeaderCellRenderer}
                key={this.index}
                name={this.name}
            />
        );
    }

    renderMenu(sortColumn: ISortCallback) {
        const sortAsc = () => sortColumn(this.index,
            (a, b, indexA, indexB) => this.compareFunction(a, b, indexA, indexB));
        const sortDesc = () => sortColumn(this.index,
            (a, b, indexA, indexB) => this.compareFunction(b, a, indexB, indexA));
        return (
          
            <Menu>
                <MenuItem icon="sort-asc" onClick={sortAsc} text="Sort Asc"/>
                <MenuItem icon="sort-desc" onClick={sortDesc} text="Sort Desc"/>
            </Menu>
            
            
        );
    }

    compare(a: any, b: any) {
        return a.toString().localeCompare(b);
    }
    
}
