// @flow

export const QuantityTypeEnum = Object.freeze({
    FLOAT: 'FLOAT'
});

export type QuantityTypeEnumTypes = $Keys<typeof QuantityTypeEnum>;

export const PriceTypeEnum = Object.freeze({
    USD: 'USD',
    PER_BID: 'PER_BID'
});

export type PriceTypeEnumTypes = $Keys<typeof PriceTypeEnum>;

export default class Task {
    id: string;
    taskGroupId: string;
    title: string;
    description: string;
    unit: string;
    quantityType: QuantityTypeEnumTypes;
    price: string;
    priceType: PriceTypeEnumTypes;

    constructor(id: string,
                taskGroupId: string,
                title: string,
                description: string,
                unit: string,
                quantityType: QuantityTypeEnumTypes,
                price: string,
                priceType: PriceTypeEnumTypes) {
        this.id = id;
        this.taskGroupId = taskGroupId;
        this.title = title;
        this.description = description;
        this.unit = unit;
        this.quantityType = quantityType;
        this.price = price;
        this.priceType = priceType;
    }
}


export class CreateTaskRequest {
    taskGroupId: string;
    title: string;
    description: string;
    unit: string;
    quantityType: QuantityTypeEnumTypes;
    price: string;
    priceType: PriceTypeEnumTypes;
    taskListId:String;


    constructor( taskGroupId: string,
                title: string,
                description: string,
                unit: string,
                quantityType: QuantityTypeEnumTypes,
                price: string,
                priceType: PriceTypeEnumTypes,
                taskListId:String
                ) {
        this.taskGroupId = taskGroupId;
        this.title = title;
        this.description = description;
        this.unit = unit;
        this.quantityType = quantityType;
        this.price = price;
        this.priceType = priceType;
        this.taskListId=taskListId;
    }
}


export class UpdateTaskRequest {
    taskGroupId: string;
    title: string;
    description: string;
    unit: string;
    quantityType: QuantityTypeEnumTypes;
    price: string;
    priceType: PriceTypeEnumTypes;
    id: String;


    constructor( taskGroupId: string,
                title: string,
                description: string,
                unit: string,
                quantityType: QuantityTypeEnumTypes,
                price: string,
                priceType: PriceTypeEnumTypes, id: String) {
        this.taskGroupId = taskGroupId;
        this.title = title;
        this.description = description;
        this.unit = unit;
        this.quantityType = quantityType;
        this.price = price;
        this.priceType = priceType;
        this.id = id;
    }
}
