// @flow
import { AppToaster } from "../../components/Toaster/Toaster";
import { Intent } from "@blueprintjs/core";
import type { AuthBloc } from "../../blocs/authBloc";
import type Task from "../../models/Task/Task";
import { apiAxios } from "../../config/AxiosConfig";
import { CreateTaskRequest } from "../../models/Task/Task";

class TaskApi {
  authBloc: AuthBloc;

  constructor(authBloc: AuthBloc) {
    this.authBloc = authBloc;
  }

  async getAll(taskListId): Promise<Array<Task>> {
    const tenantId = this.authBloc.getCurrentTenant();

    const response = await apiAxios.get(
      `/tenants/${tenantId}/list/${taskListId}/tasks`,
      this.buildHeaders()
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.status);
    }
  }

  async createTask(createTaskRequest: CreateTaskRequest): Promise<Task> {
    const tenantId = this.authBloc.getCurrentTenant();

    const response = await apiAxios.post(
      `/tenants/${tenantId}/tasks`,
      createTaskRequest,
      this.buildHeaders()
    );

    if (response.status === 201) {
      return response.data;
    } else {
      throw new Error(response.status);
    }
  }

  async updateTask(updateTaskRequest): Promise<Task> {
    const tenantId = this.authBloc.getCurrentTenant();

    const taskId = updateTaskRequest.id;

    const response = await apiAxios.patch(
      `/tenants/${tenantId}/tasks/${taskId}`,
      updateTaskRequest,
      this.buildHeaders()
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.status);
    }
  }

  async removeTaskFromList(taskId: string): Promise<Task> {
    try {
      const tenantId = this.authBloc.getCurrentTenant();

      const response = await apiAxios.delete(
        `/tenants/${tenantId}/tasks/${taskId}`,
        this.buildHeaders()
      );

      if (response.status === 200) {
        AppToaster.show({
          message: "Task Deleted",
          intent: Intent.PRIMARY
        });
        return response.data;
      } else { }
    } catch (error) {
      if (error.response.data) {
        AppToaster.show({
          message: error.response.data,
          intent: Intent.DANGER
        });
      } else {
        throw new Error(error);
      }
    }
  }

  buildHeaders = () => {
    const accessToken = this.authBloc.getCurrentToken();
    const auth = "Bearer " + accessToken;
    return { headers: { Authorization: auth } };
  };
}

export default TaskApi;
