//@flow
import * as XLSX from "@sheet/core";
import "./ProjectSheetExporter.css";
import React, { useEffect, useState } from "react";
import Project from "../../models/Project/Project";
import AssignedTask from "../../models/AssignedTask/AssignedTask";
import TaskGroup from "../../models/TaskGroup/TaskGroup";
import { Callout, Card, Intent, ProgressBar } from "@blueprintjs/core";
import { PriceTypeEnum, QuantityTypeEnum } from "../../models/Task/Task";
import DateSheetComponent from "./DateSheetComponent";
import DataReportSheetComponent from "./DataReportSheetComponent";
import moment from "moment";
import InvoiceSheet from "./InvoiceSheet";

export default function ProjectSheetExporter(props: Props) {
  const { projects, onExportComplete, projectExportData, projectGroupName } = props;
  const [steps, setSteps] = useState(0);
  const totalSteps = 3;

  useEffect(() => {
    if (!projects) return;
    if (!projectExportData) return;
    setSteps(1);
    const worksheet = buildBidSheetForProject(projectExportData, projectGroupName);
    // const worksheet1 = DataReportSheetComponent(projectExportData);
    // const worksheet2 = DateSheetComponent(projectExportData);
    // const worksheet3 = InvoiceSheet(projectExportData);

    setSteps(2);
    const book = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(book, worksheet, "Details");
    // XLSX.utils.book_append_sheet(book, worksheet1, "Data Reports");
    // XLSX.utils.book_append_sheet(book, worksheet2, "Details Dates");
    // XLSX.utils.book_append_sheet(book, worksheet3, "Invoices");

    setSteps(3);
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "array",
      cellStyles: true
    };
    const currentMonth = moment().format("MMMM YYYY");
    // XLSX.writeFile(book, `CTBU ${currentMonth} SPREADSHEET.xlsx`, wopts);
    XLSX.writeFile(book, `${projectGroupName}.xlsx`, wopts);
    onExportComplete();
  }, [projects]);

  return (
    <Card elevation={4}>
      <Callout title={"Exporting"}>
        <ProgressBar value={steps / totalSteps} />
      </Callout>
    </Card>
  );
}

function buildBidSheetForProject(projects: ?Array<any>, projectGroupName = "CTBU") {
  let projectGroupStat = projects.shift();
  let projectGrantDetails = projects?.length && projects[0];
  projects.shift();

  let dataArray = [];
  projects.map((project, index) => {
    if (!project) return dataArray;
    dataArray.push([
      [
        index + 1,
        project.projectState ? project.projectState : '',
        project.firstName ? project.firstName : '',
        project.lastName ? project.lastName : '',
        project.address ? project.address : '',
        project.city ? project.city : '',
        project.state ? project.state : '',
        project.zip ? project.zip : '',
        project.telephone ? project.telephone : '',
        project.age ? project.age : '',
        project.gender ? project.gender : '',
        project.ethnicity ? project.ethnicity : '',
        project.race ? project.race : '',
        project.veteran ? project.veteran : '',
        project.applicantVeteranExplanation ? project.applicantVeteranExplanation : '',
        project.spouseOfVertern ? project.spouseOfVertern : '',
        project.spouseMilitaryBranch ? project.spouseMilitaryBranch : '',
        project.elderly ? project.elderly : '',
        project.disabled ? project.disabled : '',
        project.disability ? project.disability : '',
        project.tadSqft ? project.tadSqft : '',
        project.income ? `$${project.income}` : '',
        project.numberOfPeople ? project.numberOfPeople : '',
        project.medianIncomePercentage ? project.medianIncomePercentage : '',
        project.lengthOfTimeInHome ? project.lengthOfTimeInHome : '',
        project.childrenVisit ? project.childrenVisit : '',
        project?.createdAt ? moment(project.createdAt).format("MM/DD/YYYY") : '',
        project.applicationStarted ? moment(project.applicationStarted).format("MM/DD/YYYY") : '',
        project.applicationSubmitted ? moment(project.applicationSubmitted).format("MM/DD/YYYY") : '',
        project.application ? moment(project.application).format("MM/DD/YYYY") : '',
        project.selectionCommitteeApproved ? moment(project.selectionCommitteeApproved).format("MM/DD/YYYY") : '',
        project.applicationDenied ? moment(project.applicationDenied).format("MM/DD/YYYY") : '',
        project.initialAssessment ? moment(project.initialAssessment).format("MM/DD/YYYY") : '',
        project.workWriteUp ? moment(project.workWriteUp).format("MM/DD/YYYY") : '',
        project.errSubmitted ? moment(project.errSubmitted).format("MM/DD/YYYY") : '',
        project.errReceived ? moment(project.errReceived).format("MM/DD/YYYY") : '',
        project?.riskAssessmentSubmitted ? moment(project.riskAssessmentSubmitted).format("MM/DD/YYYY") : '',
        project?.vendorsSelected ? moment(project.vendorsSelected).format("MM/DD/YYYY") : '',
        project?.firstCustomMilestoneName ? project?.firstCustomMilestoneName : '',
        project?.firstCustomMilestoneDate ? moment(project.firstCustomMilestoneDate).format("MM/DD/YYYY") : '',
        project.projectStartDate ? moment(project.projectStartDate).format("MM/DD/YYYY") : '',
        project?.secondCustomMilestoneName ? project?.secondCustomMilestoneName : '',
        project?.secondCustomMilestoneDate ? moment(project.secondCustomMilestoneDate).format("MM/DD/YYYY") : '',
        project.workCompletion ? moment(project.workCompletion).format("MM/DD/YYYY") : '',
        project?.invoicesSubmitted ? moment(project.invoicesSubmitted).format("MM/DD/YYYY") : '',
        project?.invoicesPaid ? moment(project.invoicesPaid).format("MM/DD/YYYY") : '',
        project.reimbursementSubmitted ? moment(project.reimbursementSubmitted).format("MM/DD/YYYY") : '',
        project.reimbursementReceived ? moment(project.reimbursementReceived).format("MM/DD/YYYY") : '',
        project?.projectCompleted ? moment(project.projectCompleted).format("MM/DD/YYYY") : '',
        project?.hfhiReported ? moment(project.hfhiReported).format("MM/DD/YYYY") : '',
        project.budget ? `$${project.budget}` : '',
        project.actual ? `$${project.actual}` : '',
        project.leftToSpend ? `$${project.leftToSpend}` : '',
        project.notes ? project.notes : '',
        project?.yearOfHouseBuilt ? project?.yearOfHouseBuilt : ''
      ]
    ]);
    return dataArray;
  });

  const flattenedDataArray = [].concat.apply([], dataArray);
  const sheetData = {
    cols: [
      { name: "A", key: 0 },
      { name: "B", key: 1 },
      { name: "C", key: 2 },
      { name: "D", key: 3 },
      { name: "E", key: 4 },
      { name: "F", key: 5 },
      { name: "G", key: 6 },
      { name: "H", key: 7 },
      { name: "I", key: 8 },
      { name: "J", key: 9 },
      { name: "K", key: 10 },

      { name: "L", key: 11 },
      { name: "M", key: 12 },
      { name: "N", key: 13 },
      { name: "O", key: 14 },
      { name: "P", key: 15 },
      { name: "Q", key: 16 },
      { name: "R", key: 17 },
      { name: "S", key: 18 },
      { name: "T", key: 19 },
      { name: "U", key: 20 },

      { name: "V", key: 21 },
      { name: "W", key: 22 },
      { name: "X", key: 23 },
      { name: "Y", key: 24 },
      { name: "Z", key: 25 },
      { name: "AA", key: 26 },
      { name: "AB", key: 27 },
      { name: "AC", key: 28 },
      { name: "AD", key: 29 },
      { name: "AE", key: 30 },

      { name: "AF", key: 31 },
      { name: "AG", key: 32 },
      { name: "AH", key: 33 },
      { name: "AI", key: 34 },
      { name: "AJ", key: 35 },
      { name: "AK", key: 36 },
      { name: "AL", key: 37 },
      { name: "AM", key: 38 },
      { name: "AN", key: 39 },
      { name: "AO", key: 40 },
      { name: "AP", key: 41 },
      { name: "AQ", key: 42 },
      { name: "AR", key: 43 },
      { name: "AS", key: 44 },
      { name: "AT", key: 45 },
      { name: "AU", key: 46 },
      { name: "AV", key: 47 },
      { name: "AW", key: 48 },
      { name: "AX", key: 49 },
      { name: "AY", key: 50 },
      { name: "AZ", key: 51 },
      { name: "BA", key: 52 },
      { name: "BB", key: 53 },
      { name: "BC", key: 54 },
    ],
    data: [
      [
        `${projectGroupName}`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ], // row 0
      [
        "",
        "Project Group Status",
        "",
        `${projectGroupStat.status}`,
        "Grant Name",
        `${projectGrantDetails?.grantName}`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ], // row 1
      [
        "",
        "",
        "",
        "",
        "Grant No",
        `${projectGrantDetails?.grantNumber}`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ], //row 2
      [
        "",
        "License Number",
        `${projectGroupStat?.licenseNumber}`,
        "",
        "Grant Amount",
        `${projectGrantDetails?.grantAmount}`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ], // row 3
      [
        "",
        "Projects In Process",
        `${projectGroupStat.inProcess}`,
        "",
        "Budget",
        `$ ${projectGroupStat.budget}`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ], // row 4
      [
        "",
        "Project Completed",
        `${projectGroupStat.completed}`,
        "",
        "Actual",
        `$ ${projectGroupStat.actual}`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ], // row 5
      [
        "",
        "Total Projects",
        `${projectGroupStat.total}`,
        "",
        "Remaining ",
        `$ ${projectGroupStat.remaining}`,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ], // row 6
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
      ], // row 9
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Milestones",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Money",
        "",
        "",
        "",
        ""
      ], // row 10
      [
        "#",
        "Status",
        "First Name",
        "Last Name",
        "Address",
        "City",
        "State",
        "Zip",
        "Telephone",
        "Age",
        "Gender",
        "Ethnicity Hispanic or Non Hispanic",
        "Race",
        "Veteran",
        "Veteran's Military Branch",
        "Spouse of Veteran",
        "Spouse's Military Branch",
        "Elderly",
        "Disabled",
        "Disability",
        "TAD Sqft",
        "Income",
        "# of Family Members",
        "Median Income %",
        "Length of time in Home",
        "Do children under 6 visit?",
        " Created",
        " Homeowner App Started",
        " Homeowner App Submitted",
        " Homeowner App Approved",
        " Selection Committee Approval Date",
        " Homeowner App Denied",
        " Initial Assessment Date",
        " Survey Completed",
        " ER Submitted",
        " ER Released",
        " Lead Risk Assessment Submitted",
        " Vendor(s) Selected",
        " Custom Milestone Name 1",
        " Custom Milestone Date 1",
        " Work Started",
        " Custom Milestone Name 2",
        " Custom Milestone Date 2",
        " Work Completed",
        " Invoice(s) Submitted",
        " Invoice(s) Paid",
        " Reimbursement Submitted",
        " Reimbursement Received",
        " Project Completed:",
        " Report to Habitat for Humanity International",
        " Budget",
        " Actual",
        " Left to Spend",
        " Notes",
        " Year Built",
      ] // row 11(header row)
    ].concat(flattenedDataArray)
  };
  // return sheetData;
  try {
    return calculateWidths(
      addStylingToCells(XLSX.utils.aoa_to_sheet(sheetData.data))
    );
  } catch (error) {
    console.error(error);
  }
}

function calculateWidths(worksheet) {
  const range = XLSX.utils.decode_range(worksheet["!ref"]);
  if (!worksheet["!cols"]) worksheet["!cols"] = new Array(range.e.c);
  for (let index = 0; index < worksheet["!cols"].length; index++) {
    if (index === 1) {
      worksheet["!cols"][index] = { wpx: 130 };
    } else if (index > 8) {
      worksheet["!cols"][index] = { wpx: 100 };
    }
    else if (index === 0) {
      worksheet["!cols"][index] = { wpx: 30 };
    }
    else {
      worksheet["!cols"][index] = { auto: 1 };
    }
  }
  return worksheet;
}

/**styling for first sheet (client details) */
function addStylingToCells(worksheet) {
  const range = XLSX.utils.decode_range(worksheet["!ref"]);
  const headerRow = 9;
  let nextSectionRow = 1;
  const addressColumn = 4;
  const cityColumn = 5;
  const preHeaderRow = 1;
  const cumulHeaderRow = 4;
  const minRaceColumn = 13;
  const maxRaceColumn = 22;
  const minRaceRow = 10;
  const minPercentColumn = 30;
  const maxPercentColumn = 46;
  const minPercentRow = 1;

  const statusColumn = 1;
  const lastNameColumn = 2;
  const milestoneStartColumn = 26;
  const milestoneEndColumn = 49;
  const moneyStartColumn = 50;
  const moneyEndColumn = 52;


  let mergeArray = [];
  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell_address = { c: C, r: R };
      const cell_ref = XLSX.utils.encode_cell(cell_address);
      let rgb,
        bold,
        border,
        color,
        alignment,
        textStyleArray = [],
        size;
      if (cell_address.r === headerRow) {
        rgb = { rgb: "9cc3e6" };
        bold = true;
        alignment = {
          wrapText: true
        };
      }
      if (cell_address.r < headerRow) {
        size = { sz: 12 };
        if (cell_address.c === addressColumn && cell_address.r >= cumulHeaderRow && cell_address.r <= 6) {
          // size = { sz: 8 };
          border = "thin";
          textStyleArray.push(size);
          rgb = { rgb: "b6dde8" };
          bold = true;
          alignment = { horizontal: "right", vertical: 'center' };

        } else if (cell_address.c === cityColumn && cell_address.r >= cumulHeaderRow && cell_address.r <= 6) {
          // size = { sz: 8 };
          border = "thin";
          textStyleArray.push(size);
          rgb = { rgb: "b6dde8" };
          // bold = true;
          alignment = { horizontal: "center", vertical: 'center' };

        }
        else if (cell_address.c === statusColumn && cell_address.r >= cumulHeaderRow && cell_address.r <= 6) {
          // size = { sz: 12 };
          border = "thin";
          textStyleArray.push(size);
          rgb = { rgb: "d6e3bc" };
          bold = true;
          alignment = { horizontal: "right", vertical: 'center', wrapText: true };
        }
        else if (cell_address.c === lastNameColumn && cell_address.r >= cumulHeaderRow && cell_address.r <= 6) {
          // size = { sz: 12 };
          border = "thin";
          textStyleArray.push(size);
          rgb = { rgb: "d6e3bc" };
          // bold = true;
          alignment = { horizontal: "center", vertical: 'center' };

        }

        else {
          border = "none";
          // rgb = { rgb: "deebf6" };
        }
      }
      if (cell_address.r === range.e.r) {
        if (cell_address.c === range.e.c) {
          border = "thick";
        }
      }

      // if (cell_address.r === minRaceRow && cell_address.c === minRaceColumn) {
      //   rgb = { rgb: "9cc3e6" };
      //   size = {sz:10}
      //   textStyleArray.push(size)
      //   alignment = { horizontal: "center", wrapText:true };
      // }
      // if (cell_address.r >= minPercentRow && cell_address.r < headerRow && cell_address.c >= minPercentColumn && cell_address.c <= maxPercentColumn) {
      //   rgb = { rgb: "9cc3e6" };
      //   border = "thick";
      //   size = {sz:10}
      //   textStyleArray.push(size)
      //   alignment = { horizontal: "center", wrapText:true, vertical: "center" };
      // }

      if (cell_address.r === 0 && cell_address.c === 0) {
        size = { sz: 48 };
        color = { rgb: "0070c0" };
        textStyleArray.push(size)
        alignment = { horizontal: "left", vertical: 'center' };
      }

      // if (cell_address.r === 1 && (cell_address.c === 1 || cell_address.c === 1 || cell_address.c === 3)) {
      //   // size = {sz: 12};
      //   rgb = { rgb: "fbd4b4" };
      //   // bold = true;
      //   textStyleArray.push(size)
      //   alignment = { horizontal: "left" };
      // }

      if (cell_address.r === 8 && cell_address.c === milestoneStartColumn) {
        size = { sz: 12 };
        rgb = { rgb: "8db3e2" };
        bold = true;
        border = "none";
        // color ={color: "0070c0"};
        textStyleArray.push(size)
        alignment = { horizontal: "center" };
      }

      if (cell_address.r === 8 && cell_address.c === moneyStartColumn) {
        size = { sz: 12 };
        rgb = { rgb: "92cddc" };
        bold = true;
        border = "medium";
        // color ={color: "0070c0"};
        textStyleArray.push(size)
        alignment = { horizontal: "center" };
      }

      if (cell_address.r === 1 && (cell_address.c === 1 || cell_address.c === 2 || cell_address.c === 3)) {
        size = { sz: 14 };
        border = "thin";
        rgb = { rgb: "fbd4b4" };
        textStyleArray.push(size)
        alignment = { horizontal: "left", vertical: 'center' };
      }


      if (cell_address.r === headerRow) {
        if (cell_address.c >= milestoneStartColumn && cell_address.c <= milestoneEndColumn) {
          alignment = {
            horizontal: "center",
            vertical: "bottom",
            textRotation: 90,
            // wrapText: true
          };
          rgb = { rgb: "deebf6" };
          color = { rgb: "366092" };
          bold = false;
        }
      }

      if (cell_address.r === headerRow) {
        if (cell_address.c >= moneyStartColumn && cell_address.c <= moneyEndColumn) {
          alignment = {
            horizontal: "center",
            vertical: "bottom",
            textRotation: 90,
            // wrapText: true
          };
          rgb = { rgb: "daeef3" };
          color = { rgb: "31859b" };
          bold = false;
        }
      }

      if (cell_address.r === 1 && cell_address.c < 3) {
        bold = true;
      }





      worksheet[cell_ref].s = buildCellStyles(
        bold,
        rgb,
        border,
        color,
        alignment,
        textStyleArray
      );
    }
  }
  mergeArray = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
    { s: { r: 1, c: 1 }, e: { r: 1, c: 2 } },
    { s: { r: 8, c: milestoneStartColumn }, e: { r: 8, c: milestoneEndColumn } },
    { s: { r: 8, c: moneyStartColumn }, e: { r: 8, c: moneyEndColumn } },

    // {s: { r: 7, c: 7 }, e: { r: 7, c: 9 }},
    // {s: { r: minRaceRow, c: minRaceColumn }, e: { r: minRaceRow, c: maxRaceColumn }},
    // {s: { r: minPercentRow, c: minPercentColumn }, e: { r: minPercentRow, c: maxPercentColumn}},
    // {s: { r: minPercentRow+1, c: maxPercentColumn }, e: { r: headerRow-1, c: maxPercentColumn}}
  ]
  worksheet["!merges"] = mergeArray;
  const freeze_cell_address = { c: 6, r: 10 };
  const freeze_cell_ref = XLSX.utils.encode_cell(freeze_cell_address);
  worksheet["!freeze"] = freeze_cell_ref;

  // var wscols = [{ width: 05 }];
  // worksheet["!cols"] = wscols;

  return worksheet;
}

const buildCellStyles = (
  bold,
  rgb,
  border,
  color,
  alignment,
  textStyleArray = []
) => {
  if (!border) border = "thin";
  let style = {
    top: { style: border },
    bottom: { style: border },
    left: { style: border },
    right: { style: border }
  };
  if (rgb) style.fgColor = rgb;
  if (bold) style.bold = bold;
  if (color) style.color = color;
  if (alignment) style.alignment = alignment;
  // if(size) style.sz = size;
  textStyleArray.map(textStyle => {
    const keys = Object.keys(textStyle);
    keys.map(key => {
      style[key] = textStyle[key];
    });
  });
  return style;
};