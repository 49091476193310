import React, { useContext, useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { TextField, Button, Container, Stack } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from "./ManageNotesView.module.css";

import axios from "axios";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  outline: 'none'
};

export default function ViewNotesDetail({ open, projectId, isView, currentNote, handleClose, src }) {
  const [image, setImage] = useState(null);

  useEffect(() => {
    const getImageData = async () => {
      if (src?.notesAttachments[src?.notesAttachments?.length - 1]?.getUrl) {
        const encodedString = (await axios.get(src?.notesAttachments[src?.notesAttachments?.length - 1]?.getUrl))?.data;
        const newImage = new Image();

        newImage.onload = () => {
          setImage(newImage);
        };
        newImage.onerror = () => {
          newImage.src = "/errorMessage.png";
        };
        newImage.src = encodedString;
        //}
      }
    };
    src && src?.notesAttachments && src?.notesAttachments?.length &&  getImageData();

  }, [src]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <React.Fragment>
          <h2>Notes Details:</h2>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Stack spacing={1}>
            <h4>Title :</h4>
            <p>{currentNote?.title}</p>
            <h4>Description :</h4>
            <p>{currentNote?.description}</p>
            <h4>Attachments :</h4>
            {src?.notesAttachments?.length ?
              (<div className={styles.imgContainer}>
                {src?.notesAttachments?.map((img, index) => (
                  <div key={index} className={styles.previewImg} >
                    {/* <h4>Attachment {index + 1}</h4> */}
                    <a href={img?.getUrl} target="_blank">
                      <img src={img?.getUrl} alt='failed to load' title="Open this image in new tab" />
                    </a>
                  </div>
                ))}
              </div>):
              <h5>No attachments to show</h5>
            }
            {/* <LinkPreview url={image?.src} width="300px" /> */}
            <Button className={styles.submitBtn} onClick={handleClose} size="small" variant="outlined" color="secondary" type="button">Back</Button>
          </Stack>
        </React.Fragment>
      </Box>
    </Modal>
  );
}