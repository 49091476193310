//@flow

import React, { useState, useEffect } from "react";
import Task from "../../models/Task/Task";
import TaskGroup from "../../models/TaskGroup/TaskGroup";
import { Button, Card, Collapse, Divider, Spinner } from "@blueprintjs/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "formik";
import { faChevronUp, faChevronDown, faPlus } from "@fortawesome/free-solid-svg-icons";
import Flexbox from "flexbox-react";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import styles from "./AddDefaultTask.module.css";
import CheckboxSelect from "../CheckboxSelect/CheckboxSelect";

type Props = {
  tasks: ?Array<Task>,
  projectGroupData: ?any,
  taskGroups: ?Array<TaskGroup>,
};

function AddDefaultTask(props: Props) {
  const { tasks, taskGroups } = props;
  const [openSections, setOpenSections] = useState(new Map());

  const setAllSections = (open: boolean) => {
    if (taskGroups && taskGroups.length > 0) {
      const stateMap = taskGroups.reduce((map, taskGroup) => {
        map.set(taskGroup.id, open);
        return map;
      }, new Map());
      setOpenSections(stateMap);
    }
  };

  useEffect(() => {
    setAllSections(false);
  }, [taskGroups]);

  if (!taskGroups || !tasks) return <Spinner />;

  const taskGroupsIdsToTasksMap: Map<string, Array<Task>> = new Map();

  taskGroups.forEach((taskGroup) => {
    if (!taskGroupsIdsToTasksMap.has(taskGroup.id))
      taskGroupsIdsToTasksMap.set(taskGroup.id, []);
  });

  tasks.forEach((task) => {
    const tasksById = taskGroupsIdsToTasksMap.get(task.taskGroupId);
    if (tasksById) {
      tasksById.push(task);
      taskGroupsIdsToTasksMap.set(task.taskGroupId, tasksById);
    }
  });

  const buttons = taskGroups
    .sort(function (a, b) {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title > b.title) {
        return 1;
      }
      return 0;
    })
    .map((taskGroup) => {
      const tasks = taskGroupsIdsToTasksMap.get(taskGroup.id);
      if (tasks && tasks.length > 0)
        return (
          <TaskCollapseView
            taskGroup={taskGroup}
            tasks={tasks}
            isOpen={openSections.get(taskGroup.id)}
            onOpen={() => {
              openSections.set(taskGroup.id, true);
              setOpenSections(new Map(openSections));
            }}
            onClose={() => {
              openSections.set(taskGroup.id, false);
              setOpenSections(new Map(openSections));
            }}
          />
        );
      return null;
    });

  return (
    <Flexbox
      height={"100%"}
      width={"100%"}
      flexDirection={"column"}
      paddingBottom={"100px"}
    >
      <Fab event={"click"} icon={<FontAwesomeIcon icon={faPlus} size={"2x"} />}>
        <Action
          text="Collapse All"
          onClick={() => {
            setAllSections(false);
          }}
        >
          <Flexbox flexDirection={"column"}>
            <FontAwesomeIcon icon={faChevronDown} />
            <FontAwesomeIcon icon={faChevronUp} />
          </Flexbox>
        </Action>
        <Action
          text="Expand All"
          onClick={() => {
            setAllSections(true);
          }}
        >
          <Flexbox flexDirection={"column"}>
            <FontAwesomeIcon icon={faChevronUp} />
            <FontAwesomeIcon icon={faChevronDown} />
          </Flexbox>
        </Action>
      </Fab>

      {buttons}
    </Flexbox>
  );
}

type TaskCollapseViewProps = {
  taskGroup: TaskGroup,
  tasks: Array<Task>,
  isOpen: boolean,
  onClose: () => any,
  onOpen: () => any,
};

function TaskCollapseView(props: TaskCollapseViewProps) {
  const { taskGroup, tasks, isOpen, onClose, onOpen } = props;

  const createSelectInput = (inputName: string, inputLabel: string, select) => {
    return (
      <Flexbox
        flex={1}
        flexDirection={"column"}
        width={"100%"}
        padding={"10px"}
        marginTop={"10px"}
      >
        <Flexbox flex={1}>
          <Flexbox flex={1}>
            {select} <label htmlFor={inputName}>{inputLabel}</label>
          </Flexbox>
        </Flexbox>

        <Flexbox className={styles.errorText} flex={1}>
          <ErrorMessage name={inputName} />
        </Flexbox>
      </Flexbox>
    );
  };

  let tasklist = <Spinner />;

  if (tasks && tasks.length) {
    tasklist = tasks
      .sort(function (a, b) {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      })
      .map((task, index) => (
        <Flexbox width={"50%"}>
          {createSelectInput(
            task.id,
            task.title,
            <CheckboxSelect
              inputName={task.id}
              // disabled={disableEdit}
            />
          )}
        </Flexbox>
      ));
  }

  return (
    <Card
      className={`${styles.pickerCard} bp3-ui-text`}
      elevation={isOpen ? 3 : 1}
    >
      <Button
        minimal
        fill
        id={taskGroup.id}
        text={
          <Flexbox alignItems="center">
            <h5 className={styles.pickerCardTitle}>{taskGroup.title}</h5>
            <Divider />
            {isOpen ? (
              <FontAwesomeIcon
                className={styles.upDownArrow}
                icon={faChevronDown}
              />
            ) : (
              <FontAwesomeIcon
                className={styles.upDownArrow}
                icon={faChevronUp}
              />
            )}
          </Flexbox>
        }
        onClick={() => {
          if (isOpen) onClose();
          else onOpen();
        }}
      />
      <Collapse
        isOpen={isOpen}
        className={isOpen ? `${styles.assignedTasksContainer}` : ""}
      >
        <h4 className={styles.pickerCardTitle}>Tasks</h4>

        <Divider />
        <Flexbox
          flex={1}
          alignItems={"flex-start"}
          flexDirection={"row"}
          flexWrap={"wrap"}
          className={styles.inputSection}
        >
          {tasklist}
        </Flexbox>
      </Collapse>
    </Card>
  );
}

export default AddDefaultTask;
