// @flow

import Vendor, {
  CreateVendorRequest,
  UpdateVendorRequest,
  VendorAttachment
} from "../../models/Vendor/Vendor";
import VendorApi from "../../api/VendorApi/VendorApi";
import AttachmentStorageApi from "../../api/AttachmentApi/AttachmentStorageApi";

class VendorService {
  vendorApi: VendorApi;
  attachmentStorageApi: AttachmentStorageApi;

  constructor(vendorApi: VendorApi, attachmentStorageApi: AttachmentStorageApi) {
    this.vendorApi = vendorApi;
    this.attachmentStorageApi = attachmentStorageApi;
  }

  async getAllVendors(): Promise<Array<Vendor>> {
    return await this.vendorApi.getAll();
  }

  async createVendor(
    createVendorRequest: CreateVendorRequest
  ): Promise<Vendor> {
    return await this.vendorApi.createVendor(createVendorRequest);
  }

  async updateVendor(
    updateVendorRequest: UpdateVendorRequest
  ): Promise<Vendor> {
    return await this.vendorApi.updateVendor(updateVendorRequest);
  }
  async vendorAttachment(vendorAttachment: VendorAttachment, vendorRequest: UpdateVendorRequest, attachmentType: AttachmentTypeEnum): Promise<vendor>{    
    const modifiedAttachments = vendorAttachment.attachments.map(({ payload, ...rest }) => ({
      ...rest,
    }));
    const finalData = {
      id: vendorAttachment.id,
      attachments: modifiedAttachments,
    };
    const vendorAttachments =  await this.vendorApi.vendorAttachments(finalData);
    if(vendorAttachments){
      vendorAttachment.attachments.map(async (attachment, index)=>{
        let updatedVendorRequest = vendorRequest;
        attachment.vendorId = vendorAttachment.id;
        updatedVendorRequest.attachment = attachment;
        if (this.attachmentStorageApi && this.attachmentStorageApi.putAttachmentPayload) {
          await this.attachmentStorageApi.putAttachmentPayload(vendorAttachments[index].putUrl, vendorRequest, attachmentType);
      } else {
          console.error('attachmentStorageApi or putAttachmentPayload is not defined');
      }      
    })
      }
    return vendorAttachments
  }
  async removeVendorFromVendorList(vendorId: string): Promise<Vendor> {
    return await this.vendorApi.removeVendorFromList(vendorId);
  }
}

export default VendorService;
