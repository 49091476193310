//@flow

import React,{useState} from "react";
import { useField } from "formik";
import styles from "../CreateProjectRequestBuilder/CreateProjectRequestBuilder.module.css";
import { Spinner, Tag, Intent } from "@blueprintjs/core";
import { DatePicker, DateInput,IDateFormatProps } from "@blueprintjs/datetime";
import moment from "moment";
import Flexbox from "flexbox-react";


type Props = {
  inputName: string,
  disabled?: boolean,
  selectedDate?:string,
  userName?: String
};

export default function DateTimeInput(props: Props) {
  const [itemField, itemMeta] = useField(props.inputName);
  const valueChangeHandler = itemField.onChange;
  const error =
    itemMeta.error && itemMeta.touched ? (
      <div className={styles.errorText}>{itemMeta.error}</div>
    ) : (
      <div />
    );

  const [intialDate,setIntialDate] = useState(props.selectedDate ? new Date(props.selectedDate):null);

  const m = moment(itemField.value);

  return (
    <div>
      <Flexbox justifyContent={"center"}>
        <Flexbox flex={0} justifyContent={"center"} flexDirection="row">
            <DateInput
            {...getMomentFormatter("LL")} locale="de"
              showActionsBar={true}
              closeOnSelection={true}
              canClearSelection={true}
              clearButtonText={"Clear"}
              disabled={props.disabled}
              highlightCurrentDay={true}
              key={props.inputName}
              onChange={(date) => {
                const event = {
                  target: {
                    name: itemField.name,
                    value: date,
                  },
                };
                valueChangeHandler(event);
                setIntialDate(date);
              }}
              value={intialDate}
              maxDate={new Date("9999-12-31")} // Arbitrarily distant future date
            ></DateInput>
        </Flexbox>
        {(itemField.name === "approvalDateSecond" ||
          itemField.name === "approvalDateFirst") &&
          intialDate != null && (
            <label style={{ marginLeft: 10, marginTop: -10 }}>
              <span style={{ fontSize: 13 }}>Approved by : </span><br />{props.userName}
            </label>
          )}
      </Flexbox>
      {/* {m.isValid() ? (
        <Tag intent={Intent.PRIMARY}>{m.format("dddd, LL")}</Tag>
      ) : (
        <Tag minimal={true}>no date</Tag>
      )} */}
    </div>
  );
}

function getMomentFormatter(format: string): IDateFormatProps {
    // note that locale argument comes from locale prop and may be undefined
    return {
        formatDate: (date, locale) => moment(date).locale(locale).format(format),
        parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
        
    }
};
