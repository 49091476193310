// @flow

import {PartialObserver, Subject} from 'rxjs'
import  InvoiceService from '../service/InvoiceService/InvoiceService';
import Invoice, { CreateInvoiceRequest, PostInvoiceResponse, UpdateInvoiceRequest } from '../models/Invoice/Invoice';
 



export const InvoiceEventTypesEnum = Object.freeze({
    CREATE_INVOICE: Symbol("CREATE INVOICE"),
    DELETE_INVOICE: Symbol("DELETE INVOICE"),
    FETCH_INVOICES_FOR_PROJECT: Symbol("FETCH INVOICES FOR PROJECT"),
    UPDATE_INVOICE:Symbol("UPDATE INVOICE")
});

export type InvoiceEventTypes = $Keys<typeof InvoiceEventTypesEnum>;


export class InvoiceEvent {
    type: InvoiceEventTypes;
    attachmentType: InvoiceEventTypes;

    constructor(type: InvoiceEventTypes, attachmentType: AttachmentTypes) {
        this.type = type;
        this.attachmentType = attachmentType;
    }
}

export class FetchInvoicesForProjectEvent extends InvoiceEvent {

    projectId: string;

    constructor(projectId: string, attachmentType: AttachmentTypes) {
        super(InvoiceEventTypesEnum.FETCH_INVOICES_FOR_PROJECT, attachmentType);
        this.projectId = projectId;
    }
}

export class CreateInvoiceForProjectEvent extends InvoiceEvent {

    request: CreateInvoiceRequest;

    constructor(attachmentType: AttachmentTypes, createInvoiceRequest: CreateInvoiceRequest) {
        super(InvoiceEventTypesEnum.CREATE_INVOICE, attachmentType);
        this.request = createInvoiceRequest;
    }
}

export class DeleteInvoiceForProjectEvent extends InvoiceEvent {

    projectId: string;
    invoiceId: string;

    constructor(projectId: string, attachmentType: AttachmentTypes, invoiceId: string) {
        super(InvoiceEventTypesEnum.DELETE_INVOICE, attachmentType);
        this.projectId = projectId;
        this.invoiceId = invoiceId;
    }
}

export class UpdateInvoiceForProjectEvent extends InvoiceEvent {

    request: UpdateInvoiceRequest;

    constructor(attachmentType: AttachmentTypes, updateInvoiceRequest: UpdateInvoiceRequest) {
        super(InvoiceEventTypesEnum.UPDATE_INVOICE, attachmentType);
        this.request = updateInvoiceRequest;
    }
}

export class InvoiceBloc {
    _outInvoiceStream: Subject = new Subject();

    subscribeToInvoiceContext(observer?: PartialObserver<InvoiceOutEvent>) {
        return this._outInvoiceStream.subscribe(observer);
    }

    _eventController: Subject = new Subject();

    sendEvent(event: InvoiceEvent) {
        return this._eventController.next(event)
    }

    _invoiceService: InvoiceService;

    constructor(invoiceService: InvoiceService) {
        this._invoiceService = invoiceService;
        this._eventController.subscribe(this.buildEventHandler())
    }

    buildEventHandler = () => {
        return {
            next: async (event: AttachmentEvent) => {
                switch (event.type) {
                    case InvoiceEventTypesEnum.FETCH_INVOICES_FOR_PROJECT: {
                        const projectId = (event: FetchInvoicesForProjectEvent).projectId;
                        const attachmentType = event.attachmentType;
                        const invoices = await this._invoiceService.getInvoicesForProject(projectId, attachmentType);
                       
                        this._outInvoiceStream.next(new FetchInvoicesForProjectOutEvent(projectId, invoices));
                        break;
                    }

                    case InvoiceEventTypesEnum.CREATE_INVOICE: {
                        const projectId = (event: CreateInvoiceForProjectEvent).request.projectId;
                        const attachmentType = (event: CreateInvoiceForProjectEvent).attachmentType;
                        const invoice = await this._invoiceService.createInvoice(attachmentType, (event: CreateInvoiceForProjectEvent).request);
                        this._outInvoiceStream.next(new AddInvoicesForProjectOutEvent(projectId, [invoice]));
                        break;
                    }

                    case InvoiceEventTypesEnum.DELETE_INVOICE: {
                        const attachmentType = event.attachmentType;
                        const {projectId, invoiceId} = (event: DeleteInvoiceForProjectEvent);
                        await this._invoiceService.deleteInvoice(projectId, invoiceId, attachmentType);
                        const invoices = await this._invoiceService.getInvoicesForProject(projectId, attachmentType);
                        this._outInvoiceStream.next(new DeleteInvoicesForProjectOutEvent(projectId, [invoices]));
                        break;
                    }

                    case InvoiceEventTypesEnum.UPDATE_INVOICE: {
                        const projectId = (event: UpdateInvoiceForProjectEvent).request.projectId;
                        const attachmentType = (event: UpdateInvoiceForProjectEvent).attachmentType;
                        const invoice = await this._invoiceService.updateInvoice(attachmentType, (event: UpdateInvoiceForProjectEvent).request);
                        this._outInvoiceStream.next(new UpdateInvoicesForProjectOutEvent(projectId, [invoice]));
                        break;
                    }
                  
                    default: {
                        throw new Error("Unknown attachment event: " + event.type);
                    }
                }
            },
            error(err) {
                throw err
            },
        };
    };

    dispose() {
        this._outInvoiceStream.complete();
        this._eventController.complete();
    }
}

export const InvoiceOutEventTypesEnum = Object.freeze({
    FETCHED: Symbol("FETCHED"),
    CREATED: Symbol("CREATED"),
    DELETED: Symbol("DELETED"),
    UPDATED: Symbol("UPDATED")
});

export type InvoiceOutEventTypes = $Keys<typeof InvoiceOutEventTypesEnum>;


export class InvoiceOutEvent {
    type: InvoiceOutEventTypes;

    constructor(type: InvoiceOutEventTypes) {
        this.type = type;
    }
}

export class FetchInvoicesForProjectOutEvent extends InvoiceOutEvent {

    projectId: string;
    invoices: Array<GetInvoiceResponse>;

    constructor(projectId: string, invoices: Array<Invoice>) {
        super(InvoiceOutEventTypesEnum.FETCHED);
        this.invoices = invoices;
        this.projectId = projectId;
    }
}

export class AddInvoicesForProjectOutEvent extends InvoiceOutEvent {

    projectId: string;
    newInvoices: Array<PostInvoiceResponse>;

    constructor(projectId: string, newInvoices: Array<Invoice>) {
        super(InvoiceOutEventTypesEnum.CREATED);
        this.newInvoices = newInvoices;
        this.projectId = projectId;
    }
}

export class DeleteInvoicesForProjectOutEvent extends InvoiceOutEvent {

    projectId: string;
    deleteInvoicesIds: Array<string>;

    constructor(projectId: string, deleteInvoicesIds: Array<string>) {
        super(InvoiceOutEventTypesEnum.DELETED);
        this.deleteInvoicesIds = deleteInvoicesIds;
        this.projectId = projectId;
    }
}

export class UpdateInvoicesForProjectOutEvent extends InvoiceOutEvent {

    projectId: string;
    newInvoices: Array<PostInvoiceResponse>;

    constructor(projectId: string, newInvoices: Array<Invoice>) {
        super(InvoiceOutEventTypesEnum.UPDATED);
        this.newInvoices = newInvoices;
        this.projectId = projectId;
    }
}